import React from "react";
import Utils from "../../../utils/Utils";
import {Table} from "semantic-ui-react";
import {LoanPayment} from "../../loans/container/info/LoanPayments";
import {ShowLoan} from "../../loans/container/ShowLoan";

export default function PaymentsReport(params: { transactions: any }) {
    const [showLoan, setShowLoan] = React.useState({show: false, loan_id: 0})
    const [transactions, setTransactions] = React.useState<LoanPayment[]>([])

    React.useEffect(() => {
        if (params.transactions.hasOwnProperty("rp")) {
            setTransactions(params.transactions.rp)
        }
    }, [params.transactions])

    return (
        <>
            {
                showLoan.show ?
                    <ShowLoan close={() => setShowLoan({loan_id: 0, show: false})} loan_id={showLoan.loan_id}/> :
                    <Table celled striped compact size='small' inverted color='grey' selectable className="has_total">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '70px'}} textAlign="center">Loan No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '180px'}}>Branch Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '180px'}}>Received By</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '180px'}}>Paid By</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}>Principal Paid</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}>Interest Paid</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}>Penalty Paid</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}>Total Paid</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                transactions
                                    .map((transaction, index) => <>
                                            <Table.Row
                                                key={index}
                                                onClick={() => {
                                                    if (transaction.loan_id > 0) {
                                                        setShowLoan({loan_id: transaction.loan_id, show: true});
                                                    }
                                                }}>
                                                <Table.Cell style={{width: '50px'}} textAlign="center">
                                                    {index !== (transactions.length - 1) && Utils.row_number(index)}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '70px'}} textAlign="center">
                                                    {index !== (transactions.length - 1) && transaction.loan_id}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '180px'}}>{transaction.office_name}</Table.Cell>
                                                <Table.Cell style={{width: '180px'}}>{transaction.accountant_name}</Table.Cell>
                                                <Table.Cell style={{width: '180px'}}>{transaction.payer_name}</Table.Cell>
                                                <Table.Cell style={{width: '130px'}}>{Utils.comma_number(transaction.principal_paid)}</Table.Cell>
                                                <Table.Cell style={{width: '130px'}}>{Utils.comma_number(transaction.interest_paid)}</Table.Cell>
                                                <Table.Cell style={{width: '130px'}}>{Utils.comma_number(transaction.penalty_paid)}</Table.Cell>
                                                <Table.Cell style={{width: '130px'}}>{Utils.comma_number(transaction.total_paid)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    )
                            }
                        </Table.Body>
                    </Table>
            }
        </>
    )
}
