import {LoanInfo} from "../../ShowLoan";
import React from "react";
import Utils, {ConfirmAction, initial_confirm} from "../../../../../utils/Utils";
import {ToastsStore} from "react-toasts";
import {Req} from "../../../../../utils/Req";
import {Loading, MyDateInput, MyInput} from "../../../../../utils/Components";
import {Button, Confirm, Table} from "semantic-ui-react";

export interface LoanFee {
    fee_id: number
    fee_description: string
    fee_amount: string | number
    date_created: string
    created_by: string
    fee_balance: number
}

const initial_loan_fee: LoanFee = {
    fee_id: 0, fee_balance: 0, created_by: "", date_created: Utils.today(), fee_amount: "", fee_description: ""
}

export default function OtherFees(params: { loanInfo: LoanInfo, setLoanInfo: (loanInfo: LoanInfo) => void }) {
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)
    const [loader, setLoader] = React.useState({message: "", show: false})

    const [fee, setFee] = React.useState(initial_loan_fee)
    const handle_fee = (name: string, value: string) => {
        setFee({...fee, [name]: value})
    }
    const save_fee = () => {
        if (!Utils.is_valid_number(fee.fee_amount as string)) {
            ToastsStore.error("Enter valid loan fee added")
        } else {
            setConfirm({
                ...confirm,
                open: true,
                content: 'Are you sure you want to proceed with adding ' + Utils.comma_number(parseFloat(fee.fee_amount as string)) +
                    ' as loan fee to this loan?',
                onConfirm: () => {
                    setConfirm({...confirm, 'open': false})
                    setLoader({message: "Adding loan fee, please wait", show: true})

                    Req.add_loan_fee({
                        date_created: fee.date_created, fee_amount: fee.fee_amount as string,
                        fee_description: fee.fee_description, loan_id: params.loanInfo.loan_id
                    })
                        .then((response) => {
                            setLoader({message: "", show: false})
                            if (response.data.hasOwnProperty("code")) {
                                if (response.data.code === 1) {
                                    ToastsStore.success("Successfully added loan fees")
                                    setFee(initial_loan_fee)
                                    params.setLoanInfo(Utils.format_loan_data(response))
                                } else if (response.data.code === 2) {
                                    ToastsStore.error("Loan not found")
                                } else if (response.data.code === 3) {
                                    ToastsStore.error("Loan was already cleared, you cannot adda fee")
                                }
                            } else {
                                ToastsStore.error("Could not add loan fee, please retry")
                            }
                        })
                        .catch(() => {
                            setLoader({message: "", show: false})
                            ToastsStore.error("Could not add loan fee, please retry")
                        })
                },
                onCancel: () => setConfirm({...confirm, 'open': false})
            })
        }
    }

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} centered={false} size='mini'
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} content={confirm.content}/>

            <div className="row mx-0 h-100">
                <div className="col-12 p-1 col-md-8 h-100">
                    <div className="table_container full_height">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}}>No.</Table.HeaderCell>
                                    <Table.HeaderCell className="time_row">Date Added</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '200px'}}>Added By</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '250px'}}>Fee Description</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '130px'}}>Fee Amount</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '130px'}}>Fee Balance</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    params.loanInfo.others.other_fees
                                        .map((info, index) =>
                                            <Table.Row key={info.fee_id}>
                                                <Table.Cell style={{width: '50px'}}>{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell className="time_row">{Utils.localise_date(info.date_created)}</Table.Cell>
                                                <Table.Cell style={{width: '200px'}}>{info.created_by}</Table.Cell>
                                                <Table.Cell style={{width: '250px'}}>{info.fee_description}</Table.Cell>
                                                <Table.Cell style={{width: '130px'}}>{Utils.comma_number(info.fee_amount as number)}</Table.Cell>
                                                <Table.Cell style={{width: '130px'}}>{Utils.comma_number(info.fee_balance)}</Table.Cell>
                                            </Table.Row>
                                        )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                <div className="d-none col-12 p-1 col-md-4 h-100">
                    <div className="form">
                        <div className='form_content no_header'>
                            <MyInput placeholder="Enter fee added" title="Enter fee added" name="fee_amount"
                                     change={handle_fee} value={fee.fee_amount as string}/>

                            <MyInput placeholder="Enter fee description" title="Enter fee description" name="fee_description"
                                     change={handle_fee} value={fee.fee_description}/>

                            <MyDateInput
                                title="Date fee added" value={fee.date_created} placeholder="Date fee added"
                                name="date_created" change={handle_fee} maxDate={Utils.today()} minDate={params.loanInfo.start_date}/>
                        </div>

                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <Button negative onClick={() => setFee(initial_loan_fee)} labelPosition={"left"} icon='close'
                                            content="Clear Data" size='mini' fluid
                                            disabled={params.loanInfo.payment_summary.principal_balance === 0}/>
                                </div>
                                <div className="col-6 pl-1 pr-0">
                                    <Button positive onClick={save_fee} content="Add Fee" size='mini' fluid
                                            icon="save" labelPosition={"left"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
