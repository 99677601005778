import {Button, Modal, Table} from "semantic-ui-react";
import Utils from "../../../../utils/Utils";
import {MyDateInput, MyInput, MySelect, select_type} from "../../../../utils/Components";
import {ToastsStore} from "react-toasts";
import React from "react";
import {ModifiedLoan} from "./ModifyLoan";
import {LoanProps} from "../../container/ShowLoan";

export interface ModifyExtension {
    user_id: number
    extension_rate: number | string
    extension_period: number | string
    extension_reason: string
    date_extended: string
    extension_start_date: string
    interest_mode: 'principal_only' | 'interest_principal'
}

export default function ModifyExtensions(params: {
    loan: ModifiedLoan, show: boolean, close: (extensions: ModifyExtension[]) => void, loanProps: LoanProps
}) {
    const initial_extension: ModifyExtension = {
        interest_mode: 'interest_principal', date_extended: "", extension_period: 1, extension_rate: params.loan.loan_rate,
        extension_reason: "", user_id: 0, extension_start_date: ""
    }

    const [extensions, setExtensions] = React.useState<Array<ModifyExtension>>([])
    const [extension, setExtension] = React.useState<{ extension: ModifyExtension, index: number }>({
        extension: initial_extension, index: -1
    })
    const handle_extension = (name: string, value: string | number) => {
        setExtension({...extension, extension: {...extension.extension, [name]: value}})
    }

    const save_extension = () => {
        if (extension.extension.date_extended.trim().split(" ").length !== 2) {
            ToastsStore.error("Enter valid date loan was extended")
            return
        }
        if (extension.extension.extension_start_date === "") {
            ToastsStore.error("Enter valid date")
            return
        }

        if (extension.index === -1) {
            setExtensions([...extensions, extension.extension])
        } else {
            setExtensions(extensions.map((__extension, _index) =>
                extension.index === _index ? __extension : extension.extension
            ))
        }
        setExtension({extension: initial_extension, index: -1})
    }

    const save_extensions = () => {
        params.close(extensions)
    }

    const get_extension_data = (type: 'time' | 'date') => {
        const dateTime = extension.extension.date_extended.split(" ")
        if (type === 'date') {
            return dateTime[0]
        } else {
            return dateTime.length === 2 ? dateTime[1] : ""
        }
    }

    React.useEffect(() => {
        setExtension({...extension, extension: {...extension.extension, extension_rate: params.loan.loan_rate}})
    }, [params.loan.loan_rate])

    React.useEffect(() => {
        if (params.show) {
            setExtensions(params.loan.extensions)
        }
    }, [params.show])

    return (
        <Modal open={params.show} basic size='small' centered={false} onClose={() => params.close(extensions)}>
            <div className='modify_loan extensions row m-0'>
                <div className='col-5 p-1 h-100'>
                    <div className="table_container" style={{height: 'calc(100% - 45px)'}}>
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}}>No</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '200px'}}>Date Rescheduled</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    extensions.map((restructure, index) =>
                                        <Table.Row key={restructure.date_extended}
                                                   onClick={() => setExtension({index: index, extension: restructure})}>
                                            <Table.Cell style={{width: '50px'}}>
                                                <Button
                                                    primary size='mini' icon='trash' compact
                                                    onClick={() => {
                                                        setExtensions(
                                                            extensions.filter((_, _index) => _index !== index)
                                                        )
                                                    }}/>
                                            </Table.Cell>
                                            <Table.Cell style={{width: '200px'}}>
                                                {Utils.localise_date(restructure.date_extended)}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>

                    <div className="row mx-0 mt-1">
                        <div className="col-12 col-md-8 offset-md-2 p-1">
                            <Button fluid size='tiny' primary onClick={save_extensions} content='Update Extensions'
                                    icon='save' labelPosition={"left"}/>
                        </div>
                    </div>
                </div>

                <div className='col-7 p-1 h-100'>
                    <div className="form">
                        <div className="form_content no_header">
                            <MySelect
                                change={(value: select_type) => handle_extension('user_id', (value as number))}
                                title="Extended By" name="user_id" value={extension.extension.user_id}
                                options={params.loanProps.staffs.map((user) => ({text: user.user_name, value: user.user_id}))}/>

                            <div className='row mx-0 mb-3'>
                                <div className='col-6 pl-0 pr-1'>
                                    <MyDateInput
                                        title="Date Extended" value={get_extension_data("date")} name="extension_date"
                                        placeholder="Date Extended"
                                        change={(name, value) => {
                                            const dateTime = extension.extension.date_extended.trim().split(" ")
                                            handle_extension("date_extended", `${value} ${dateTime.length > 0 ? dateTime[1] : ''}`)
                                        }}/>
                                </div>
                                <div className='col-6 pl-1 pr-0'>
                                    <MyDateInput
                                        title="Time Extended" value={get_extension_data("time")} name="extension_time"
                                        placeholder="Time Extended" type="time" disabled={extension.extension.date_extended === ""}
                                        change={(name, value) => {
                                            const dateTime = extension.extension.date_extended.trim().split(" ")
                                            handle_extension('date_extended', `${dateTime[0]} ${value}`)
                                        }}/>
                                </div>
                            </div>

                            <div className='row m-0'>
                                <div className='col-6 pl-0 pr-1'>
                                    <MyInput placeholder="Rate" title="Rate" name="extension_rate"
                                             value={extension.extension.extension_rate as string} change={handle_extension}/>
                                </div>
                                <div className='col-6 pl-1 pr-0'>
                                    <MyInput placeholder="Period" title="Extension" name="extension_period"
                                             value={extension.extension.extension_period as string} change={handle_extension}/>
                                </div>
                            </div>

                            <MySelect
                                change={(value) => handle_extension('interest_mode', value as string)}
                                title="Interest calculation method" name='interest_mode' value={extension.extension.interest_mode}
                                options={[
                                    {text: 'Principal balance', value: 'principal_only'},
                                    {text: 'Principal + interest balance', value: 'interest_principal'}
                                ]}/>

                            <MyInput placeholder="Reason for extension" title="Reason for extension" name="extension_reason"
                                     value={extension.extension.extension_reason} change={handle_extension}/>

                            <div className="mt-2">
                                <MyDateInput
                                    title="Extension Start Date" value={extension.extension.extension_start_date}
                                    name="extension_start_date" placeholder="Extension Start Date" change={handle_extension}/>
                            </div>
                        </div>

                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <Button negative onClick={() => setExtension({extension: initial_extension, index: -1})}
                                            content="Clear Data" size='mini' fluid icon='close' labelPosition={"left"}/>
                                </div>
                                <div className="col-6 pl-1 pr-0">
                                    <Button positive onClick={save_extension} content="Add Extension" size='mini'
                                            fluid icon="save" labelPosition={"left"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
