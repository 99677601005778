import React from "react";
import {Button, Modal, Table} from "semantic-ui-react";
import {initial_security, Security} from "../../container/info/collateral/LoanCollateral";
import Utils from "../../../../utils/Utils";
import ManageCollateral from "../../container/info/collateral/ManageCollateral";

export interface ModifySecurity {
    security_name: string
    security_value: string
    security_desc: string
    security_id: number
    security_images: string[]
}

export default function ModifySecurities(params: { securities: Security[], show: boolean, close: (securities?: Security[]) => void }) {
    const [security, setSecurity] = React.useState<Security>(initial_security)
    const [securities, setSecurities] = React.useState<Security[]>(params.securities)

    const save_securities = () => {
        params.close(securities)
    }

    React.useEffect(() => {
        setSecurities(params.securities)
        setSecurity(initial_security)
    }, [params.securities])

    return (
        <>
            <Modal open={params.show} basic size='small' centered={false} onClose={() => params.close(securities)}>
                <div className='modify_loan extensions row m-0'>
                    <div className="col-12 col-md-7 p-1 h-100">
                        <div className="table_container" style={{height: 'calc(100% - 45px)'}}>
                            <Table celled striped compact size='small' inverted color='grey' selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '150px'}}>Security Name</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '120px'}}>Security Value</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '60px'}} textAlign="center">Images</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        securities.map((collateral, index) =>
                                            <Table.Row key={index} onClick={() => setSecurity(collateral)}>
                                                <Table.Cell style={{width: '50px'}} textAlign="center">
                                                    {Utils.row_number(index)}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '150px'}}>{collateral.collateral_name}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>
                                                    {Utils.comma_number(parseFloat(collateral.collateral_value))}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '60px'}} textAlign="center">
                                                    {collateral.images.length}
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>

                        <div className="row mx-0 mt-1">
                            <div className="col-12 col-md-8 offset-md-2 p-1">
                                <Button fluid size='tiny' primary onClick={save_securities} content='Update Securities'
                                        icon='save' labelPosition={"left"}/>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-5 p-1 h-100">
                        <ManageCollateral
                            security={security}  securities={securities} editDisabled={false}
                            save={(collateral)=>{
                                if (collateral.index === -1) {
                                    setSecurities([...securities, {...collateral, index: securities.length}])
                                } else {
                                    setSecurities(securities.map((_security, index) => (index === collateral.index) ? collateral : _security))
                                }
                            }}/>
                    </div>
                </div>
            </Modal>
        </>
    )
}
