import React from "react";
import {MyDateInput, MyInput, MySelect, select_type, SelectData, TablePagination} from "../../../utils/Components";
import {Page, Req} from "../../../utils/Req";
import {Button, Image, Table} from "semantic-ui-react";
import placeholder from "../../../../assets/images/image.png";
import BankAccounts, {BankAccount} from "./BankAccounts";
import Utils, {InputFile} from "../../../utils/Utils";
import {useAppSelector} from "../../../store/hooks";
import {getStaff} from "../../../store/slices/userSlice";
import {ToastsStore} from "react-toasts";
import {BranchUser} from "../../../nav/SideBar";
import {MultiSelect, multiselect_type} from "../../../utils/MultiSelect";

interface BankDeposit {
    deposit_id: number
    account_id: number
    account_name: string
    account_no: string
    bank_name: string
    office_id: number
    office_name: string
    user_id: number
    user_name: string
    accountant_id: number
    accountant_name: string
    amount_deposited: number | string
    date_deposited: string
    date_modified: string
    deposit_receipt: InputFile | null
}

export default function BankDeposits(params: { branchUser: BranchUser, branches: { select: SelectData[], selected: number[] } }) {
    const user = useAppSelector(getStaff)

    const initial_deposit: BankDeposit = {
        accountant_id: user.staff.user_id, accountant_name: user.staff.last_name,
        account_id: 0, account_name: "", amount_deposited: "", bank_name: "", date_deposited: Utils.today(), date_modified: "",
        deposit_id: 0, deposit_receipt: null, office_id: 0, office_name: "", user_id: 0, user_name: "", account_no: ""
    }

    const [showAccounts, setShowAccounts] = React.useState(false)
    const [bankAccounts, setBankAccounts] = React.useState<Array<BankAccount>>([])
    const [accounts, setAccounts] = React.useState<Array<SelectData>>([])

    const [deposit, setDeposit] = React.useState(initial_deposit)
    const [deposits, setDeposits] = React.useState<Array<BankDeposit>>([])
    const handle_change = (name: string, value: string | number) => {
        setDeposit({...deposit, [name]: value})
    }

    const [pages, setPages] = React.useState<Page>({page: 1, pages: 0, limit: 100})
    const [search, setSearch] = React.useState({
        min_date: Utils.weekly_date().sunday, max_date: Utils.today(), branches: params.branches.selected
    })
    const handle_search = (name: string, value: string | number | multiselect_type) => {
        setSearch({...search, [name]: value})
    }

    const [loading, setLoading] = React.useState(false)
    const [searching, setSearching] = React.useState(false)

    const load_deposits = (params: { page: number, pages: number }) => {
        setSearching(true)
        Req.get_bank_deposits({
            max_date: search.max_date, min_date: search.min_date, branches: JSON.stringify(search.branches),
            page: params.page, pages: params.pages,
        })
            .then((response) => {
                setSearching(false)
                if (response.data.hasOwnProperty("deposits")) {
                    let new_deposits: Array<any> = response.data['deposits'];
                    new_deposits.forEach((new_deposit, index) => {
                        new_deposit['deposit_receipt'] = new_deposit['deposit_receipt'] === "" ? null :
                            new InputFile(`${Req.BASE_URL}${new_deposit['deposit_receipt']}`)
                        new_deposit[index] = new_deposit
                    })
                    setDeposits(new_deposits)
                    setPages({...pages, pages: response.data.pages, page: params.page})
                } else {
                    ToastsStore.error("Could not load bank deposits")
                }
            })
            .catch(() => {
                ToastsStore.error("Could not load bank deposits")
                setSearching(false)
            })
    }

    const clear_deposits = () => {
        setDeposit(initial_deposit)
    }

    const save_deposits = () => {
        /* if (deposit.deposit_receipt === null) {
             setMessage({active: true, message: "Select a bank deposit receipt", type: 'error'})
         } else*/
        if (deposit.office_id === 0) {
            ToastsStore.error("Select office making the bank deposit")
        } else if (deposit.user_id === 0) {
            ToastsStore.error("Select user who made the deposit")
        } else if (deposit.account_id === 0) {
            ToastsStore.error("Select account money was deposited to")
        } else if (!Utils.is_valid_number(deposit.amount_deposited as string)) {
            ToastsStore.error("Enter valid amount deposited")
        } else {
            setLoading(true)
            Req.save_bank_deposits({
                account_id: deposit.account_id, amount_deposited: deposit.amount_deposited as number,
                date_deposited: deposit.date_deposited, deposit_id: deposit.deposit_id,
                deposit_receipt: deposit.deposit_receipt, office_id: deposit.office_id, user_id: deposit.user_id
            })
                .then((response) => {
                    setLoading(false)
                    if (response.data.hasOwnProperty("code") && response.data['code'] === 1) {
                        let new_deposit = {...deposit, deposit_id: response.data.deposit_id, date_modified: response.data.date_modified}
                        if (response.data['deposit_receipt'] !== "") {
                            new_deposit = {
                                ...new_deposit, deposit_receipt: new InputFile(`${Req.BASE_URL}${response.data.deposit_receipt}`)
                            }
                        }
                        if (deposit.deposit_id === 0) {
                            setDeposits(deposits => [...deposits, new_deposit])
                        } else {
                            setDeposits(deposits.map((_deposit) =>
                                _deposit.deposit_id === new_deposit.deposit_id ? new_deposit : _deposit
                            ))
                        }
                        setDeposit(new_deposit)
                        ToastsStore.success("Successfully saved deposit")
                    } else {
                        ToastsStore.error("Error while making deposit, please retry")
                    }
                })
                .catch(() => {
                    setLoading(false)
                    ToastsStore.error("Error while making deposit, please retry")
                })
        }
    }

    React.useEffect(() => {
        const account = Utils.get_bank_account(bankAccounts, deposit.account_id)
        setDeposit({...deposit, account_no: account.account_no, account_name: account.account_name, bank_name: account.bank_name})
    }, [deposit.account_id])

    React.useEffect(() => {
        setAccounts(Utils.get_bank_accounts(bankAccounts, {text: "Select a bank account", value: 0}))
    }, [bankAccounts])

    React.useEffect(() => {
        Req.get_bank_accounts()
            .then((response) => {
                if (response.data.hasOwnProperty("accounts")) {
                    setBankAccounts(response.data.accounts)
                }
            })
    }, [])

    return (
        <>
            <BankAccounts
                show={showAccounts} accounts={bankAccounts}
                close={(accounts: Array<BankAccount>) => {
                    setBankAccounts(accounts)
                    setShowAccounts(false)
                }}/>

            <div className='row m-0 h-100'>
                <div className='col-12 col-md-8 p-1 h-100'>
                    <div className="content_bar">
                        <div className="search_bar">
                            <div>
                                <MultiSelect
                                    multiple={true} placeholder="Select branches"
                                    items={params.branches.select} selected={search.branches}
                                    change={(value) => handle_search('branches', value)}/>
                            </div>
                            <div>
                                <MyDateInput
                                    value={search.min_date} name="min_date" maxDate={search.max_date}
                                    placeholder="Select min date" change={handle_search}/>
                            </div>
                            <div>
                                <MyDateInput
                                    value={search.max_date} name="max_date" minDate={search.min_date}
                                    placeholder="Select max date" change={handle_search}/>
                            </div>
                        </div>

                        <div className="button_bar">
                            <Button size='mini' content="Search" primary labelPosition="left"
                                    loading={searching} disabled={searching} icon="search"
                                    onClick={() => load_deposits({page: 1, pages: 0})}/>

                            <Button size='mini' content="Accounts" primary labelPosition="left" disabled={searching}
                                    onClick={() => setShowAccounts(true)} icon="th list"/>
                        </div>
                    </div>

                    <div className="table_container">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Date Deposited</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '180px'}}>Branch Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '180px'}}>Deposited By</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Deposit Amount</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    deposits.map((bank_deposit, index) =>
                                        <Table.Row key={bank_deposit.deposit_id} onClick={() => setDeposit(bank_deposit)}>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">
                                                {Utils.row_number(index, pages)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>
                                                {Utils.localise_date(bank_deposit.date_deposited)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '180px'}}>{bank_deposit.office_name}</Table.Cell>
                                            <Table.Cell style={{width: '180px'}}>{bank_deposit.user_name}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>
                                                {Utils.comma_number(bank_deposit.amount_deposited as number)}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>

                    <div className="table_footer">
                        <TablePagination
                            total={pages.pages} change={(page: number) => load_deposits({page: page, pages: pages.pages})}/>
                    </div>
                </div>

                <div className='col-12 col-md-4 p-1 h-100'>
                    <div className="form">
                        <div className="form_header">
                            {
                                deposit.deposit_id === 0 ? "Create new Bank Deposit"
                                    : `Change for ${deposit.user_name}, at ${Utils.localise_date(deposit.date_deposited)}`
                            }
                        </div>

                        <div className="form_content">
                            <div className="mb-2">
                                <div style={{height: '150px'}}>
                                    <Image centered
                                           src={deposit.deposit_receipt === null ? placeholder : deposit.deposit_receipt.file_src}/>
                                </div>
                                <div className="mt-2">
                                    <input type="file" className="input_file" id="deposit_receipt" accept="image/*"
                                           onChange={(event) => {
                                               const files = event.target.files;
                                               if (files !== null && files.length > 0) {
                                                   const file: File | null = files.item(0);
                                                   if (file !== null) {
                                                       Utils.format_file(file as File)
                                                           .then(result => {
                                                               setDeposit({...deposit, 'deposit_receipt': result})
                                                           })
                                                   }
                                               }
                                           }}/>
                                    <label htmlFor="deposit_receipt" className="ui tiny primary button fluid">
                                        <i className="ui upload icon"/>
                                        Select Bank Deposit Receipt
                                    </label>
                                </div>
                            </div>

                            <MySelect
                                change={(value, text) => {
                                    setDeposit({
                                        ...deposit, office_id: (value as number), office_name: text, user_id: 0, user_name: ''
                                    })
                                }}
                                title="Branch making the deposit" name="office_id" value={deposit.office_id}
                                options={[
                                    {text: "Select branch", value: 0},
                                    ...params.branchUser.branches
                                        .map((branch) =>
                                            ({text: branch.office_name, value: branch.office_id}))
                                ]}/>

                            <MySelect
                                change={(value, text) => {
                                    setDeposit({...deposit, user_name: text, user_id: value as number})
                                }}
                                title="User making the deposit" name="user_id" value={deposit.user_id}
                                placeholder="Select depositor"
                                options={[
                                    {text: "Select user", value: 0},
                                    ...params.branchUser.users
                                        .filter((user) => user.office_id === deposit.office_id && user.user_status === "active")
                                        .map((user) =>
                                            ({text: user.user_name, value: user.user_id}))
                                ]}/>

                            <MySelect
                                change={(value: select_type) => handle_change('account_id', (value as number))}
                                title="Select Bank Account" name="account_id" value={deposit.account_id}
                                options={accounts}/>

                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <MyInput
                                        placeholder="Enter account name" title="" disabled={true}
                                        name="account_name" change={handle_change} value={deposit.account_name}/>
                                </div>
                                <div className="col-6 pl-0 pr-0">
                                    <MyInput
                                        placeholder="Enter bank name" title="" disabled={true}
                                        name="bank_name" change={handle_change} value={deposit.bank_name}/>
                                </div>
                            </div>

                            <MyInput
                                placeholder="Enter amount deposited" title="Amount Deposited"
                                name="amount_deposited" change={handle_change} value={deposit.amount_deposited as string}/>

                            <MyDateInput
                                title="Select Date of Deposit" value={deposit.date_deposited} name="date_deposited"
                                change={handle_change} placeholder="Set date of deposit" maxDate={Utils.today()}/>
                        </div>

                        <div className="form_footer">
                            <div className='row m-0'>
                                <div className='col-6 pl-0 pr-1'>
                                    <Button negative onClick={clear_deposits}
                                            content='Clear Data' fluid size='tiny' icon='trash' labelPosition='right'/>
                                </div>
                                <div className='col-6 pl-1 pr-0'>
                                    <Button positive onClick={save_deposits} content='Save Deposit' size='tiny' icon='save'
                                            loading={loading} fluid labelPosition='left'
                                            disabled={loading || (deposit.deposit_id > 0 && deposit.accountant_id !== user.staff.user_id)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
