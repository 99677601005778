export interface Insurance {
    charge_amount: number
    charge_type: 'Flat' | 'Percentage'
}

export interface Charge {
    minimum: number
    amount: number
    charge_type: 'Flat' | 'Percentage'
}

export default function Settings() {
    return (
        <>
        </>
    )
}
