import React from "react";
import Utils, {InputFile} from "../../utils/Utils";
import {Loading, loading_params, LoadingParams, MyDateInput, MyInput, MySelect, select_type, SelectData, TablePagination} from "../../utils/Components";
import {Page, Req} from "../../utils/Req";
import {Button, Form, Image, Table, TextArea} from "semantic-ui-react";
import placeholder from "../../../assets/images/image.png";
import {useAppSelector} from "../../store/hooks";
import {getStaff} from "../../store/slices/userSlice";
import {ToastsStore} from "react-toasts";
import {BranchUser} from "../../nav/SideBar";
import {MultiSelect, multiselect_type} from "../../utils/MultiSelect";

interface Expense {
    expense_id: number
    expense_name: string
    expense_description: string
    expense_amount: string | number
    accountant_id: number
    accountant_name: string
    office_id: number
    office_name: string
    expense_date: string
    last_modified: string
    expense_receipt: InputFile | null
}

export default function ExpenseManagement(params: { branches: { select: SelectData[], selected: number[] }, branchUser: BranchUser }) {
    const user = useAppSelector(getStaff)

    const initial_expense: Expense = {
        accountant_id: user.staff.user_id, accountant_name: user.staff.last_name, expense_amount: "", expense_date: Utils.today(),
        expense_description: "", expense_id: 0, expense_name: "", last_modified: "", office_id: 0, office_name: "", expense_receipt: null
    }

    const [loading, setLoading] = React.useState<LoadingParams>(loading_params)

    const [expense, setExpense] = React.useState(initial_expense)
    const [expenses, setExpenses] = React.useState<Array<Expense>>([])
    const handle_change = (name: string, value: string | number) => {
        setExpense({...expense, [name]: value})
    }

    const [pages, setPages] = React.useState<Page>({page: 1, pages: 0, limit: 100})
    const [search, setSearch] = React.useState({
        min_date: Utils.weekly_date().sunday, max_date: Utils.today(), branches: params.branches.selected
    })
    const handle_search = (name: string, value: string | number | multiselect_type) => {
        setSearch({...search, [name]: value})
    }

    const [expenseNames, setExpenseNames] = React.useState<Array<string>>([])
    const load_expenses = (params: { page: number, pages: number }) => {
        if (search.branches.length === 0) {
            ToastsStore.info("Select at least 1 branch")
            return
        }

        setLoading({show: true, text: "Loading expenses, please wait"})
        Req.get_expenses({
            page: params.page, pages: params.pages, max_date: search.max_date,
            min_date: search.min_date, branches: JSON.stringify(search.branches),
        })
            .then((response) => {
                setLoading(loading_params)
                if (response.data.hasOwnProperty("expenses")) {
                    let new_expenses: Array<any> = response.data['expenses'];
                    new_expenses.forEach((new_expense, index) => {
                        new_expense.expense_receipt = new_expense.expense_receipt === "" ? null :
                            new InputFile(`${Req.BASE_URL}${new_expense.expense_receipt}`)
                        new_expense[index] = new_expense
                    })
                    setExpenses(new_expenses)
                    setPages({...pages, pages: response.data.pages, page: params.page})
                } else {
                    ToastsStore.error("Could not load expenses")
                }
            })
            .catch(() => {
                setLoading(loading_params)
                ToastsStore.error("Could not load expenses")
            })
    }

    const clear_expenses = () => {
        setExpense(initial_expense)
    }

    const save_expenses = () => {
        if (expense.office_id === 0) {
            ToastsStore.error("Select a branch")
        } else if (!Utils.is_valid_number(expense.expense_amount as string)) {
            ToastsStore.error("Enter valid expense amount")
        } else if (expense.expense_name.length < 3) {
            ToastsStore.error("Provide name to expense at least 3 characters")
        } else {
            setLoading({show: true, text: "Saving expense, please wait"})
            Req.save_expenses(
                {
                    expense_amount: parseInt(expense.expense_amount as string), expense_date: expense.expense_date,
                    expense_description: expense.expense_description, expense_name: expense.expense_name,
                    expense_id: expense.expense_id, expense_receipt: expense.expense_receipt, office_id: expense.office_id,
                })
                .then((response) => {
                    setLoading(loading_params)
                    if (response.data.hasOwnProperty("code")) {
                        switch (response.data['code']) {
                            case 1:
                                const new_expense = {...expense, expense_id: response.data.expense_id, last_modified: response.data.last_modified}
                                if (expense.expense_id === 0) {
                                    setExpenses(expenses => [new_expense, ...expenses])
                                } else {
                                    setExpenses(expenses.map((_expense) =>
                                        expense.expense_id === _expense.expense_id ? new_expense : _expense
                                    ))
                                }
                                setExpense(new_expense)
                                ToastsStore.success("Successfully saved expense")
                                break
                            case 2:
                                ToastsStore.error("Could not find expense to be modified")
                                break
                        }
                    } else {
                        ToastsStore.error("Could not find expense to be modified")
                    }
                })
                .catch(() => {
                    setLoading(loading_params)
                    ToastsStore.error("Could not find expense to be modified")
                })
        }
    }

    React.useEffect(() => {
        const initialise_expenses = () => {
            setLoading({...loading_params, show: true, text: 'Initialising expense data, please wait'})
            Req.initialise_expenses()
                .then((response) => {
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        setExpenseNames(response.data.expenses)
                        setLoading(loading_params)
                    } else {
                        setLoading({
                            error: "Could not get initial expense data, check your internet connection and retry",
                            reload: initialise_expenses, show: true, text: ""
                        })
                    }
                })
                .catch(() => {
                    setLoading({
                        error: "Could not get initial expense data, check your internet connection and retry",
                        reload: initialise_expenses, show: true, text: ""
                    })
                })
        }
        initialise_expenses()
    }, [])

    return (
        <>
            <datalist id='expense_info'>
                {expenseNames.map((name, index) => <option value={name} key={index}>{name}</option>)}
            </datalist>

            <Loading show={loading.show} text={loading.text} reload={loading.reload} error={loading.error}/>

            <div className="row m-0 h-100">
                <div className="col-12 col-md-8 p-1 h-100">
                    <div className="content_bar">
                        <div className="search_bar">
                            <div>
                                <MultiSelect
                                    multiple={true} placeholder="Select branches" items={params.branches.select} selected={search.branches}
                                    change={(value) => handle_search('branches', value)}/>
                            </div>
                            <div>
                                <MyDateInput
                                    title="" value={search.min_date} name="min_date" maxDate={search.max_date}
                                    placeholder="Minimum payment date" change={handle_search}/>
                            </div>
                            <div>
                                <MyDateInput
                                    title="" value={search.max_date} name="max_date" maxDate={Utils.today()} minDate={search.min_date}
                                    placeholder="Maximum payment date" change={handle_search}/>
                            </div>
                        </div>

                        <div className="button_bar">
                            <Button size='mini' content="Search" primary labelPosition="left" icon="search"
                                    onClick={() => load_expenses({page: 1, pages: 0})}/>
                        </div>
                    </div>

                    <div className="table_container">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Branch Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Expense Amount</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '180px'}}>Expense Reason</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Expense Date</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    expenses.map((expense, index) =>
                                        <Table.Row key={expense.expense_id} onClick={() => setExpense(expense)}>
                                            <Table.Cell style={{width: '40px'}} textAlign="center">
                                                {Utils.row_number(index, pages)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{expense.office_name}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>
                                                {Utils.comma_number(expense.expense_amount as number)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '180px'}}>{expense.expense_name}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.localise_date(expense.expense_date)}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>

                    <div className="table_footer">
                        <TablePagination
                            total={pages.pages} change={(page: number) => load_expenses({page: page, pages: pages.pages})}/>
                    </div>
                </div>

                <div className="col-12 col-md-4 p-1 h-100">
                    <div className="form">
                        <div className="form_header">
                            {expense.expense_id === 0 ? "Create new expense" : `Change for ${expense.expense_name}`}
                        </div>

                        <div className="form_content">
                            <div className="mb-2">
                                <div style={{height: '150px'}}>
                                    <Image centered src={expense.expense_receipt === null ? placeholder : expense.expense_receipt.file_src}/>
                                </div>
                                <div className="mt-2">
                                    <input type="file" className="input_file" id="expense_receipt" accept="image/*"
                                           onChange={(event) => {
                                               const files = event.target.files;
                                               if (files !== null && files.length > 0) {
                                                   const file: File | null = files.item(0);
                                                   if (file !== null) {
                                                       Utils.format_file(file as File)
                                                           .then(result => {
                                                               setExpense({...expense, 'expense_receipt': result})
                                                           })
                                                   }
                                               }
                                           }}/>
                                    <label htmlFor="expense_receipt" className="ui tiny primary button fluid">
                                        <i className="ui upload icon"/>
                                        Select receipt of expense if any
                                    </label>
                                </div>
                            </div>

                            <MySelect
                                change={(value: select_type) => handle_change('office_id', (value as number))}
                                title="Select a branch" value={expense.office_id}
                                options={[{text: "Select a branch", value: 0}, ...params.branches.select]}/>

                            <div style={{marginBottom: '15px'}}>
                                <MyDateInput
                                    title="Select Date of Expense" value={expense.expense_date} name="expense_date"
                                    change={handle_change} placeholder="Set date of expense" maxDate={Utils.today()}/>
                            </div>

                            <MyInput
                                placeholder="Enter expense amount" title="Amount of Expense"
                                name="expense_amount" change={handle_change} value={expense.expense_amount as string}/>

                            <MyInput
                                placeholder="Enter expense name e.g airtime, lunch allowance" title="Name of Expense"
                                value={expense.expense_name} name="expense_name" change={handle_change} list="expense_info"/>

                            <Form>
                                <TextArea placeholder='Describe the expense' rows={6} value={expense.expense_description}
                                          onChange={((event, data) => {
                                              handle_change('expense_description', data.value as string)
                                          })}/>
                            </Form>
                        </div>

                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <Button negative onClick={clear_expenses} content="Clear Data"
                                            icon="trash" size="mini" fluid labelPosition="left"/>
                                </div>
                                <div className="col-6 pl-1 pr-0">
                                    <Button positive onClick={save_expenses} content="Save Expense"
                                            icon="save" size="mini" fluid labelPosition="left"
                                            disabled={expense.expense_id > 0 && expense.accountant_id !== user.staff.user_id}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
