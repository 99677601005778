import React from "react";
import Utils from "../../../utils/Utils";
import {Table} from "semantic-ui-react";

interface Expense {
    accountant_name: string
    expense_amount: number
    expense_date: string
    expense_description: string
    expense_name: string
    expense_receipt: string
    office_name: string
}

export default function ExpensesReport(params: { transactions: any }) {
    const [statements, setStatements] = React.useState<Array<Expense>>([])

    React.useEffect(() => {
        if (params.transactions.hasOwnProperty("rp")) {
            setStatements(params.transactions.rp)
        }
    }, [params.transactions])

    return (
        <Table celled striped compact size='small' inverted color='grey' selectable className="has_total">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '170px'}}>Branch Name</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '140px'}}>Created By</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '200px'}}>Expense Name</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '130px'}}>Expense Amount</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '130px'}}>Receipt No.</Table.HeaderCell>
                    <Table.HeaderCell className="time_row">Expense Date</Table.HeaderCell>
                    <Table.HeaderCell style={{width: '300px'}}>Expense Description</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {
                    statements
                        .map((expense, index) => <>
                                <Table.Row key={index}>
                                    <Table.Cell style={{width: '50px'}} textAlign="center">
                                        {(index < statements.length - 1) && Utils.row_number(index)}
                                    </Table.Cell>
                                    <Table.Cell style={{width: '170px'}}>{expense.office_name}</Table.Cell>
                                    <Table.Cell style={{width: '140px'}}>{expense.accountant_name}</Table.Cell>
                                    <Table.Cell style={{width: '200px'}}>{expense.expense_name}</Table.Cell>
                                    <Table.Cell style={{width: '130px'}}>{Utils.comma_number(expense.expense_amount)}</Table.Cell>
                                    <Table.Cell style={{width: '130px'}}>{expense.expense_receipt}</Table.Cell>
                                    <Table.Cell className="time_row">{Utils.localise_date(expense.expense_date)}</Table.Cell>
                                    <Table.Cell style={{width: '300px'}}>{expense.expense_description}</Table.Cell>
                                </Table.Row>
                            </>
                        )
                }
            </Table.Body>
        </Table>
    )
}
