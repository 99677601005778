import {Button, Dropdown, Icon, Input, Label, Loader, Modal, Pagination} from "semantic-ui-react";
import React from "react";

export function MyLabel(props: { error?: boolean, message?: string, title: string }) {
    return (
        <Label style={{marginBottom: '5px', marginTop: '0px', display: 'block', width: '100%'}} color={props.error ? 'red' : 'grey'}>
            {props.error ? props.message : props.title}
        </Label>
    )
}

export function MyDateInput(props: {
    title?: string, minDate?: string, maxDate?: string, value: string, name: string, placeholder: string, className?: string,
    change?: (name: string, value: string) => void, message?: string, error?: boolean, disabled?: boolean, type?: 'date' | 'time'
}) {
    return (
        <>
            {props.title !== undefined && props.title !== "" && <MyLabel title={props.title} message={props.message} error={props.error}/>}
            <input className="date_input" name={props.name} placeholder={props.placeholder} value={props.value}
                   type={props.type === undefined ? 'date' : props.type} max={props.maxDate}
                   min={props.minDate} title={props.placeholder} disabled={props.disabled !== undefined ? props.disabled : false}
                   onChange={(event) => {
                       if (props.change) {
                           props.change(event.target.name, event.target.value)
                       }
                   }}/>
        </>
    )
}

export type select_type = string | number | boolean | (string | number | boolean)[] | undefined

export interface SelectData {
    text: string
    value: string | number
}

export function MySelect(props: {
    change?: (value: select_type, text: string) => void, onChange?: (name: string, value: select_type, text: string) => void,
    title?: string, name?: string, value: select_type, style?: Object, className?: string, loading?: boolean, placeholder?: string,
    options: Array<{ text: string, value: string | number }>, error?: boolean, message?: string, disabled?: boolean,
    search?: (query: string) => void
}) {
    return (
        <>
            {props.title !== undefined && props.title !== "" && <MyLabel title={props.title} message={props.message} error={props.error}/>}
            <Dropdown
                value={props.value} options={props.options} size="mini" fluid selection search={true}
                style={props.style === undefined ? {marginBottom: '15px'} : props.style}
                className={props.className !== undefined ? props.className : ''}
                disabled={props.disabled !== undefined ? props.disabled : false}
                placeholder={props.placeholder ? props.placeholder : 'Select a value'}
                loading={props.loading === undefined ? false : props.loading}

                onSearchChange={(event, data) => {
                    if (props.search !== undefined) {
                        props.search(data.searchQuery)
                    }
                }}
                onChange={((event, data) => {
                    const option = props.options.filter((option) => option.value === data.value)
                    if (props.onChange !== undefined && props.name !== undefined) {
                        props.onChange(props.name, data.value, option.length > 0 ? option[0].text : "")
                    } else if (props.change !== undefined) {
                        props.change(data.value, option.length > 0 ? option[0].text : "")
                    }
                })}/>
        </>
    )
}

export function MyInput(
    props: {
        change?: (name: string, value: string) => void, placeholder: string, title?: string, name: string, icon?: string,
        value: string, disabled?: boolean, style?: Object, className?: string, enter?: () => void, type?: string, list?: string
    }) {
    return (
        <>
            {props.title !== undefined && props.title !== "" && <MyLabel title={props.title}/>}
            <Input placeholder={props.placeholder} fluid size="mini" name={props.name} value={props.value}
                   style={props.style === undefined ? {marginBottom: '15px'} : props.style}
                   disabled={props.disabled !== undefined && props.disabled}
                   className={props.className !== undefined ? props.className : ''}
                   title={props.placeholder} type={props.type === undefined ? 'text' : props.type}
                   list={props.list} icon={props.icon}
                   onChange={(event, data) => {
                       if (props.change !== undefined) {
                           props.change(event.target.name, data.value)
                       }
                   }}
                   onKeyDown={(event: any) => {
                       if (event.keyCode === 13 && props.enter !== undefined) {
                           props.enter()
                       }
                   }}
            />
        </>
    )
}

export interface LoadingParams {
    show: boolean
    text: string
    reload?: () => void
    error?: string
}

export const loading_params: LoadingParams = {show: false, text: "", reload: undefined, error: undefined}

export function Loading(params: { show: boolean, text: string, hide?: () => void, reload?: () => void, error?: string }) {
    const [message, setMessage] = React.useState("")

    React.useEffect(() => {
        setMessage(params.error === undefined ?
            'Error while loading initial data, please check your internet connection and then click retry' : params.error
        )
    }, [params.error])

    return (
        <Modal size='mini' basic closeOnEscape={false} closeOnDimmerClick={false} dimmer="blurring" open={params.show}>
            <>
                {
                    params.error === undefined || params.reload === undefined ?
                        <Loader active inline='centered'>{params.text}</Loader>
                        :
                        <div style={{background: 'white', padding: '10px 20px'}}>
                            <div dangerouslySetInnerHTML={{__html: message}}
                                 style={{color: 'black', fontSize: '15px', fontWeight: 'bold', textAlign: 'center', lineHeight: '18px'}}/>
                            <Button primary onClick={params.reload} fluid icon="refresh" className="mt-4"
                                    size="tiny" content="Click to Retry" labelPosition="left"/>
                        </div>
                }
            </>
        </Modal>)
}

export function TablePagination(props: { total: number, change: (page: number) => void, activePage?: number }) {
    return (
        <Pagination
            activePage={props.activePage}
            ellipsisItem={{content: <Icon name='ellipsis horizontal'/>, icon: true}}
            firstItem={{content: <Icon name='angle double left'/>, icon: true}}
            lastItem={{content: <Icon name='angle double right'/>, icon: true}}
            prevItem={{content: <Icon name='angle left'/>, icon: true}}
            nextItem={{content: <Icon name='angle right'/>, icon: true}}
            totalPages={props.total} size='tiny'
            onPageChange={((event, data) => {
                props.change(data.activePage as number)
            })}
        />
    )
}

export function PDFReader(props: { source: string, open: boolean, close: () => void }) {
    const [source, setSource] = React.useState("")
    React.useEffect(() => {
        if (props.source !== "") {
            // setSource("https://docs.google.com/gview?url=" + props.source + "&embedded=true")
            setSource(props.source)
        } else {
            setSource("")
        }
    }, [props.source])
    return (
        <>
            <Modal size='large' open={props.open} onClose={props.close} centered={false}>
                <div className="pdf_container">
                    {props.source !== "" && <iframe src={source} style={{width: '100%', height: '100%'}} frameBorder="0" title="PDF Reader"/>}
                </div>
            </Modal>
        </>)
}
