import React from "react";
import Utils from "../../../utils/Utils";
import {Table} from "semantic-ui-react";

interface Report {
    branch_name: string
    income_balance: number
    loan_type: number
    loans: number
    principal_balance: number
    principal_collection: number
    principal_disbursed: number
    total_collection: number
    type_name: string
}

export default function LoanPortfolioReport(params: { transactions: any }) {
    const [statements, setStatements] = React.useState<Array<Report>>([])

    React.useEffect(() => {
        if (params.transactions.hasOwnProperty("rp")) {
            setStatements(params.transactions.rp)
        }
    }, [params.transactions])

    return (
        <>
            <Table celled striped compact size='small' inverted color='grey' selectable className="has_total">
                <Table.Header>
                    <Table.Row key={0}>
                        <Table.HeaderCell style={{width: '50px'}} textAlign="center" rowSpan={2}>No.</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '150px'}} rowSpan={2}>Branch Name</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '250px'}} rowSpan={2}>Loan Type</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '70px'}} rowSpan={2} textAlign="center">Loans</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '360px'}} textAlign="center" colSpan={3}>Principal Disbursed</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '240px'}} textAlign="center" colSpan={2}>Incomes Data</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '140px'}} rowSpan={2}>Total Balance</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row key={1}>
                        <Table.HeaderCell className='amount_row'>Disbursed</Table.HeaderCell>
                        <Table.HeaderCell className='amount_row'>Collected</Table.HeaderCell>
                        <Table.HeaderCell className='amount_row'>Balance</Table.HeaderCell>
                        <Table.HeaderCell className='amount_row'>Collected</Table.HeaderCell>
                        <Table.HeaderCell className='amount_row'>Balances</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        statements
                            .map((transaction, index) => <>
                                    <Table.Row key={index}>
                                        <Table.Cell style={{width: '50px'}} textAlign="center">
                                            {(index < statements.length - 1) && (index + 1)}
                                        </Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>{transaction.branch_name}</Table.Cell>
                                        <Table.Cell style={{width: '250px'}}>{transaction.type_name}</Table.Cell>
                                        <Table.Cell style={{width: '70px'}} textAlign="center">
                                            {Utils.comma_number(transaction.loans, '', '')}
                                        </Table.Cell>
                                        <Table.Cell className='amount_row'>{Utils.comma_number(transaction.principal_disbursed)}</Table.Cell>
                                        <Table.Cell className='amount_row'>{Utils.comma_number(transaction.principal_collection)}</Table.Cell>
                                        <Table.Cell className='amount_row'>{Utils.comma_number(transaction.principal_balance)}</Table.Cell>
                                        <Table.Cell className='amount_row'>{Utils.comma_number(transaction.total_collection)}</Table.Cell>
                                        <Table.Cell className='amount_row'>{Utils.comma_number(transaction.income_balance)}</Table.Cell>
                                        <Table.Cell style={{width: '140px'}}>
                                            {Utils.comma_number(transaction.principal_balance + transaction.income_balance)}
                                        </Table.Cell>
                                    </Table.Row>
                                </>
                            )
                    }
                </Table.Body>
            </Table>
        </>
    )
}
