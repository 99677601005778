import {MyInput, MySelect, SelectData, TablePagination} from "../../../utils/Components";
import {ToastsStore} from "react-toasts";
import {Page, Req} from "../../../utils/Req";
import React from "react";
import {ShowLoan} from "../container/ShowLoan";
import ListingTable, {ListingLoan} from "./ListingTable";
import {MultiSelect} from "../../../utils/MultiSelect";
import {Button} from "semantic-ui-react";

interface Search {
    name: string
    loan_id: string
    branches: number[]
    month: number
    year: number
}

export default function LoanListing(params: {
    branches: { select: SelectData[], selected: number[] },
    status: 'active' | 'extended' | 'all' | 'deleted' | 'modified' | 'monthly'
}) {
    const [loading, setLoading] = React.useState(false)
    const [search, setSearch] = React.useState<Search>({
        name: '', loan_id: '', branches: params.branches.selected,
        month: (new Date()).getMonth(), year: (new Date()).getFullYear()
    })
    const [loans, setLoans] = React.useState<Array<ListingLoan>>([])
    const [showLoan, setShowLoanInfo] = React.useState({show: false, loan_id: 0})
    const [pages, setPages] = React.useState<Page>({page: 1, pages: 0, limit: 100})

    const handle_search = (name: string, value: string | number | Array<number | string>) => {
        setSearch({...search, [name]: value})
    }

    const get_months = () => {
        const date = new Date()
        let times: Array<SelectData> = []
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        for (let month = (search.year === date.getFullYear() ? date.getMonth() : 11); month >= 0; month--) {
            times.push({text: months[month], value: month})
        }
        return times
    }

    const get_years = () => {
        let times: Array<SelectData> = []
        for (let year = (new Date()).getFullYear(); year >= 2019; year--) {
            times.push({text: year.toString(), value: year})
        }
        return times
    }

    React.useEffect(() => {
        if (search.year === (new Date()).getFullYear()) {
            setSearch({...search, month: (new Date()).getMonth()})
        } else {
            setSearch({...search, month: 0})
        }
    }, [search.year])

    const get_loans = (props: { page: number, pages: number }) => {
        if (search.branches.length === 0) {
            ToastsStore.error("No branches selected, reload page if branches select is empty")
            return
        }

        setLoading(true)
        Req.get_loans_listing_portfolio({
            branches: JSON.stringify(search.branches), status: params.status, loan_id: search.loan_id,
            name: search.name, loan_type: 0, user_id: 0, page: props.page, pages: props.pages,
            year: search.year, month: search.month
        })
            .then((response) => {
                setLoading(false)
                if (response.data.hasOwnProperty("loans")) {
                    setLoans(response.data.loans)
                    setPages({pages: response.data.pages, page: props.page, limit: response.data.limit})
                } else {
                    ToastsStore.error("Failed to get loan portfolios")
                }
            })
            .catch(() => {
                setLoading(false)
                ToastsStore.error("Failed to get loan portfolios")
            })
    }

    React.useEffect(() => {
        setLoans([])
        setSearch({name: '', branches: params.branches.selected, loan_id: '', month: (new Date()).getMonth(), year: (new Date()).getFullYear()})
        setPages({page: 1, pages: 0, limit: 100})
        setShowLoanInfo({show: false, loan_id: 0})
    }, [params.status])

    return (
        <>
            {showLoan.show && <ShowLoan close={() => setShowLoanInfo({loan_id: 0, show: false})} loan_id={showLoan.loan_id}/>}

            {
                !showLoan.show &&
                <>
                    <div className="content_bar">
                        <div className="search_bar">
                            {
                                params.status === "monthly" &&
                                <>
                                    <div>
                                        <MySelect
                                            placeholder="Select a year" options={get_years()} value={search.year}
                                            onChange={(name, value) => handle_search('year', value as number)}/>
                                    </div>

                                    <div>
                                        <MySelect
                                            placeholder="Select a month" options={get_months()} value={search.month}
                                            onChange={(name, value) => handle_search('month', value as number)}/>
                                    </div>
                                </>

                            }
                            <div>
                                <MultiSelect
                                    multiple={true} placeholder="Select offices" items={params.branches.select} selected={search.branches}
                                    change={(value) => handle_search('branches', value)}/>
                            </div>
                            <div>
                                <MyInput
                                    placeholder="Search by client name" title="" name="name" value={search.name}
                                    change={handle_search} enter={() => get_loans({pages: 0, page: 1})}/>
                            </div>
                            <div>
                                <MyInput
                                    placeholder="Search by loan no" title="" name="loan_id" value={search.loan_id}
                                    change={handle_search} enter={() => get_loans({page: 1, pages: 0})}/>
                            </div>
                        </div>
                        <div className="button_bar">
                            <Button floated='right' size='mini' icon='search' disabled={loading} loading={loading}
                                    content="Search" primary labelPosition="left" onClick={() => get_loans({pages: 0, page: 1})}/>
                        </div>
                    </div>

                    <div className="table_container">
                        <ListingTable loans={loans} click={(loan_id) => setShowLoanInfo({loan_id: loan_id, show: true})}/>
                    </div>

                    <div className="table_footer">
                        <TablePagination total={pages.pages} change={(page: number) => get_loans({page: page, pages: pages.pages})}/>
                    </div>
                </>
            }
        </>
    )
}
