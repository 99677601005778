import React from "react"
import {Loading, SelectData} from "../../../utils/Components";
import {Button, Table} from "semantic-ui-react";
import {Req} from "../../../utils/Req";
import {ToastsStore} from "react-toasts";
import Utils from "../../../utils/Utils";
import {useAppSelector} from "../../../store/hooks";
import {getStaff} from "../../../store/slices/userSlice";
import {Rights} from "../../admin/users/rights/AccessRights";
import {loan_statuses, ShowLoan} from "../container/ShowLoan";
import {MultiSelect} from "../../../utils/MultiSelect";
import {BranchUser} from "../../../nav/SideBar";

interface Loan {
    loan_id: number
    office_name: string
    client_name: string
    created_by: string
    date_created: string
    loan_status: loan_statuses
    loan_principal: number
    loan_rate: number
    payment_interval: number
    loan_period: number
    due_date: string
    projected_interest: number
}

export default function LoanActions(params: { branchUser: BranchUser }) {
    const user = useAppSelector(getStaff)
    const [loading, setLoading] = React.useState({show: false, message: "Fetching loans, please wait"})
    const [loans, setLoans] = React.useState<Array<Loan>>([])
    const [loanModal, setLoanModal] = React.useState({show: false, loan_id: 0})

    const [offices, setOffices] = React.useState<Array<SelectData>>([])
    const [actions, setActions] = React.useState<Array<SelectData>>([])

    const [search, setSearch] = React.useState<{ branches: Array<number>, loan_status: Array<loan_statuses> }>(
        {loan_status: [], branches: []})
    const handle_search = (name: string, value: Array<number | string>) => {
        setSearch({...search, [name]: value})
    }

    const load_loans = (params: { branches: Array<number>, loan_status: Array<loan_statuses> }) => {
        setLoading({...loading, show: true})
        Req.get_loan_actions(
            {branches: JSON.stringify(params.branches), loan_status: JSON.stringify(params.loan_status),}
        )
            .then((response) => {
                setLoading({...loading, show: false})
                if (response.data.hasOwnProperty("loans")) {
                    setLoans(response.data.loans)
                    if (response.data.loans.length === 0) {
                        ToastsStore.error("No actions are required")
                    }
                } else {
                    ToastsStore.error("Could not fetch loans, please retry")
                }
            })
            .catch(() => {
                setLoading({...loading, show: false})
                ToastsStore.error("Could not fetch loans, please retry")
            })
    }

    const action_name = (status: loan_statuses): string => {
        if (status === 'pending') {
            return "Pending"
        } else if (status === 'rejected') {
            return "Rejected"
        } else if (status === 'accepted') {
            return "Accepted"
        } else if (status === 'verified') {
            return "Verified"
        }
        return ""
    }

    React.useEffect(() => {
        const initialise_combo = () => {
            let create_select = Utils.format_accessible_office(params.branchUser.branches, user.roles.user_roles, Rights.create_loan)
            let disburse_select = Utils.format_accessible_office(params.branchUser.branches, user.roles.user_roles, Rights.disburse_loan)
            let approve_select = Utils.format_accessible_office(params.branchUser.branches, user.roles.user_roles, Rights.approve_loan)
            let verify_select = Utils.format_accessible_office(params.branchUser.branches, user.roles.user_roles, Rights.verify_loan)

            let selected: Array<number> = []
            let combos: Array<SelectData> = []

            const format_data = (data: Array<SelectData>) => {
                data.forEach((selectData) => {
                    if (!selected.includes(selectData.value as number)) {
                        selected = [...selected, selectData.value as number]
                        combos = [...combos, selectData]
                    }
                })
            }
            format_data(create_select.select)
            format_data(disburse_select.select)
            format_data(approve_select.select)

            let _actions: Array<loan_statuses> = []
            let _actions_: Array<SelectData> = []
            if (create_select.selected.length > 0 || approve_select.selected.length > 0) {
                _actions = [..._actions, 'pending', 'rejected']
                _actions_ = [..._actions_, {text: 'Pending loans', value: 'pending'}, {text: 'Rejected loans', value: 'rejected'}]
            }
            if (verify_select.selected.length > 0) {
                _actions = [..._actions, 'accepted']
                _actions_ = [..._actions_, {text: 'Approved Loans', value: 'accepted'}]
            }
            if (disburse_select.selected.length > 0) {
                _actions = [..._actions, 'verified']
                _actions_ = [..._actions_, {text: 'Verified Loans', value: 'verified'}]
            }

            setSearch({branches: selected, loan_status: _actions})
            setOffices(combos)
            setActions(_actions_)
            load_loans({branches: selected, loan_status: _actions})
        }
        initialise_combo()
    }, [params.branchUser])


    return (
        <>
            <Loading show={loading.show} text={loading.message}/>

            {loanModal.show && <ShowLoan close={() => setLoanModal({...loanModal, 'show': false})} loan_id={loanModal.loan_id}/>}

            {
                !loanModal.show &&
                <>
                    <div className="content_bar">
                        <div className="search_bar">
                            <div>
                                <MultiSelect
                                    multiple={true} placeholder="Branches" items={offices} selected={search.branches}
                                    change={(value) => handle_search('branches', value as Array<number>)}/>
                            </div>
                            <div>
                                <MultiSelect
                                    multiple={true} placeholder="Loan status" selected={search.loan_status} items={actions}
                                    change={(value) => handle_search('loan_status', value as Array<number>)}/>
                            </div>
                        </div>

                        <div className="button_bar">
                            <Button
                                primary size='mini' icon='search' labelPosition="left"
                                content="Search" onClick={() => load_loans(search)}/>
                        </div>
                    </div>

                    <div className="table_container container_no_footer">
                        <Table celled striped compact size='small' selectable inverted color='grey' unstackable={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '70px'}} textAlign="center">Loan No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '160px'}}>Branch</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '200px'}}>Created By</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '200px'}}>Client Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '80px'}}>Status</Table.HeaderCell>
                                    <Table.HeaderCell className="amount_row">Principal</Table.HeaderCell>
                                    <Table.HeaderCell className="amount_row">Interest</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '90px'}}>Period</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '70px'}} textAlign="center">Rate</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '70px'}} textAlign="center">Interval</Table.HeaderCell>
                                    <Table.HeaderCell className='time_row'>Date Created</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    loans.map((loan, index) =>
                                        <Table.Row onClick={() => setLoanModal({loan_id: loan.loan_id, show: true})} key={loan.loan_id}>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                            <Table.Cell style={{width: '70px'}} textAlign="center">{loan.loan_id}</Table.Cell>
                                            <Table.Cell style={{width: '160px'}}>{loan.office_name}</Table.Cell>
                                            <Table.Cell style={{width: '200px'}}>{loan.created_by}</Table.Cell>
                                            <Table.Cell style={{width: '200px'}}>{loan.client_name}</Table.Cell>
                                            <Table.Cell style={{width: '80px'}}>{action_name(loan.loan_status)}</Table.Cell>
                                            <Table.Cell className="amount_row">{Utils.comma_number(loan.loan_principal)}</Table.Cell>
                                            <Table.Cell className="amount_row">{Utils.comma_number(loan.projected_interest)}</Table.Cell>
                                            <Table.Cell style={{width: '90px'}}>{loan.loan_period} Months</Table.Cell>
                                            <Table.Cell style={{width: '70px'}} textAlign="center">
                                                {loan.loan_rate.toFixed(2)}%
                                            </Table.Cell>
                                            <Table.Cell style={{width: '70px'}}>
                                                {`${loan.payment_interval === 30 ? '1 Month' : loan.payment_interval + ' days'}`}
                                            </Table.Cell>
                                            <Table.Cell className='time_row'>{Utils.localise_date(loan.date_created)}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </>
            }
        </>
    )
}
