import React from "react";
import {Button} from "semantic-ui-react";
import ChangePassword from "./ChangePassword";
import {Req} from "../../../../utils/Req";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {getFCM, getStaff, loginUser} from "../../../../store/slices/userSlice";
import {MyInput} from "../../../../utils/Components";
import {updateCompany, updateDate} from "../../../../store/slices/systemSlice";

export default function Login() {
    const dispatch = useAppDispatch()
    const user = useAppSelector(getStaff)
    const fcm = useAppSelector(getFCM)

    const [loginData, setLoginData] = React.useState({credential: "", login_password: ""})
    const [error, setError] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [changePassword, setChangePassword] = React.useState(false)
    const [disable, setDisable] = React.useState(user.staff.user_id > 0)

    const login_user = (password: string) => {
        setLoading(true)
        setError("")
        Req.login_user({credential: loginData.credential, fetch_data: 1, login_password: password, fcm_token: fcm.token})
            .then(response => {
                setLoading(false)
                if (response.data.code === 1) {
                    setLoginData({...loginData, login_password: "", credential: ""})

                    if (response.hasOwnProperty("data")) {
                        const data = response.data.data
                        dispatch(
                            loginUser({
                                staff: {
                                    first_name: data.first_name,
                                    user_gender: data.user_gender,
                                    last_name: data.last_name,
                                    main_contact: data.main_contact,
                                    office_id: data.office_id,
                                    other_contact: data.other_contact,
                                    user_name: data.user_name,
                                    user_avatar: {file: undefined, file_src: data.user_avatar === "" ? "" : `${Req.BASE_URL}${data.user_avatar}`},
                                    user_email: data.user_email,
                                    user_id: data.user_id,
                                    office_name: data.office_name,
                                },
                                roles: {user_roles: data.access_rights, report_rights: data.report_rights},
                                user_token: data.user_token,
                            })
                        )
                        dispatch(updateDate(data.config.current_date))
                        dispatch(updateCompany(data.config.company))
                    }
                } else if (response.data.code === 3) {
                    setChangePassword(true)
                } else {
                    setError(response.data.msg)
                }
            })
            .catch(() => {
                setLoading(false)
                setError("Could not login at the moment, please retry")
            })
    }

    React.useEffect(() => {
        setLoginData({...loginData, credential: user.staff.user_email})
        setDisable(user.staff.user_id > 0)
    }, [user])

    return (
        <>
            <ChangePassword
                show={changePassword}
                close={(status: boolean, password: string) => {
                    if (status) {
                        setLoginData({...loginData, login_password: password})
                        login_user(password)
                    }
                    setChangePassword(false)
                }}
                credential={loginData.credential}
                logged_in={false}/>

            <div className="login_container">
                <div className="login_modal">
                    <h2 className=''>Login to proceed</h2>

                    <form>
                        <MyInput
                            placeholder="Email or contact or username" disabled={disable} title="" name="credential"
                            value={loginData.credential} icon="user" type="text"
                            change={(name: string, value: string) => setLoginData({...loginData, [name]: value})}/>

                        <MyInput
                            placeholder="Login password" title="" name="login_password"
                            value={loginData.login_password} icon="lock" type="password"
                            enter={() => login_user(loginData.login_password)}
                            change={(name: string, value: string) => setLoginData({...loginData, [name]: value})}/>

                        <Button primary className="ui button fluid" size='small' loading={loading} disabled={loading}
                                content="Login User" onClick={() => login_user(loginData.login_password)}/>

                        {
                            user.staff.user_id > 0 &&
                            <div className="not_user">Not {user.staff.user_name}?&nbsp;&nbsp;
                                <span className="link"
                                      onClick={() => {
                                          setDisable(false)
                                          setLoginData({credential: "", login_password: ""})
                                      }}
                                >Proceed</span>
                            </div>
                        }
                    </form>

                    {error !== "" && <div className='ui error message'>{error}</div>}
                </div>
            </div>
        </>
    )
}
