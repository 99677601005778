import React from "react";
import Utils from "../../../../utils/Utils";
import {MyDateInput, MyInput, MySelect, select_type} from "../../../../utils/Components";
import {LoanInfo, LoanProps, TypeCharge} from "../ShowLoan";
import {LoanType} from "../../../admin/loans/LoanTypes";
import {Table} from "semantic-ui-react";

export default function LoanStructure(params: {
    loanProps: LoanProps, loanInfo: LoanInfo, setLoanInfo: (loanInfo: LoanInfo) => void, editDisabled: boolean,
    charges: TypeCharge[], setCharges: (charges: TypeCharge[]) => void
}) {
    const handle_change = (name: string, value: string | number | select_type | LoanType | null) => {
        let loanInfo: LoanInfo = {...params.loanInfo, [name]: value}
        if (['loan_principal', 'loan_type', 'rate_type', 'payment_interval', 'loan_rate', 'loan_period', 'start_date'].includes(name)) {
            loanInfo = {...loanInfo, expected_schedule: [], actual_schedule: [], projected_interest: '', due_date: '',}
        }
        params.setLoanInfo(loanInfo)
    }

    const set_data = (value: string | number) => {
        return params.editDisabled ? Utils.comma_number(parseFloat(value.toString())) : Utils.comma_input(value)
    }

    const get_charge_value = (charge_id: number): string => {
        const principal: string = Utils.is_valid_number(Utils.strip_commas(params.loanInfo.loan_principal)) ?
            Utils.strip_commas(params.loanInfo.loan_principal) : "0"
        const charges = params.charges.filter((charge) => charge.charge_id === charge_id)
        if (charges.length === 1) {
            return Utils.comma_number(charges[0].charge_type === 'Flat' ?
                charges[0].charge_value : (charges[0].charge_value * parseFloat(principal)) / 100
            )
        }
        return "N/A"
    }

    const get_charge = (charge_id: number): string => {
        const charges = params.charges.filter((charge) => charge.charge_id === charge_id)
        if (charges.length === 1) {
            return charges[0].charge_amount
        }
        return "N/A"
    }

    React.useEffect(() => {
        const calculate_loan_charges = () => {
            const charges: TypeCharge[] = params.loanProps.charges
                .filter((charge) => charge.type_id === params.loanInfo.loan_type?.type_id)
                .map((charge) => ({fee_id: 0, charge_id: charge.charge_id, charge_type: 'Flat', charge_value: 0, charge_amount: "",}))

            const principal: string = Utils.is_valid_number(Utils.strip_commas(params.loanInfo.loan_principal)) ?
                Utils.strip_commas(params.loanInfo.loan_principal) : "0"

            if (params.loanInfo.loan_type === null || !Utils.is_valid_number(principal)) {
                params.setCharges(charges)
            } else {
                const loan_charges = params.loanProps.charges
                    .filter((charge) => charge.type_id === params.loanInfo.loan_type?.type_id)
                params.setCharges(
                    charges.map((charge) => {
                        let loan_charge = loan_charges.filter((loan_charge) => loan_charge.charge_id === charge.charge_id)

                        if (loan_charge.length === 1) {
                            let fees = loan_charge[0].fees
                                .filter((fee) => parseFloat(principal) >= fee.min_value)
                                .sort((a, b) => b.min_value - a.min_value)
                            if (fees.length > 0) {
                                return {
                                    ...charge, charge_type: fees[0].charge_type, charge_value: fees[0].charge_value,
                                    charge_amount: fees[0].charge_type === "Flat" ? fees[0].charge_value.toString() :
                                        ((fees[0].charge_value * parseFloat(principal)) / 100).toString()
                                }
                            }
                        }
                        return charge
                    }))
            }
        }
        calculate_loan_charges()
    }, [params.loanInfo.loan_type, params.loanInfo.loan_principal])

    return (
        <>
            <div className="row mx-0">
                {/*Loan Type*/}
                <div className="col-12 col-md-3 loan_form">
                    <div>
                        <MySelect
                            onChange={handle_change} value={params.loanInfo.inspector_id} name="inspector_id"
                            title="Loan inspecting officer" disabled={params.editDisabled}
                            options={[
                                {text: 'Select a loan inspector', value: 0},
                                ...params.loanProps.staffs
                                    .filter((staff) => params.loanInfo.loan_id === 0 ? staff.user_id > 0 : true)
                                    .map((staff) => ({text: staff.user_name, value: staff.user_id}))
                            ]}/>

                        <MySelect
                            change={(value: select_type) => {
                                const loan_type = Utils.get_loan_type(params.loanProps.loanTypes, parseInt(value as string));
                                let loanInfo: LoanInfo
                                if (loan_type === null) {
                                    loanInfo = {...params.loanInfo, loan_rate: '', start_date: Utils.today(), loan_type: loan_type}
                                } else {
                                    const days = (loan_type.start_days as number) < 30 ? loan_type.start_days : undefined
                                    const months = (loan_type.start_days as number) < 30 ? undefined : loan_type.start_days / 30
                                    const start_date = Utils.format_payment_date(Utils.today(), months, days)

                                    loanInfo = {
                                        ...params.loanInfo, payment_interval: loan_type.payment_interval,
                                        loan_rate: loan_type.loan_rate, start_date: start_date, loan_type: loan_type
                                    }
                                }
                                params.setLoanInfo(loanInfo)
                            }}
                            title="Loan Type" name="loan_type" disabled={params.editDisabled}
                            value={params.loanInfo.loan_type !== null ? params.loanInfo.loan_type.type_id : 0}
                            options={[
                                {text: "Select a loan type", value: 0},
                                ...params.loanProps.loanTypes
                                    .filter((loanType) => loanType.loan_status === "active")
                                    .map((loanType) => ({text: loanType.type_name, value: loanType.type_id}))
                            ]}/>

                        <MyInput placeholder="Enter amount of money to be borrowed" title="Loan principal" name="loan_principal"
                                 change={handle_change} disabled={params.editDisabled}
                                 value={set_data(params.loanInfo.loan_principal).toString()}/>

                        <div style={{marginBottom: '15px'}}>
                            <MyDateInput
                                title="Date of first installment payment" value={params.loanInfo.start_date} name="start_date"
                                placeholder="Select payment start date" disabled={params.editDisabled}
                                change={handle_change}/>
                        </div>

                        <MySelect
                            change={(value: select_type) => handle_change('rate_type', (value as string))} className="mb-0"
                            title="Interest Rate Type" name="rate_type" value={params.loanInfo.rate_type} disabled={params.editDisabled}
                            options={
                                [
                                    {text: "Select interest type", value: ""},
                                    {text: "Flat Interest", value: "simple"},
                                    {text: "Flat Interest (Only Interest Paid)", value: "simple_interest"},
                                    {text: "Reducing Balance", value: "compound"}
                                ]
                            }/>
                    </div>
                </div>

                {/*Loan principal, rate and interest*/}
                <div className="col-12 col-md-3 loan_form">
                    <div>
                        <MyInput placeholder="Enter loan rate" title="Interest Rate (In percentage)" name="loan_rate"
                                 disabled={params.editDisabled} change={handle_change} value={params.loanInfo.loan_rate}/>

                        <MyInput placeholder="Enter loan period" title="Loan Period (In months)" name="loan_period"
                                 disabled={params.editDisabled} change={handle_change} value={params.loanInfo.loan_period}/>

                        <MySelect
                            change={(value: select_type) => handle_change('payment_interval', parseInt(value as string))}
                            disabled={params.editDisabled} title="Loan payment Interval" name="payment_interval"
                            value={params.loanInfo.payment_interval}
                            options={
                                [
                                    {text: "Daily Payment", value: 1}, {text: "One week (7 days)", value: 7},
                                    {text: "Two weeks (14 days)", value: 14}, {text: "One Month", value: 30}
                                ]
                            }/>

                        <MyInput placeholder="Interest Projection" title="Projected Interest" disabled={true} name="projected_interest"
                                 change={handle_change} value={set_data(params.loanInfo.projected_interest).toString()}/>

                        <div style={{marginBottom: '0px'}}>
                            <MyDateInput
                                title="Loan expiry date" value={params.loanInfo.due_date} name="due_date"
                                placeholder="Calculated payment due date" disabled={true} change={handle_change}/>
                        </div>
                    </div>
                </div>

                {/*Loan charges data*/}
                <div className="col-12 col-md-6 loan_form">
                    <div className="table_container full_height">
                        {
                            params.editDisabled ?
                                <Table celled striped compact size='small' inverted color='grey' selectable>
                                    <Table.Header fullWidth>
                                        <Table.Row>
                                            <Table.HeaderCell style={{width: '40px'}}>No.</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '120px'}}>Charge Name</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '150px'}}>Received By</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '120px'}}>Initial Value</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '120px'}}>Amount Charged</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            params.loanInfo.charges
                                                .map((charge, index) =>
                                                    <Table.Row key={charge.charge_id}>
                                                        <Table.Cell style={{width: '40px'}}>{Utils.row_number(index)}</Table.Cell>
                                                        <Table.Cell style={{width: '120px'}}>{charge.fee_name}</Table.Cell>
                                                        <Table.Cell style={{width: '150px'}}>{charge.cashier_name}</Table.Cell>
                                                        <Table.Cell style={{width: '120px'}}>
                                                            {
                                                                charge.charge_type === "Flat" ?
                                                                    Utils.comma_number(charge.charge_value) :
                                                                    Utils.comma_number((charge.charge_value *
                                                                        parseFloat(Utils.strip_commas(params.loanInfo.loan_principal))
                                                                    ) / 100)
                                                            }
                                                        </Table.Cell>
                                                        <Table.Cell style={{width: '120px'}}>
                                                            {Utils.comma_number(charge.charge_amount)}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                        }
                                    </Table.Body>
                                </Table>
                                :
                                <Table celled striped compact size='small' inverted color='grey' selectable>
                                    <Table.Header fullWidth>
                                        <Table.Row>
                                            <Table.HeaderCell style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '160px'}}>Charge Name</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '120px'}}>Charge Value</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '120px'}}>Amount Charged</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            params.loanProps.charges
                                                .filter((charge) => charge.type_id === params.loanInfo.loan_type?.type_id)
                                                .map((charge, index) =>
                                                    <Table.Row key={charge.charge_id}>
                                                        <Table.Cell style={{width: '40px'}} textAlign="center">
                                                            {Utils.row_number(index)}
                                                        </Table.Cell>
                                                        <Table.Cell style={{width: '160px'}}>{charge.fee_name}</Table.Cell>
                                                        <Table.Cell style={{width: '120px'}}>{get_charge_value(charge.charge_id)}</Table.Cell>
                                                        <Table.Cell style={{width: '120px'}}>
                                                            <MyInput
                                                                placeholder="Enter charge" name="" className="mb-0"
                                                                value={Utils.comma_input(get_charge(charge.charge_id)).toString()}
                                                                change={(text, value) => {
                                                                    params.setCharges(
                                                                        params.charges.map((_charge) => {
                                                                            if (_charge.charge_id === charge.charge_id) {
                                                                                return {..._charge, charge_amount: Utils.strip_commas(value)}
                                                                            } else {
                                                                                return {..._charge}
                                                                            }
                                                                        })
                                                                    )
                                                                }}/>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                        }
                                    </Table.Body>
                                </Table>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
