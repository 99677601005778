import React from "react";
import Utils from "../../utils/Utils";
import {Loading, loading_params, LoadingParams, MyDateInput, MyInput, MySelect, select_type} from "../../utils/Components";
import {Button, Form, Table, TextArea} from "semantic-ui-react";
import {Req} from "../../utils/Req";
import {MultiSelect, multiselect_type} from "../../utils/MultiSelect";
import {BranchUser} from "../../nav/SideBar";

export interface Asset {
    office_id: number
    asset_name: string
    asset_description: string
    buying_price: number | string
    appreciation_rate: number | string
    depreciation_rate: number | string
    asset_no: string
    asset_id: number
    date_acquired: string
    payment_method: "cash" | "bank" | ""
}

export default function AssetsIncomes(params: { branchUser: BranchUser }) {
    const [loading, setLoading] = React.useState<LoadingParams>(loading_params)
    const initial_asset: Asset = {
        date_acquired: Utils.today(), payment_method: "", appreciation_rate: "0", asset_description: "", asset_id: 0,
        asset_name: "", asset_no: "", buying_price: 0, depreciation_rate: "0", office_id: 0
    }

    const [search, setSearch] = React.useState({branches: []})
    const handle_search = (name: string, value: string | number | multiselect_type) => {
        setSearch({...search, [name]: value})
    }

    const [asset, setAsset] = React.useState(initial_asset)
    const handle_change = (name: string, value: string | number) => {
        setAsset({...asset, [name]: value})
    }

    const [assets, setAssets] = React.useState<Array<Asset>>([])
    const load_assets = () => {
        setLoading({show: true, text: "Loading assets, please wait"})
        Req.get_assets()
            .then((response) => {
                setLoading({show: false, text: ""})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setAssets(response.data.assets)
                }
            })
            .catch(() => {
                setLoading({show: false, text: ""})
            })
    }

    const save_asset = () => {
        setLoading({show: true, text: "Saving asset data, please wait"})
        Req.save_asset(asset)
            .then((response) => {
                setLoading({show: false, text: ""})
            })
            .catch(() => {
                setLoading({show: false, text: ""})
            })
    }

    return (
        <>
            <Loading show={loading.show} text={loading.text} reload={loading.reload} error={loading.error}/>

            <div className="row m-0 h-100">
                <div className="col-12 col-md-8 p-1 h-100">
                    <div className="content_bar">
                        <div className="search_bar">
                            <div>
                                <MultiSelect
                                    multiple={true} placeholder="Select branches" selected={search.branches}
                                    change={(value) => handle_search('branches', value)}
                                    items={
                                        params.branchUser.branches.map((branch) => {
                                            return ({text: branch.office_name, value: branch.office_id});
                                        })}/>
                            </div>
                        </div>
                        <div className="button_bar">
                            <Button size='mini' content="Search" primary labelPosition="left" icon="search" onClick={load_assets}/>
                        </div>
                    </div>

                    <div className="table_container container_no_footer">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={1}>No.</Table.HeaderCell>
                                    <Table.HeaderCell>Asset Name</Table.HeaderCell>
                                    <Table.HeaderCell>Asset Number</Table.HeaderCell>
                                    <Table.HeaderCell>Buying Price</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}} textAlign="center">App. Rate</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}} textAlign="center">Dep. Rate</Table.HeaderCell>
                                    <Table.HeaderCell>Date Acquired</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    assets.map((asset, index) =>
                                        <Table.Row key={asset.asset_id} onClick={() => setAsset(asset)}>
                                            <Table.Cell width={1}>{Utils.row_number(index)}</Table.Cell>
                                            <Table.Cell>{asset.asset_name}</Table.Cell>
                                            <Table.Cell>{asset.asset_no}</Table.Cell>
                                            <Table.Cell>{Utils.comma_number(asset.buying_price as number)}</Table.Cell>
                                            <Table.Cell style={{width: '100px'}} textAlign="center">
                                                {asset.appreciation_rate}%
                                            </Table.Cell>
                                            <Table.Cell style={{width: '100px'}} textAlign="center">
                                                {asset.depreciation_rate}%
                                            </Table.Cell>
                                            <Table.Cell>
                                                {Utils.localise_date(asset.date_acquired)}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                <div className="col-12 col-md-4 p-1 h-100">
                    <div className="form">
                        <div className="form_header">Asset Management</div>

                        <div className="form_content">
                            <MySelect
                                change={(value: select_type) => handle_change('office_id', (value as number))}
                                title="Office of attachment" name="office_id" value={asset.office_id}
                                options={[
                                    {value: 0, text: "Select office of attachment"},
                                    ...params.branchUser.branches.map((branch) => {
                                        return ({text: branch.office_name, value: branch.office_id});
                                    })
                                ]}/>

                            <MyInput placeholder="Enter name of asset" title="Asset Name" name="asset_name"
                                     value={asset.asset_name} change={handle_change}/>

                            <MyInput placeholder="Enter asset no. (e.g land title)" title="Asset Number"
                                     name="asset_no" change={handle_change} value={asset.asset_no}/>

                            <Form style={{marginBottom: '15px'}}>
                                <TextArea
                                    placeholder='Describe the asset' rows={4} value={asset.asset_description}
                                    onChange={((event, data) => {
                                        handle_change('asset_description', data.value as string)
                                    })}/>
                            </Form>

                            <MyInput placeholder="Enter buying price" title="Buying Price" name="buying_price"
                                     value={asset.buying_price as string} change={handle_change}/>

                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <MyInput placeholder="Enter rate of appreciation" title="Appreciation Rate"
                                             name="appreciation_rate" value={asset.appreciation_rate as string} change={handle_change}/>
                                </div>
                                <div className="col-6 pl-1 pr-0">
                                    <MyInput placeholder="Enter rate of depreciation" title="Depreciation Rate"
                                             name="depreciation_rate" value={asset.depreciation_rate as string}
                                             change={handle_change}/>
                                </div>
                            </div>

                            <MySelect
                                change={(value: select_type) => handle_change('payment_method', (value as string))}
                                title="Payment Method" name="payment_method" value={asset.payment_method}
                                options={
                                    [
                                        {text: "Select payment method", value: ""},
                                        {text: "Cash or Mobile Money", value: "cash"},
                                        {text: "Cheque or Money Transfer", value: "bank"}
                                    ]
                                }/>

                            <div className="mb-2">
                                <MyDateInput
                                    title="Date Asset Acquired" value={asset.date_acquired} name="date_acquired"
                                    change={handle_change} maxDate={Utils.today()} placeholder="Select date acquired"/>
                            </div>
                        </div>

                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <Button negative fluid size='tiny' content="Clear Data" icon="close"
                                            labelPosition="left" onClick={() => setAsset(initial_asset)}/>
                                </div>
                                <div className="col-6 pl-1 pr-0">
                                    <Button positive onClick={save_asset} fluid size='tiny' content="Save Asset" icon="save" labelPosition="left"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
