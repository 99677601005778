import React from "react";
import Utils from "../../../../utils/Utils";
import {Button, Modal} from "semantic-ui-react";
import {Req} from "../../../../utils/Req";
import {ToastsStore} from "react-toasts";
import {MyDateInput, MyInput} from "../../../../utils/Components";
import {LoanDetail} from "./LoansDetails";

interface Extension {
    extension_period: number | string
    payment_start_date: string
    extension_rate: string
}

const initial_extension: Extension = {extension_period: '', payment_start_date: "", extension_rate: "0"}

export default function LoanReschedule(props: { show: boolean, close: (loan?: LoanDetail) => void, creditorLoan: LoanDetail }) {
    const [extending, setExtending] = React.useState(false)
    const [extension, setExtension] = React.useState(initial_extension)

    const handle_change = (name: string, value: string) => {
        setExtension({...extension, [name]: value})
    }

    const extend_loan = () => {
        if (!Utils.is_valid_number(extension.extension_period as string) || parseInt(extension.extension_period as string) < 1) {
            ToastsStore.error("Enter a valid extension period")
        } else if (!Utils.is_valid_number(extension.extension_rate) || parseFloat(extension.extension_rate) < 0) {
            ToastsStore.error("Enter a valid extension rate")
        } else if (extension.payment_start_date === "") {
            ToastsStore.error("Enter a valid date when the extension begins")
        } else {
            setExtending(true)
            Req.extend_creditor_loan({
                extension_period: parseInt(extension.extension_period as string),
                loan_id: props.creditorLoan.loan.loan_id,
                payment_start_date: extension.payment_start_date,
                extension_rate: parseFloat(extension.extension_rate)
            })
                .then((response) => {
                    setExtending(false)
                    if (response.data.hasOwnProperty("code")) {
                        props.close(response.data.loan)
                    } else {
                        ToastsStore.error("Could not extend loan, please retry")
                    }
                })
                .catch(() => {
                    setExtending(false)
                    ToastsStore.error("Could not extend loan, please retry")
                })
        }
    }

    React.useEffect(() => {
        if (props.show) {
            setExtension({...initial_extension, extension_rate: props.creditorLoan.loan.credit_rate})
        }
    }, [props.show])

    return (
        <>

            <Modal open={props.show} basic size='mini' centered={false} onClose={() => props.close()}>
                <div className="modal_div">
                    <div className="form">
                        <div className="form_content no_header">
                            <MyInput
                                placeholder="Principal Balance" title="Principal Balance"
                                name="principal_balance" value={Utils.comma_number(props.creditorLoan.loan.principal_balance)}/>

                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <MyInput
                                        placeholder="Enter period in months" title="Months Extended" change={handle_change}
                                        name="extension_period" value={extension.extension_period as string}/>
                                </div>
                                <div className="col-6 pl-1 pr-0">
                                    <MyInput placeholder="Extension Rate" title="Extension Rate" change={handle_change}
                                             name="extension_rate" value={extension.extension_rate}/>
                                </div>
                            </div>
                            <div style={{marginBottom: '15px'}}>
                                <MyDateInput
                                    title="Start date of payment" value={extension.payment_start_date} name="payment_start_date"
                                    placeholder="Payment start date" minDate={props.creditorLoan.loan.due_date} change={handle_change}/>
                            </div>
                        </div>

                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <Button negative onClick={() => props.close()} fluid icon="close" size="tiny"
                                            content="Close Window" labelPosition="left"/>
                                </div>
                                <div className="col-6 pl-0 pr-1">
                                    <Button positive onClick={extend_loan} loading={extending} content="Extend loan"
                                            icon="save" fluid labelPosition="left" size="tiny"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
