import {User} from "../Users";
import {Button, Form, Modal} from "semantic-ui-react";
import React from "react";
import {Req} from "../../../../utils/Req";
import {ToastsStore} from "react-toasts";

export type report_type = "loan_report" | "loan_balances" | "loan_portfolio" | "payment_report" | "incomes_report" | "income_statement" |
    "aging_report" | "till_allocation" | "expenses_report" | "trial_balance" | "daily_report" | "balance_sheet" | "" |
    "bank_statement" | "sales_report" | "restructured_loans" | "portfolio_risk"

interface Report {
    report_type: report_type
    report_name: string
}

export const accessRoles: Array<Report> = [
    {report_type: "balance_sheet", report_name: "Balance Sheet"},
    {report_type: "bank_statement", report_name: "Bank Statement"},
    {report_type: "daily_report", report_name: "Daily Reports"},
    {report_type: "loan_report", report_name: "Loan Processing"},
    {report_type: "loan_balances", report_name: "Loan Balances"},
    {report_type: "loan_portfolio", report_name: "Loan Portfolio"},
    {report_type: "payment_report", report_name: "Loan Payments"},
    {report_type: "incomes_report", report_name: "Incomes Report"},
    {report_type: "income_statement", report_name: "Incomes Statement"},
    {report_type: "aging_report", report_name: "Loan Aging"},
    {report_type: "till_allocation", report_name: "Till Allocation"},
    {report_type: "expenses_report", report_name: "Expenses Report"},
    {report_type: "trial_balance", report_name: "Trial Balance"},
    {report_type: "sales_report", report_name: "Sales Report"},
    {report_type: "restructured_loans", report_name: "Restructured Loans"},
    {report_type: "portfolio_risk", report_name: "Portfolio at Risk"},
]

export default function ReportRights(params: { show: boolean, close: (rights?: Array<string>) => void, user: User }) {
    const [saving, setSaving] = React.useState(false)
    const [reportRights, setReportRights] = React.useState(Array<string>())

    const save_report_rights = () => {
        setSaving(true)
        Req.update_report_rights({report_rights: JSON.stringify(reportRights), user_id: params.user.user_id})
            .then((response) => {
                setSaving(false)
                if (response.data.hasOwnProperty("code") && response.data['code'] === 1) {
                    ToastsStore.success("User rights successfully updated")
                    params.close(reportRights)
                } else {
                    ToastsStore.error("Could not update report rights, please retry")
                }
            })
            .catch(() => {
                setSaving(false)
                ToastsStore.error("Could not update report rights, please retry")
            })
    }

    React.useEffect(() => {
        setReportRights(params.user.report_rights)
    }, [params.user])

    return (
        <>
            <Modal open={params.show} basic size='mini' centered={false} onClose={() => params.close()}>
                <div className="modal_div modal_form">
                    <div className="form_header">
                        {params.user.last_name + " " + params.user.first_name}
                    </div>

                    <div className="form_content rights_form">
                        {
                            accessRoles
                                // .sort((a, b) => a.report_name.localeCompare(b.report_type))
                                .map((accessRole) =>
                                    <Form.Checkbox
                                        className="mb-2" checked={reportRights.includes(accessRole.report_type)}
                                        key={accessRole.report_type} label={accessRole.report_name} value={accessRole.report_type}
                                        onChange={((event, data) => {
                                            if (data.checked as Boolean) {
                                                setReportRights([...reportRights, accessRole.report_type])
                                            } else {
                                                setReportRights(reportRights
                                                    .filter((reportRight) => reportRight !== accessRole.report_type))
                                            }
                                        })}/>
                                )
                        }
                    </div>

                    <div className="form_footer">
                        <div className="row m-0">
                            <div className="col-6 p-0 pr-1">
                                <Button
                                    negative onClick={() => params.close()} content="Cancel Update" size='mini' fluid
                                    icon="close" labelPosition={"left"}/>
                            </div>
                            <div className="col-6 p-0 pl-1">
                                <Button
                                    positive onClick={save_report_rights} content="Update Report Rights" size='mini' fluid loading={saving}
                                    icon="save" labelPosition={"left"} disabled={saving || params.user.user_id === 0}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
