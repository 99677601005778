import React from "react";
import {Table} from "semantic-ui-react";
import Utils from "../../../utils/Utils";

interface Report {
    account_name: string
    account_no: string | number
    amount: number
    bank_name: string
    date: string
    id: number
    office_name: string
    receipt: string
    type: "Deposit" | "Withdrawal"
    username: string
}

export default function BankStatementReport(params: { transactions: any }) {
    const [statements, setStatements] = React.useState<Array<Report>>([])

    React.useEffect(() => {
        if (params.transactions.hasOwnProperty("rp")) {
            setStatements(params.transactions.rp)
        }
    }, [params.transactions])

    return (
        <>
            <Table celled striped compact size='small' inverted color='grey' selectable className='has_total'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                        <Table.HeaderCell className='time_row'>Date</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '180px'}}>Cashier Name</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '180px'}}>Branch Name</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '150px'}}>Bank Name</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '150px'}}>Account Name</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '150px'}}>Account Number</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '100px'}}>Transaction</Table.HeaderCell>
                        <Table.HeaderCell className='amount_row'>Amount</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        statements
                            .map((transaction, index) => <>
                                    <Table.Row key={transaction.id}>
                                        <Table.Cell style={{width: '50px'}} textAlign="center">
                                            {(index < statements.length - 1) && (index + 1)}
                                        </Table.Cell>
                                        <Table.Cell className='time_row'>{Utils.localise_date(transaction.date)}</Table.Cell>
                                        <Table.Cell style={{width: '180px'}}>{transaction.username}</Table.Cell>
                                        <Table.Cell style={{width: '180px'}}>{transaction.office_name}</Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>{transaction.bank_name}</Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>{transaction.account_name}</Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>{transaction.account_no}</Table.Cell>
                                        <Table.Cell style={{width: '100px'}}>{transaction.type}</Table.Cell>
                                        <Table.Cell className='amount_row'>{Utils.comma_number(transaction.amount)}</Table.Cell>
                                    </Table.Row>
                                </>
                            )
                    }
                </Table.Body>
            </Table>
        </>
    )
}
