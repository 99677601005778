import React from "react"
import Utils, {ConfirmAction, initial_confirm,} from "../../utils/Utils"
import {Loading, MyDateInput, MyInput, MyLabel, PDFReader} from "../../utils/Components"
import {Req} from "../../utils/Req"
import {Button, Confirm, Modal} from "semantic-ui-react"
import PhoneInput from "react-phone-input-2"
import {ToastsStore} from "react-toasts";

interface Payment {
    payment_amount: string
    payer_name: string
    payer_contact: string
    loan_id: number
    client_name: string
    date_paid: string
}

const initial_payment: Payment = {
    client_name: "", loan_id: 0, payer_contact: "", payer_name: "", payment_amount: "", date_paid: Utils.today()
}

export default function MakePayment(props: { loan_info: any, show: boolean, close: () => void }) {
    const [loading, setLoading] = React.useState({message: "", show: false})
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)
    const [showReader, setShowReader] = React.useState({display: false, source: ""})

    const [payment, setPayment] = React.useState<Payment>(initial_payment)
    const handle_payment_change = (name: string, value: string | number) => {
        setPayment({...payment, [name]: value})
    }

    const make_payment = () => {
        if (!Utils.is_valid_number(payment.payment_amount)) {
            ToastsStore.error("Enter valid payment amount")
        } else if (parseFloat(payment.payment_amount) < 0) {
            ToastsStore.error("Minimum payment cannot be less than 0")
        } else if (Utils.invalid_full_name(payment.payer_name)) {
            ToastsStore.error("Enter a valid payer name")
        } else if (Utils.invalid_contact(payment.payer_contact)) {
            ToastsStore.error("Enter a valid payer contact")
        } else {
            setConfirm({
                ...confirm, open: true,
                content: `Are you sure you want to proceed with making a payment of 
                        "${Utils.comma_number(parseFloat(payment.payment_amount))}" to loan "${payment.loan_id}"?`,
                onConfirm: () => {
                    setConfirm({...confirm, 'open': false})

                    setLoading({message: "Saving payment, please wait", show: true})
                    Req.make_payment({
                        loan_id: payment.loan_id, payer_contact: payment.payer_contact, payer_name: payment.payer_name,
                        total_paid: parseFloat(payment.payment_amount), date_paid: payment.date_paid
                    })
                        .then((response) => {
                            setLoading({message: "", show: false})
                            if (response.data.hasOwnProperty("code")) {
                                if (response.data.code === 1) {
                                    props.close()
                                    ToastsStore.success("Successfully made payment")
                                    setShowReader({display: true, source: Req.BASE_URL + response.data.file})
                                } else {
                                    ToastsStore.error("Could not make payment, please retry")
                                }
                            } else {
                                ToastsStore.error("Could not make payment, please retry")
                            }
                        })
                        .catch(() => {
                            ToastsStore.error("Could not make payment, please retry")
                            setLoading({message: "", show: false})
                        })

                },
                onCancel: () => setConfirm({...confirm, 'open': false})
            })
        }
    }

    React.useEffect(() => {
        if (props.loan_info.hasOwnProperty("next_dates")) {
            const contact = (props.loan_info.main_contact as number).toString()
            setPayment({
                client_name: props.loan_info.client_name,
                loan_id: props.loan_info.loan_id,
                payer_contact: Utils.invalid_contact(contact) ? "" : contact,
                payer_name: props.loan_info.client_name,
                payment_amount: "", date_paid: props.loan_info.date
            })
        } else {
            setPayment(initial_payment)
        }
    }, [props.loan_info])

    return (
        <>
            <PDFReader source={showReader.source} open={showReader.display}
                       close={() => setShowReader({...showReader, display: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} centered={false} size='mini'
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} content={confirm.content}/>

            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <Modal open={props.show} basic size='mini' centered={false}>
                <div className="modal_div modal_form payment_modal">
                    <div className="form_header">
                        {payment.client_name} (Loan No: {payment.loan_id})
                    </div>

                    <div className="form_content">
                        <div className="">
                            <MyInput
                                title={`Total Amount Paid.`} placeholder="Total Amount Paid" name="payment_amount"
                                value={payment.payment_amount} className={`payment_amount`} change={handle_payment_change}/>
                        </div>

                        <MyInput placeholder="Name of person making payment" title="Paid By" name="payer_name" value={payment.payer_name}
                                 change={handle_payment_change}/>

                        <MyLabel title="Contact of person making payment"/>
                        <PhoneInput
                            countryCodeEditable={false} containerStyle={{marginBottom: '0px'}}
                            country={'ug'} value={payment.payer_contact} onlyCountries={['ug']}
                            onChange={((value) => handle_payment_change('payer_contact', value))}/>

                        <div className="mt-2">
                            <MyDateInput title="Payment Date" value={payment.date_paid} name="date_paid"
                                         placeholder="Payment date" change={handle_payment_change}/>
                        </div>
                    </div>

                    <div className="form_footer">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button negative onClick={() => props.close()} content="Close Window" size='mini' fluid
                                        icon='close' labelPosition={"left"}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button positive onClick={make_payment} content="Save Payment" size='mini' fluid
                                         icon="save" labelPosition={"left"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
