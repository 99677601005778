import Header from "./nav/Header";
import SideBar from "./nav/SideBar";
import {useAppDispatch, useAppSelector} from "./store/hooks";
import {getCounter, getStaff, lockUser, updateCounter} from "./store/slices/userSlice";
import React from "react";
import Login from "./modules/admin/users/auth/Login";
import axios from "axios";
import {Req} from "./utils/Req";
import {ToastsStore} from "react-toasts";
import Utils from "./utils/Utils";
import {getSummary, updateSummary} from "./store/slices/systemSlice";

export default function System() {
    const dispatch = useAppDispatch()
    const user = useAppSelector(getStaff)
    const counter = useAppSelector(getCounter)
    const summary = useAppSelector(getSummary)

    const set_axios_data = () => {
        axios.defaults.baseURL = Req.BASE_URL;

        axios.defaults.headers.common.Authorization = `Bearer ${user.user_token}`
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['Client'] = `${Req.CLIENT}`
        axios.defaults.headers.common['Version'] = '1.0';
        axios.defaults.timeout = 1000 * 60

        axios.interceptors.response.use(
            function (response) {
                console.log(response.data)
                console.log(response)
                if (response.data.hasOwnProperty("code") && response.data['code'] === 426) {
                    ToastsStore.error("Client version is too low, please contact the admin for help")
                }
                if (response.data.hasOwnProperty("code") && response.data['code'] === 401) {
                    dispatch(lockUser())
                }
                return response
            },
            function (error) {
                console.log(error)
                return Promise.reject(error);
            });
    }
    set_axios_data()

    const load_today_summary = () => {
        Req.daily_summary({})
            .then((response) => {
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    dispatch(updateSummary({
                        date: response.data.date,
                        expected: response.data.data.pending,
                        due_loans: response.data.data.due_loans,
                        received: response.data.data.payments
                    }))
                }
            })
    }

    React.useEffect(() => {
        if (Utils.today() !== summary.date) {
            load_today_summary()
        }
    }, [])

    window.onclick = () => {
        dispatch(updateCounter(0))
    }

    React.useEffect(() => {
        axios.defaults.headers['common']['Authorization'] = `Bearer ${user.user_token}`
    }, [user.user_token])

    return (
        <>
            <Header/>
            <SideBar/>
            {(counter >= 180 || user.user_token === "") && <Login/>}
        </>
    )
}
