import Utils from "../../../utils/Utils";
import React from "react";
import {Button, Modal} from "semantic-ui-react";
import {MyDateInput, MyInput, MyLabel, SelectData} from "../../../utils/Components";
import {MultiSelect} from "../../../utils/MultiSelect";
import {ToastsStore} from "react-toasts";

export interface CriteriaLoan {
    min_date: string
    max_date: string
    branches: []
    loanTypes: number[]
    min_principal: string
    max_principal: string
    loan_status: string[]
}

export default function LoanCriteria(
    params: {
        branches: SelectData[], show: boolean, close: () => void, criteria: CriteriaLoan,
        handle_change: (criteria: CriteriaLoan) => void, generate: () => void, loanTypes: SelectData[],
    }
) {
    const handle_change = (name: string, value: string | number | Array<number | string>) => {
        params.handle_change({...params.criteria, [name]: value})
    }

    return (
        <Modal open={params.show} basic size='mini' centered={false} onClose={params.close}>
            <div className='modal_div modal_form'>
                <div className="form_content">
                    <div className="p-1">
                        <MyDateInput
                            title="Minimum date of creation" value={params.criteria.min_date} name="min_date"
                            maxDate={params.criteria.max_date} placeholder="Select minimum date" change={handle_change}/>
                    </div>
                    <div className="p-1">
                        <MyDateInput
                            title="Maximum date of creation" value={params.criteria.max_date} name="max_date"
                            maxDate={Utils.today()} minDate={params.criteria.min_date}
                            placeholder="Select maximum date" change={handle_change}/>
                    </div>
                    <div className="p-1">
                        <MyLabel title="Select branches"/>
                        <MultiSelect
                            multiple={true} placeholder="Select branches" items={params.branches} selected={params.criteria.branches}
                            change={(value) => handle_change('branches', value)}/>
                    </div>
                    <div className="p-1">
                        <MyLabel title="Select loan types"/>
                        <MultiSelect
                            multiple={true} placeholder="Select loan types" items={params.loanTypes} selected={params.criteria.loanTypes}
                            change={(value) => handle_change('loanTypes', value)}/>
                    </div>
                    <div className="p-1">
                        <MyLabel title="Select loan statuses"/>
                        <MultiSelect
                            multiple={true} placeholder="Select loan statuses" selected={params.criteria.loan_status}
                            change={(value) => handle_change('loan_status', value)}
                            items={[
                                {text: "Pending Loans", value: "pending"},
                                {text: "Rejected Loans", value: "rejected"},
                                {text: "Accepted Loans (Branch Manager)", value: "accepted"},
                                {text: "Verified Loans (Pending Disbursement)", value: "verified"},
                                {text: "Active Loans", value: "active"},
                                {text: "Aged Loans (Portfolio at Risk)", value: "over_due"},
                                {text: "Unrecoverable Loans", value: "unrecoverable"},
                                {text: "Cleared Loans", value: "cleared"},
                                {text: "Deleted Loans", value: "deleted"},
                            ]}/>
                    </div>
                    <div className="p-1">
                        <MyInput placeholder="Minimum loan principal" title="Minimum loan principal" change={handle_change}
                                 name="min_principal" value={params.criteria.min_principal} className="mb-0"/>
                    </div>
                    <div className="p-1">
                        <MyInput placeholder="Maximum loan principal" title="Maximum loan principal" change={handle_change}
                                 name="max_principal" value={params.criteria.max_principal} className="mb-0"/>
                    </div>
                </div>

                <div className="form_footer">
                    <div className="row m-0">
                        <div className="col-6 pl-0 pr-1">
                            <Button negative onClick={params.close} content="Close Window" size='mini' fluid icon='close' labelPosition="left"/>
                        </div>
                        <div className="col-6 pl-1 pr-0">
                            <Button positive content="Load Report" size='mini' fluid icon="table" labelPosition="left"
                                    onClick={() => {
                                        if (params.criteria.branches.length === 0) {
                                            ToastsStore.error("Select at least 1 branch")
                                        } else if (params.criteria.loanTypes.length === 0) {
                                            ToastsStore.error("Select at least 1 loan type")
                                        } else if (params.criteria.loan_status.length === 0) {
                                            ToastsStore.error("Select at least 1 loan status")
                                        } else {
                                            params.generate()
                                        }
                                    }}/>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
