import {LoanInfo} from "../../ShowLoan";
import React from "react";
import Utils, {ConfirmAction, initial_confirm} from "../../../../../utils/Utils";
import {ToastsStore} from "react-toasts";
import {Req} from "../../../../../utils/Req";
import {Loading, MyInput, MyLabel} from "../../../../../utils/Components";
import {Button, Confirm, Form, Table, TextArea} from "semantic-ui-react";

export interface DeductInterest {
    removal_id: number
    amount_removed: string | number
    reason_removed: string
    date_removed: string
    removed_by: string
}

const initial_deduct: DeductInterest = {
    removal_id: 0, removed_by: "", amount_removed: "", date_removed: Utils.today(), reason_removed: ""
}

export default function RemoveInterest(params: { loanInfo: LoanInfo, setLoanInfo: (loanInfo: LoanInfo) => void }) {
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)
    const [loader, setLoader] = React.useState({message: "", show: false})

    const [deduct, setDeduct] = React.useState(initial_deduct)
    const handle_deduct = (name: string, value: string) => {
        setDeduct({...deduct, [name]: value})
    }

    const [balance, setBalance] = React.useState({
        principal: params.loanInfo.payment_summary.principal_balance,
        interest: params.loanInfo.payment_summary.interest_balance
    })

    const calculate_max = () => {
        if (params.loanInfo.payment_summary.principal_balance === 0) {
            return 0
        }

        if (params.loanInfo.payment_summary.principal_balance <= params.loanInfo.interest.paid) {
            return params.loanInfo.payment_summary.principal_balance
        }

        return params.loanInfo.interest.previous
    }

    const deduct_interest = () => {
        if (!Utils.is_valid_number(deduct.amount_removed as string)) {
            ToastsStore.error("Enter valid amount deducted")
        } else if (parseFloat((deduct.amount_removed as string)) > calculate_max()) {
            ToastsStore.error(`Maximum of ${Utils.comma_number(calculate_max())} can be deducted`)
        } else {
            setConfirm({
                ...confirm,
                'open': true,
                'content': `Are you sure you want to proceed with deducting ${Utils.comma_number(parseFloat(deduct.amount_removed as string))} interest from this loan?`,
                'onConfirm': () => {
                    setConfirm({...confirm, 'open': false})
                    setLoader({message: "Deducting interest, please wait", show: true})

                    Req.deduct_interest({
                        amount_removed: deduct.amount_removed as string,
                        reason_removed: deduct.reason_removed,
                        loan_id: params.loanInfo.loan_id
                    })
                        .then((response) => {
                            setLoader({message: "", show: false})
                            if (response.data.hasOwnProperty("code")) {
                                if (response.data.code === 1) {
                                    ToastsStore.success("Successfully removed loan interest")
                                    setDeduct(initial_deduct)
                                    params.setLoanInfo(Utils.format_loan_data(response))
                                } else if (response.data.code === 2) {
                                    ToastsStore.error("Loan not found");
                                } else if (response.data.code === 3) {
                                    ToastsStore.error("Loan was already cleared");
                                } else if (response.data.code === 4) {
                                    ToastsStore.error(`Maximum of ${Utils.comma_number(response.data.maximum)} can be deducted from loan`)
                                } else if (response.data.code === 0) {
                                    ToastsStore.error("Error while deducting interest, please retry");
                                }
                            } else {
                                ToastsStore.error("Cannot deduct interest from loan, please retry")
                            }
                        })
                        .catch(() => {
                            setLoader({message: "", show: false})
                            ToastsStore.error("Cannot deduct interest from loan, please retry")
                        })
                },
                'onCancel': () => setConfirm({...confirm, 'open': false})
            })
        }
    }

    React.useEffect(() => {
        if (Utils.is_valid_number((deduct.amount_removed as string).trim())) {
            const amount = parseFloat((deduct.amount_removed as string).trim())
            const interest = params.loanInfo.payment_summary.interest_balance - amount
            setBalance({principal: params.loanInfo.payment_summary.principal_balance - amount, interest: interest > 0 ? interest : 0})
        } else {
            setBalance({principal: params.loanInfo.payment_summary.principal_balance, interest: params.loanInfo.payment_summary.interest_balance})
        }
    }, [deduct.amount_removed])

    React.useEffect(() => {
        setDeduct({...deduct, amount_removed: calculate_max().toString()})
    }, [params.loanInfo.payment_summary])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} centered={false} size='mini'
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} content={confirm.content}/>

            <div className="row mx-0 h-100">
                <div className="col-12 p-1 col-md-8 h-100">
                    <div className="table_container full_height">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign={'center'}>No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>Date Removed</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Removed By</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Amount Removed</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '250px'}}>Reason Removed</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    params.loanInfo.others.removals
                                        .map((info, index) =>
                                            <Table.Row key={info.removal_id}>
                                                <Table.Cell style={{width: '50px'}} textAlign={'center'}>{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell style={{width: '140px'}}>{Utils.localise_date(info.date_removed)}</Table.Cell>
                                                <Table.Cell style={{width: '150px'}}>{info.removed_by}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>
                                                    {Utils.comma_number(info.amount_removed as number)}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '250px'}}>{info.reason_removed}</Table.Cell>
                                            </Table.Row>
                                        )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                <div className="col-12 p-1 col-md-4 h-100">
                    <div className="form">
                        <div className='form_content no_header'>
                            <MyInput
                                placeholder="Interest Deducted" name="amount_removed" change={handle_deduct}
                                title={`Interest deducted (Maximum  ${Utils.comma_number(calculate_max())})`}
                                value={deduct.amount_removed as string}/>

                            <div className={'row mx-0'}>
                                <div className={'col-6 pl-0 pr-1'}>
                                    <MyInput
                                        placeholder="Principal Balance" title="Principal Balance" name=""
                                        disabled={true} value={Utils.comma_number(balance.principal)}/>
                                </div>
                                <div className={'col-6 pl-1 pr-0'}>
                                    <MyInput
                                        placeholder="Interest Balance" title="Interest Balance" name=""
                                        disabled={true} value={Utils.comma_number(balance.interest)}/>
                                </div>
                            </div>

                            <div style={{marginTop: '15px'}}>
                                <MyLabel title="Reason for deducting interest"/>
                                <Form>
                                    <TextArea
                                        placeholder='Enter reason as to why you have deducted interest from loan' rows={5}
                                        value={deduct.reason_removed}
                                        onChange={((event, data) => {
                                            handle_deduct('reason_removed', data.value as string)
                                        })}/>
                                </Form>
                            </div>
                        </div>

                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <Button negative onClick={() => setDeduct(initial_deduct)} labelPosition={"left"} icon='close'
                                            content="Clear Data" size='mini' fluid disabled={calculate_max() === 0}/>
                                </div>
                                <div className="col-6 pl-1 pr-0">
                                    <Button positive onClick={deduct_interest} content="Deduct Interest" size='mini' fluid
                                            icon="save" labelPosition={"left"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
