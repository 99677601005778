import {Button, Confirm, Modal, Table} from "semantic-ui-react";
import React from "react";
import Utils, {ConfirmAction, initial_confirm} from "../../../../utils/Utils"
import {Loading, MyDateInput, MyInput, MySelect, select_type, SelectData} from "../../../../utils/Components"
import {Req} from "../../../../utils/Req";
import {CreditorPayment, LoanPayments} from "./LoanPayments";
import LoanReschedule from "./LoanReschedule";
import {Creditor, initial_creditor} from "../Creditors";
import {rate_types} from "../../../admin/loans/LoanTypes";
import {ToastsStore} from "react-toasts";
import {CreditorLoan} from "../SystemCreditors";

interface Schedule {
    payment_date: string
    balance_after: number
    principal_payable: number
    principal_paid: number
    interest_paid: number
}

export interface LoanDetail {
    payments: Array<CreditorPayment>
    schedule: Array<Schedule>
    creditor: Creditor
    loan: CreditorLoan
}

const initial_creditor_loan: LoanDetail = {
    creditor: initial_creditor,
    loan: {
        date_acquired: "", credit_rate: "", due_date: "", loan_id: 0, loan_period: "", principal_balance: 0,
        principal_overdue: 0, start_date: "", creditor_name: "", interest_paid: 0, loan_principal: 0, next_date: "",
        next_total: 0, principal_paid: 0, can_extend: 0, loan_status: "active"
    },
    payments: [],
    schedule: []
}

export function LoansDetails(
    props: {
        close: () => void, update: (loan: CreditorLoan) => void, delete: (loan_id: number) => void,
        show: boolean, creditors: Array<Creditor>, loan_id: number, users: Array<SelectData>, user_id: number
    }
) {
    const [loading, setLoading] = React.useState({message: "", show: false})
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)
    const [loanDetail, setLoanDetail] = React.useState(initial_creditor_loan)
    const [showPayment, setShowPayment] = React.useState({show: false})

    const handle_change = (name: string, value: string | number | rate_types) => {
        setLoanDetail({...loanDetail, schedule: [], loan: {...loanDetail.loan, [name]: value, due_date: ''}})
    }

    const save_creditor_loan = () => {
        if (loanDetail.creditor.creditor_id === 0) {
            ToastsStore.error("Select a creditor")
        } else if (!Utils.is_valid_number(Utils.strip_commas(loanDetail.loan.loan_principal)) ||
            parseFloat(Utils.strip_commas(loanDetail.loan.loan_principal)) < 0) {
            ToastsStore.error("Enter a valid loan amount")
        } else if (!Utils.is_valid_number(loanDetail.loan.credit_rate) || parseFloat(loanDetail.loan.credit_rate) < 0) {
            ToastsStore.error("Enter a valid loan rate")
        } else if (!Utils.is_valid_number(loanDetail.loan.loan_period) || parseFloat(loanDetail.loan.loan_period) < 0) {
            ToastsStore.error("Enter a valid loan period greater than 0")
        } else if ((new Date(loanDetail.loan.start_date)) < (new Date(loanDetail.loan.date_acquired))) {
            ToastsStore.error("Payment start date cannot be less than date loan was acquired")
        } else {
            setLoading({message: "Saving loan, please wait", show: true})
            Req.save_creditor_loan({
                creditor_id: loanDetail.creditor.creditor_id,
                date_acquired: loanDetail.loan.date_acquired,
                due_date: loanDetail.loan.due_date,
                loan_id: loanDetail.loan.loan_id,
                loan_period: parseInt(loanDetail.loan.loan_period),
                loan_principal: parseFloat(Utils.strip_commas(loanDetail.loan.loan_principal)),
                loan_rate: parseFloat(loanDetail.loan.credit_rate),
                start_date: loanDetail.loan.start_date
            })
                .then((response) => {
                    setLoading({message: "", show: false})
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        ToastsStore.success("Successfully saved loan")
                        setLoanDetail(initial_creditor_loan)
                        props.update(response.data.loan)
                    } else {
                        ToastsStore.error("Could not save loan, please retry")
                    }
                })
                .catch(() => {
                    setLoading({message: "", show: false})
                    ToastsStore.error("Could not save loan, please retry")
                })
        }
    }

    const [creditors, setCreditors] = React.useState<Array<SelectData>>([])

    /*loan extension*/
    const [showReschedule, setShowReschedule] = React.useState(false)

    /*deleting loan*/
    const delete_loan = () => {
        setLoading({message: "Deleting loan, please wait", show: true})
        setConfirm({
            ...confirm, open: true, content: 'Are you sure you want to delete this loan?',
            onConfirm: () => {
                setConfirm({...confirm, 'open': false})
                setLoading({message: "", show: false})
                Req.delete_creditor_loan({loan_id: loanDetail.loan.loan_id})
                    .then(response => {
                        if (response.data.hasOwnProperty("code")) {
                            if (response.data.code === 1) {
                                props.delete(loanDetail.loan.loan_id)
                            } else if (response.data.code === 2) {
                                ToastsStore.info("Loan already has payments, it cannot be deleted")
                            }
                        } else {
                            ToastsStore.error("Error while deleting loan, please retry")
                        }
                        setLoading({message: "", show: false})
                    })
                    .catch(() => {
                        setLoading({message: "", show: false})
                        ToastsStore.error("Error while deleting loan, please retry")
                    })
            },
            onCancel: () => setConfirm({...confirm, 'open': false})
        })
    }

    React.useEffect(() => {
        const data: Array<SelectData> = []
        data.push({text: "Select a creditor", value: 0})
        props.creditors.forEach((value) => {
            data.push({text: value.creditor_name, value: value.creditor_id})
        })
        setCreditors(data)
    }, [props.creditors])

    React.useEffect(() => {
        if (props.show) {
            setLoanDetail(initial_creditor_loan)

            if (props.loan_id > 0) {
                setLoading({message: "Getting loan data, please wait", show: true})
                Req.get_creditor_loan({loan_id: props.loan_id})
                    .then((response) => {
                        setLoading({message: "", show: false})
                        if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                            setLoanDetail(response.data.loan)
                        } else {
                            ToastsStore.error("Loan not found")
                            props.close()
                        }
                    })
                    .catch(() => {
                        setLoading({message: "", show: false})
                        props.close()
                    })
            }
        }
    }, [props.show])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <LoanPayments show={showPayment.show} loan={loanDetail} users={props.users} user_id={props.user_id}
                          update={(detail) => {
                              if (detail !== undefined) {
                                  props.update(detail.loan)
                                  setLoanDetail(detail)
                              }
                              setShowPayment({show: false})
                          }}/>

            <LoanReschedule show={showReschedule} creditorLoan={loanDetail}
                            close={(detail) => {
                                if (detail !== undefined) {
                                    props.update(detail.loan)
                                    setLoanDetail(detail)
                                    ToastsStore.success("Successfully extended loan")
                                }
                                setShowReschedule(false)
                            }}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm}
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton}
                     size='mini' content={confirm.content}/>

            <Modal open={props.show} basic size='small' closeOnDocumentClick={true} centered={false} onClose={props.close}>
                <div className="modal_div creditor_loan">
                    <div className="table_container">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header fullWidth>
                                <Table.Row key={1}>
                                    <Table.HeaderCell style={{width: '50px'}} rowSpan={2} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}} rowSpan={2}>Payment Date</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}} colSpan={1} textAlign="center">Expectations</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '300px'}} colSpan={3} textAlign="center">Payments Made</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}} rowSpan={2}>Balance After</Table.HeaderCell>
                                </Table.Row>
                                <Table.Row key={2}>
                                    <Table.HeaderCell style={{width: '120px'}} rowSpan={2}>Principal</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>Principal</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>Interest</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}}>Total</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    loanDetail.schedule.map((schedule, index) =>
                                        <Table.Row key={index}>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.localise_date(schedule.payment_date)}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.comma_number(schedule.principal_payable)}</Table.Cell>

                                            <Table.Cell style={{width: '100px'}}>{Utils.comma_number(schedule.principal_paid)}</Table.Cell>
                                            <Table.Cell style={{width: '100px'}}>{Utils.comma_number(schedule.interest_paid)}</Table.Cell>
                                            <Table.Cell style={{width: '100px'}}>
                                                {Utils.comma_number(schedule.principal_paid + schedule.interest_paid)}
                                            </Table.Cell>

                                            <Table.Cell style={{width: '120px'}}>{Utils.comma_number(schedule.balance_after)}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>

                    <div className="creditor_loan_form">
                        <div className="row m-0">
                            <div className="col-12 col-md-6 p-1">
                                <MySelect
                                    change={(value: select_type) => {
                                        if (value as number === 0) {
                                            setLoanDetail({...loanDetail, creditor: initial_creditor})
                                        } else {
                                            for (let index = 0; index < props.creditors.length; index++) {
                                                if (props.creditors[index].creditor_id === value) {
                                                    setLoanDetail({...loanDetail, creditor: props.creditors[index]})
                                                    break
                                                }
                                            }
                                        }
                                    }}
                                    title="Select creditor providing loan" name="creditor_id"
                                    value={loanDetail.creditor.creditor_id} options={creditors}/>

                                <MyInput
                                    placeholder="Enter loan amount borrowed" title="Loan principal" name="loan_principal"
                                    change={handle_change} value={Utils.comma_input(loanDetail.loan.loan_principal)}/>

                                <div className="row m-0">
                                    <div className="col-6 p-1">
                                        <MyInput placeholder="Enter loan rate" title="Interest Rate" name="credit_rate"
                                                 change={handle_change} value={loanDetail.loan.credit_rate} className="mb-0"/>
                                    </div>
                                    <div className="col-6 p-1">
                                        <MyInput placeholder="Enter loan period" title="Loan Period" name="loan_period"
                                                 change={handle_change} value={loanDetail.loan.loan_period} className="mb-0"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 p-1">
                                <div style={{marginBottom: '15px'}}>
                                    <MyDateInput
                                        title="Date acquired" value={loanDetail.loan.date_acquired} name="date_acquired"
                                        placeholder="Select date loan acquired" change={handle_change} maxDate={Utils.today()}/>
                                </div>

                                <div style={{marginBottom: '15px'}}>
                                    <MyDateInput
                                        title="Payment start date" value={loanDetail.loan.start_date} name="start_date"
                                        change={handle_change} minDate={loanDetail.loan.date_acquired}
                                        placeholder="Select payment start date"/>
                                </div>

                                <MyDateInput
                                    title="Due date of loan" value={loanDetail.loan.due_date} name="due_date"
                                    placeholder="Expected payment due date" disabled={true} change={handle_change}/>
                            </div>
                        </div>
                    </div>

                    <div className="row m-0">
                        <div className="col-3 pl-0 pr-1">
                            <Button negative onClick={props.close} size='tiny' icon="close"/>

                            <Button negative icon='trash' size='tiny' onClick={delete_loan} disabled={loanDetail.loan.loan_id === 0}/>

                            <Button onClick={() => setShowReschedule(true)} primary icon='sync alternate' size='tiny'
                                    disabled={loanDetail.loan.can_extend === 0}
                            />
                        </div>

                        <div className="col-3 pl-0 pr-1 offset-3">
                            <Button primary onClick={() => setShowPayment({show: true})} fluid size='tiny' content="Payments"
                                    disabled={loanDetail.loan.loan_id === 0} icon="money bill alternate outline" labelPosition="left"/>
                        </div>
                        <div className="col-3 pl-1 pr-0">
                            <Button positive onClick={save_creditor_loan} fluid size='tiny' content="Save Loan" icon="save" labelPosition="left"/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
