import Utils from "../../../utils/Utils";
import {Loading, MyInput, MySelect, SelectData} from "../../../utils/Components";
import React from "react";
import {Button, Table} from "semantic-ui-react";
import {Req} from "../../../utils/Req";
import {ToastsStore} from "react-toasts";
import {Creditor, Creditors} from "./Creditors";
import {LoansDetails} from "./loans/LoansDetails";
import {useAppSelector} from "../../../store/hooks";
import {getStaff} from "../../../store/slices/userSlice";

export interface CreditorLoan {
    creditor_name: string
    due_date: string
    interest_paid: number
    loan_id: number
    loan_principal: number | string
    next_date: string
    next_total: number
    principal_balance: number
    principal_overdue: number
    principal_paid: number
    date_acquired: string
    credit_rate: string
    loan_period: string
    start_date: string
    can_extend: 1 | 0
    loan_status: 'active' | 'deleted' | 'complete'
}

interface Search {
    loan_status: 'active' | 'deleted' | 'complete'
    creditor_name: string
}

export default function SystemCreditors() {
    const user = useAppSelector(getStaff)
    const [loading, setLoading] = React.useState({message: "", show: false})

    const [search, setSearch] = React.useState<Search>({
        creditor_name: "", loan_status: 'active'
    })
    const handle_search = (name: string, value: string) => {
        setSearch({...search, [name]: value})
    }

    const [showCreditors, setShowCreditors] = React.useState(false)
    const [creditors, setCreditors] = React.useState<Array<Creditor>>([])
    const [users, setUsers] = React.useState<Array<SelectData>>([])

    const [loans, setLoans] = React.useState<Array<CreditorLoan>>([])
    const [showLoan, setShowLoan] = React.useState({loan_id: 0, show: false})

    const get_creditors = () => {
        setLoading({message: "Loading available loans, please wait", show: true})
        Req.get_system_creditor({loan_status: search.loan_status, creditor_name: search.creditor_name.trim()})
            .then((response) => {
                if (response.data.hasOwnProperty("creditors")) {
                    setUsers(Utils.get_users(response.data.users, {text: "Select a user", value: 0}))
                    setCreditors(response.data.creditors)
                    setLoans(response.data.loans)
                } else {
                    ToastsStore.error("Could not load creditor data, please retry")
                }
                setLoading({message: "", show: false})
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Could not load creditor data, please retry")
            })
    }

    React.useEffect(() => {
        get_creditors()
    }, [])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <Creditors
                show={showCreditors} creditors={creditors}
                close={(creditors) => {
                    if (creditors !== undefined) {
                        setCreditors(creditors)
                    }
                    setShowCreditors(false)
                }}/>

            <LoansDetails
                show={showLoan.show} creditors={creditors} loan_id={showLoan.loan_id} users={users} user_id={user.staff.user_id}
                close={() => setShowLoan({...showLoan, show: false})}
                update={(loan) => {
                    if (loan.loan_id > 0) {
                        setLoans([loan, ...loans])
                    } else {
                        setLoans(loans.map((_loan) => _loan.loan_id === loan.loan_id ? loan : _loan))
                    }
                    setShowLoan({...showLoan, loan_id: loan.loan_id})
                }}
                delete={((loan_id) => {
                    setLoans(loans.filter((loan) => loan.loan_id !== loan_id))
                    ToastsStore.success("Loan has been deleted successfully")
                    setShowLoan({...showLoan, show: false})
                })}/>

            <>
                <div className="content_bar">
                    <div className="search_bar">
                        <div>
                            <MySelect change={(value) => handle_search('loan_status', value as string)}
                                      title="" name="loan_status" value={search.loan_status}
                                      options={[
                                          {text: 'Active Loans', value: 'active'},
                                          {text: 'Deleted Loans', value: 'deleted'},
                                          {text: 'Complete Loans', value: 'complete'}
                                      ]}/>
                        </div>
                        <div>
                            <MyInput
                                placeholder="Creditor name" title="" name="creditor_name"
                                value={search.creditor_name} change={handle_search} enter={get_creditors}/>
                        </div>
                    </div>

                    <div className="button_bar">
                        <Button primary floated='right' size='mini' onClick={get_creditors} labelPosition="left" icon="search" content="Search"/>
                        <Button primary floated='right' size='mini' content="New Loan" labelPosition="left" icon="add"
                                onClick={() => setShowLoan({loan_id: 0, show: true})}/>
                        <Button floated='right' size='mini' content="Creditors" primary labelPosition="left"
                                onClick={() => setShowCreditors(true)} icon="list alternate"/>
                    </div>
                </div>
                <div className="table_container container_no_footer creditor_container">
                    <Table celled striped compact size='small' inverted color='grey' selectable>
                        <Table.Header>
                            <Table.Row key={0}>
                                <Table.HeaderCell style={{width: '50px'}} rowSpan={2} textAlign="center">No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '180px'}} rowSpan={2}>Creditor Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}} rowSpan={2}>Loan Principal</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '200px'}} colSpan={2} textAlign="center">Total Payments</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '240px'}} colSpan={2} textAlign="center">Next Payments</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}} rowSpan={2}>Amount Overdue</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}} rowSpan={2}>Principal Balance</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}} rowSpan={2}>Due date</Table.HeaderCell>
                            </Table.Row>

                            <Table.Row key={1}>
                                <Table.HeaderCell style={{width: '100px'}}>Principal</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '100px'}}>Interest</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}}>Principal</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}}>Date</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                loans.map((loan, index) =>
                                    <Table.Row key={loan.loan_id}
                                               onClick={() => {
                                                   if (loan.loan_status === "deleted") {
                                                       ToastsStore.info("Loan was already deleted")
                                                   } else {
                                                       setShowLoan({loan_id: loan.loan_id, show: true})
                                                   }
                                               }}>
                                        <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                        <Table.Cell style={{width: '180px'}}>{loan.creditor_name}</Table.Cell>
                                        <Table.Cell style={{width: '120px'}}>{Utils.comma_number(loan.loan_principal as number)}</Table.Cell>
                                        <Table.Cell style={{width: '100px'}}>{Utils.comma_number(loan.principal_paid)}</Table.Cell>
                                        <Table.Cell style={{width: '100px'}}>{Utils.comma_number(loan.interest_paid)}</Table.Cell>
                                        <Table.Cell style={{width: '120px'}}>{Utils.comma_number(loan.next_total)}</Table.Cell>
                                        <Table.Cell style={{width: '120px'}}>{Utils.localise_date(loan.next_date)}</Table.Cell>
                                        <Table.Cell style={{width: '120px'}}>{Utils.comma_number(loan.principal_overdue)}</Table.Cell>
                                        <Table.Cell style={{width: '120px'}}>{Utils.comma_number(loan.principal_balance)}</Table.Cell>
                                        <Table.Cell style={{width: '120px'}}>{Utils.localise_date(loan.due_date)}</Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                </div>
            </>
        </>
    )
}
