import React from "react";
import Utils from "../../utils/Utils";
import {Loading, MyInput, MySelect, select_type, SelectData, TablePagination} from "../../utils/Components";
import {Button, Table} from "semantic-ui-react";
import {Page, Req} from "../../utils/Req"
import CreateClient from "./CreateClient";
import {MultiSelect} from "../../utils/MultiSelect";
import {ToastsStore} from "react-toasts";
import AccountFees, {AccountFee} from "./AccountFees";

interface Client {
    client_id: number
    full_name: string
    branch_name: string
    main_contact: string
    date_created: string
    loans: number
    loan_principal: number
    principal_balance: number
    interest_balance: number
    total_balance: number
    account_fees: AccountFee[]
}

const initial_client: Client = {
    interest_balance: 0, loan_principal: 0, loans: 0, principal_balance: 0, total_balance: 0,
    client_id: 0, date_created: Utils.today(), full_name: "", main_contact: "", branch_name: "", account_fees: []
}

export default function Clients(params: { branches: { select: SelectData[], selected: number[] } }) {
    const [open, setOpen] = React.useState({open: false, client_id: 0});
    const [pages, setPages] = React.useState<Page>({page: 1, pages: 0, limit: 100})
    const [loading, setLoading] = React.useState({show: false, title: "Loading clients, please wait..."})

    const [search, setSearch] = React.useState<{ name: string, branches: number[], status: "pending" | 'paid' | 'all' }>(
        {name: "", branches: params.branches.selected, status: 'all'})
    const handle_search = (name: string, value: (string | number)[] | string | number) => {
        setSearch({...search, [name]: value})
    }
    const [clients, setClients] = React.useState<Array<Client>>([])
    const [showFees, setShowFees] = React.useState<{ client: Client, show: boolean }>({client: initial_client, show: false})

    const search_clients = (params: { page: number, pages: number }) => {
        if (search.branches.length === 0) {
            ToastsStore.info("Select at least 1 branch")
            return
        }

        setLoading({...loading, 'show': true})
        Req.get_clients({
            page: params.page, pages: params.pages, client_name: search.name.trim(),
            branches: JSON.stringify(search.branches), status: search.status
        })
            .then(response => {
                setLoading({...loading, 'show': false})
                if (response.data.hasOwnProperty("clients")) {
                    setClients(response.data.clients)
                    setPages({...pages, pages: response.data.pages, page: params.page})
                }
            })
            .catch(() => {
                setLoading({...loading, 'show': false})
            })
    }

    const get_client_summary = (client_id: number) => {
        setLoading({show: true, title: "Loading client summary, please wait"})
        Req.get_client_summary({client_id: client_id})
            .then((response) => {
                setLoading({show: false, title: ""})
            })
            .catch(() => {
                setLoading({show: false, title: ""})
            })
    }

    return (
        <>
            <Loading show={loading.show} text={loading.title} hide={() => setLoading({...loading, 'show': false})}/>

            <AccountFees client_id={showFees.client.client_id} payments={showFees.client.account_fees}
                         show={showFees.show} client_name={showFees.client.full_name}
                         close={(fees) => {
                             setClients(
                                 clients.map((client) =>
                                     client.client_id === showFees.client.client_id ? {...client, account_fees: fees} : client
                                 )
                             )
                             setShowFees({client: initial_client, show: false})
                         }}/>

            <CreateClient
                show={open.open} client_id={open.client_id}
                close={(_params) => {
                    if (_params !== undefined) {
                        const table_client: Client = {
                            ...initial_client, client_id: _params.client.client_id,
                            full_name: `${_params.client.last_name} ${_params.client.first_name} ${_params.client.other_name}`,
                            main_contact: _params.client.main_contact, account_fees: _params.client.account_fees
                        }
                        if (open.client_id === 0) {
                            setClients([...clients, table_client])
                        } else {
                            setClients(clients.map((client) => client.client_id === open.client_id ? table_client : client))
                        }
                    }
                    setOpen({client_id: 0, open: false})
                }}/>

            <div className="h-100">
                <div className="content_bar">
                    <div className="search_bar">
                        <div style={{width: '180px'}}>
                            <MultiSelect
                                multiple={true} placeholder="Select branches" items={params.branches.select} selected={search.branches}
                                change={(value) => handle_search('branches', value)}/>
                        </div>
                        <div style={{width: '180px'}}>
                            <MySelect
                                change={(value: select_type) => handle_search('status', value as string)}
                                options={[
                                    {text: "All Clients", value: "all"},
                                    {text: "Pending opening fees", value: "pending"},
                                    {text: "Paid opening fees", value: "paid"}
                                ]}
                                name="status" value={search.status}/>
                        </div>
                        <div style={{width: '200px'}}>
                            <MyInput placeholder="Search by client name" name="name" change={handle_search} value={search.name}
                                     enter={() => search_clients({page: 1, pages: 0})}/>
                        </div>
                    </div>
                    <div className="search_button">
                        <Button size='mini' primary icon='user add' labelPosition="left" content="Create"
                                onClick={() => setOpen({client_id: 0, open: true})}/>

                        <Button size='mini' primary icon='search' labelPosition="left" content="Search"
                                onClick={() => search_clients({page: 1, pages: 0})}/>
                    </div>
                </div>

                <div className="table_container">
                    <Table celled striped compact unstackable={true} size='small' inverted color='grey' selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '60px'}} textAlign='center'>No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}/>
                                <Table.HeaderCell style={{width: '250px'}}>Full Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '110px'}}>Main Contact</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '180px'}}>Client Branch</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '140px'}}>Date Created</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '60px'}}>Loans</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '140px'}}>Total Principal</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '140px'}}>Principal Balance</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '140px'}}>Interest Balance</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '140px'}}>Total Balances</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                clients.map((client, index) =>
                                    <Table.Row key={client.client_id}>
                                        <Table.Cell style={{width: '60px'}} textAlign='center'>{Utils.row_number(index, pages)}</Table.Cell>
                                        <Table.Cell style={{width: '130px'}}>
                                            <Button icon='money' primary size='tiny'
                                                    onClick={() => setShowFees({client: client, show: true})}/>
                                            <Button icon='edit' primary size='tiny'
                                                    onClick={() => setOpen({client_id: client.client_id, open: true})}/>
                                            <Button icon='info' primary size='tiny' disabled={client.loans === 0}
                                                    onClick={() => get_client_summary(client.client_id)}/>
                                        </Table.Cell>
                                        <Table.Cell style={{width: '250px'}}>{client.full_name}</Table.Cell>
                                        <Table.Cell style={{width: '110px'}}>{client.main_contact}</Table.Cell>
                                        <Table.Cell style={{width: '180px'}}>{client.branch_name}</Table.Cell>
                                        <Table.Cell style={{width: '140px'}}>{Utils.localise_date(client.date_created)}</Table.Cell>
                                        <Table.Cell style={{width: '60px'}} textAlign='center'>
                                            {Utils.comma_number(client.loans, '', '')}
                                        </Table.Cell>
                                        <Table.Cell style={{width: '140px'}}>{Utils.comma_number(client.loan_principal)}</Table.Cell>
                                        <Table.Cell style={{width: '140px'}}>{Utils.comma_number(client.principal_balance)}</Table.Cell>
                                        <Table.Cell style={{width: '140px'}}>{Utils.comma_number(client.interest_balance)}</Table.Cell>
                                        <Table.Cell style={{width: '140px'}}>{Utils.comma_number(client.total_balance)}</Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                </div>

                <div className="table_footer">
                    <TablePagination total={pages.pages} change={(page: number) => search_clients({page: page, pages: pages.pages})}/>
                </div>
            </div>
        </>
    )
}
