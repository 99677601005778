import React from "react";
import {Table} from "semantic-ui-react";
import Utils from "../../../../../utils/Utils";
import ManageCollateral from "./ManageCollateral";

export interface Security {
    collateral_id: number
    collateral_name: string
    collateral_value: string
    collateral_description: string
    images: Array<collateral_image>
    index: number
}

export type collateral_image = { path: string, file?: File }

export const initial_security: Security = {
    index: -1, collateral_description: "", collateral_id: 0, collateral_name: "", collateral_value: "", images: []
}

export default function LoanCollateral(props: {
    securities: Array<Security>, save: (securities: Security) => void, editDisabled: boolean
}) {
    const [security, setSecurity] = React.useState<Security>(initial_security)

    return (
        <>
            <div className="row m-0 h-100">
                <div className="col-12 col-md-8 p-1 h-100">
                    <div className="table_container full_height">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '350px'}}>Collateral Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>Collateral Value</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '100px'}} textAlign="center">Images</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    props.securities.map((security, index) =>
                                        <Table.Row key={index} onClick={() => setSecurity({...security, index: index})}>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">
                                                {Utils.row_number(index)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '350px'}}>{security.collateral_name}</Table.Cell>
                                            <Table.Cell style={{width: '140px'}} >
                                                {Utils.comma_number(parseFloat(security.collateral_value))}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '100px'}} textAlign="center">{security.images.length}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                <div className="col-12 col-md-4 p-1 h-100">
                    <ManageCollateral
                        security={security} save={props.save} securities={props.securities} editDisabled={props.editDisabled}/>
                </div>
            </div>
        </>
    )
}
