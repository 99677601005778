import Utils from "../../../utils/Utils";
import {Req} from "../../../utils/Req";
import React from "react";
import {useAppSelector} from "../../../store/hooks";
import {getStaff} from "../../../store/slices/userSlice";
import {MyDateInput, SelectData} from "../../../utils/Components";
import {ToastsStore} from "react-toasts";
import {Rights} from "../../admin/users/rights/AccessRights";
import {Office} from "../../admin/users/Offices";
import {MultiSelect} from "../../../utils/MultiSelect";
import {Button, Table} from "semantic-ui-react";
import {BranchSummary} from "./Branch";

export default function Company(params: { offices: Array<Office> }) {
    const user = useAppSelector(getStaff)
    const [searching, setSearching] = React.useState(false)
    const [offices, setOffices] = React.useState<Array<SelectData>>([])

    const [search, setSearch] = React.useState({min_date: '2018-01-01', max_date: Utils.today(), branches: Array<number>()})
    const handle_change = (name: string, value: string | number | (string | number)[]) => {
        setSearch({...search, [name]: value})
    }

    const [summaries, setSummaries] = React.useState(Array<BranchSummary>())
    const search_data = () => {
        setSearching(true)
        Req.get_branches_summary({
            branches: JSON.stringify(search.branches), max_date: search.max_date, min_date: search.min_date
        })
            .then((response) => {
                if (response.data.hasOwnProperty("summary")) {
                    setSummaries(response.data.summary)
                } else {
                    ToastsStore.error("Error while loading summaries")
                }
                setSearching(false)
            })
            .catch(() => {
                setSearching(false)
                ToastsStore.error("Error while loading summaries")
            })
    }

    const format = (number: number) => {
        return Utils.comma_number(Utils.roundToTwo(Utils.roundToTwo(number, '0')), '')
    }

    React.useEffect(() => {
        if (search.branches.length > 0) {
            search_data()
        }
    }, [search.branches])

    React.useEffect(() => {
        const select_info = Utils.get_accessible_office(params.offices, user.roles.user_roles, Rights.view_loans)
        setSearch({...search, branches: select_info.selected})
        setOffices(select_info.select)
    }, [params.offices])

    return (
        <>
            <div className="content_bar">
                <div className="search_bar">
                    <div>
                        <MultiSelect
                            multiple={true} placeholder="Select branches" items={offices} selected={search.branches}
                            change={(value) => handle_change('branches', value)}/>
                    </div>
                    <div style={{width: '140px'}}>
                        <MyDateInput
                            title="" value={search.min_date} name="min_date" placeholder="Minimum Date"
                            change={handle_change} maxDate={search.max_date}/>
                    </div>
                    <div style={{width: '140px'}}>
                        <MyDateInput
                            title="" value={search.max_date} name="max_date" placeholder="Maximum Date"
                            change={handle_change} minDate={search.min_date}/>
                    </div>
                </div>
                <div className="search_button">
                    <Button primary floated='right' size='mini' icon='search' loading={searching} disabled={searching} onClick={search_data}/>
                </div>
            </div>

            <div className="table_container" style={{height: 'auto'}}>
                <Table celled striped compact size='small' inverted color='grey' selectable structured className={'has_total'}>
                    <Table.Header fullWidth>
                        <Table.Row key={1}>
                            <Table.HeaderCell style={{width: '200px'}} rowSpan='2'>Branch Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '80px'}} rowSpan='2'>Clients</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '300px'}} colSpan='4' textAlign={'center'}>Total Loans</Table.HeaderCell>

                            <Table.HeaderCell style={{width: '300px'}} colSpan='3' textAlign={'center'}>Total Collections</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '400px'}} colSpan='4' textAlign={'center'}>Loan Balances</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '200px'}} colSpan='2' textAlign={'center'}>Interest Balances</Table.HeaderCell>

                            <Table.HeaderCell style={{width: '200px'}} colSpan='2' textAlign={'center'}>Due Loans</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '400px'}} colSpan='4' textAlign={'center'}>Loan Defaulters</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row key={2}>
                            <Table.HeaderCell style={{width: '150px'}}>Disbursed</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '50px'}}>Complete</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '50px'}}>Active</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '50px'}}>Total</Table.HeaderCell>

                            <Table.HeaderCell style={{width: '100px'}}>Principal</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '100px'}}>Incomes</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '100px'}}>Total</Table.HeaderCell>

                            <Table.HeaderCell style={{width: '100px'}}>Principal</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '100px'}}>Penalty</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '100px'}}>Fees</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '100px'}}>Total</Table.HeaderCell>

                            <Table.HeaderCell style={{width: '100px'}}>Actual</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '100px'}}>Projected</Table.HeaderCell>

                            <Table.HeaderCell style={{width: '150px'}}>Amount</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '50px'}}>Loans</Table.HeaderCell>

                            <Table.HeaderCell style={{width: '150px'}}>All</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '50px'}}>Loans</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '150px'}}>Defaulters</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '50px'}}>Loans</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            summaries
                                .map((summary) =>
                                    <Table.Row key={summary.branch_id}>
                                        <Table.Cell style={{width: '200px'}}>{summary.branch_name}</Table.Cell>
                                        <Table.Cell style={{width: '80px'}} textAlign={'center'}>{format(summary.total_clients)}</Table.Cell>

                                        <Table.Cell style={{width: '150px'}}>{format(summary.principal_disbursed)}</Table.Cell>
                                        <Table.Cell style={{width: '50px'}} textAlign={'center'}>{format(summary.loans_complete)}</Table.Cell>
                                        <Table.Cell style={{width: '50px'}} textAlign={'center'}>{format(summary.loans_active)}</Table.Cell>
                                        <Table.Cell style={{width: '50px'}} textAlign={'center'}>{format(summary.loans)}</Table.Cell>

                                        <Table.Cell style={{width: '100px'}}>{format(summary.principal_collection)}</Table.Cell>
                                        <Table.Cell style={{width: '100px'}}>{format(summary.income_collection)}</Table.Cell>
                                        <Table.Cell style={{width: '100px'}}>
                                            {format(summary.principal_collection + summary.income_collection)}
                                        </Table.Cell>

                                        <Table.Cell style={{width: '100px'}}>{format(summary.principal_balance)}</Table.Cell>
                                        <Table.Cell style={{width: '100px'}}>{format(summary.penalty_balance)}</Table.Cell>
                                        <Table.Cell style={{width: '100px'}}>{format(summary.fee_balance)}</Table.Cell>
                                        <Table.Cell style={{width: '100px'}}>
                                            {format(summary.principal_balance + summary.penalty_balance + summary.fee_balance)}
                                        </Table.Cell>

                                        <Table.Cell style={{width: '100px'}}>{format(summary.interest_balance)}</Table.Cell>
                                        <Table.Cell style={{width: '100px'}} textAlign={'center'}>{format(summary.interest_balances)}</Table.Cell>

                                        <Table.Cell style={{width: '150px'}}>{format(summary.loans_due_balance)}</Table.Cell>
                                        <Table.Cell style={{width: '50px'}} textAlign={'center'}>{format(summary.loans_due)}</Table.Cell>

                                        <Table.Cell style={{width: '150px'}}>{format(summary.loans_defaulters_amount)}</Table.Cell>
                                        <Table.Cell style={{width: '50px'}} textAlign={'center'}>{format(summary.loans_defaulters)}</Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>{format(summary.loans_due_defaulters_amount)}</Table.Cell>
                                        <Table.Cell style={{width: '50px'}} textAlign={'center'}>{format(summary.loans_due_defaulters)}</Table.Cell>
                                    </Table.Row>
                                )
                        }

                    </Table.Body>
                </Table>
            </div>
        </>
    )
}
