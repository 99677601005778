import React from "react";
import {Table} from "semantic-ui-react";
import Utils from "../../../utils/Utils";

interface Report {
    total: { incomes: number, expenses: number, profits: number }
    incomes: { name: string, amount: number }[]
    expenses: { name: string, amount: number }[]
}

export default function IncomesStatementReport(params: { transactions: any }) {
    const [statements, setStatements] = React.useState<Report>({
        expenses: [], incomes: [], total: {expenses: 0, incomes: 0, profits: 0}
    })

    React.useEffect(() => {
        if (params.transactions.hasOwnProperty("rp")) {
            setStatements(params.transactions.rp)
        }
    }, [params.transactions])

    return (
        <div className="table_container full_height">
            <Table celled striped inverted compact structured size='small' color='grey'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell style={{width: '300px'}}>Income Name</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '150px'}}>Income Amount</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '300px'}}>Expense Name</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '150px'}}>Expense Amount</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        statements.expenses
                            .map((transaction, index) =>
                                <>
                                    <Table.Row key={index}>
                                        {
                                            (index < statements.incomes.length) ?
                                                <>
                                                    <Table.Cell style={{width: '300px'}}>{statements.incomes[index].name}</Table.Cell>
                                                    <Table.Cell style={{width: '150px'}}>
                                                        {Utils.comma_number(statements.incomes[index].amount)}
                                                    </Table.Cell>
                                                </> :
                                                <Table.Cell style={{width: '450px'}} colSpan={2}/>
                                        }

                                        {
                                            (index < statements.expenses.length) ?
                                                <>
                                                    <Table.Cell style={{width: '300px'}}>{statements.expenses[index].name}</Table.Cell>
                                                    <Table.Cell style={{width: '150px'}}>
                                                        {Utils.comma_number(statements.expenses[index].amount)}
                                                    </Table.Cell>
                                                </> :
                                                <Table.Cell style={{width: '450px'}} colSpan={2}/>
                                        }
                                    </Table.Row>
                                </>
                            )
                    }

                    {
                        statements.expenses.length > 0 &&
                        <>
                            <Table.Row key="totals" className="has_total">
                                <Table.Cell style={{width: '300px'}}>TOTAL INCOMES</Table.Cell>
                                <Table.Cell style={{width: '150px'}}>{Utils.comma_number(statements.total.incomes)}</Table.Cell>
                                <Table.Cell style={{width: '300px'}}>TOTAL EXPENSES</Table.Cell>
                                <Table.Cell style={{width: '150px'}}>{Utils.comma_number(statements.total.expenses)}</Table.Cell>
                            </Table.Row>
                            <Table.Row key="profits" className="has_total">
                                <Table.Cell style={{width: '750px'}} colSpan={3}>
                                    GROSS {statements.total.profits < 0 ? "LOSS" : "PROFIT"}
                                </Table.Cell>
                                <Table.Cell style={{width: '150px'}}>{Utils.comma_number(Math.abs(statements.total.profits))}</Table.Cell>
                            </Table.Row>
                        </>
                    }
                </Table.Body>
            </Table>
        </div>
    )
}
