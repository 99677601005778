import {Button, Modal, Table} from "semantic-ui-react";
import React from "react";
import Utils from "../../../../utils/Utils";
import {MyDateInput, MyInput, MySelect, select_type, SelectData} from "../../../../utils/Components";
import {Req} from "../../../../utils/Req";
import {ToastsStore} from "react-toasts";
import {LoanDetail} from "./LoansDetails";

export interface CreditorPayment {
    payment_id: number
    loan_id: number
    paid_by: string
    user_id: number
    cashier_id: number
    interest_paid: number
    principal_paid: number
    time_paid: string
    date_created: string
    payment_method: "cash" | "mobile_money" | "money_transfer" | "cheque"
    reference_no: string
}

interface Payment {
    payment_id: number
    loan_id: number
    principal_paid: number | string
    interest_paid: number | string
    date_paid: string
    payment_method: "cash" | "bank" | ""
    reference_no: string
    user_id: number
    prepared_by: number
}

export function LoanPayments(props: {
    show: boolean, loan: LoanDetail, users: Array<SelectData>, user_id: number, update: (loanDetail?: LoanDetail) => void
}) {
    const initial_payment: Payment = {
        prepared_by: props.user_id, user_id: props.user_id, date_paid: Utils.today(), loan_id: props.loan.loan.loan_id,
        payment_id: 0, payment_method: '', reference_no: "", interest_paid: 0, principal_paid: 0
    }
    const [payment, setPayment] = React.useState(initial_payment)

    const handle_change = (name: string, value: string | number) => {
        setPayment({...payment, [name]: value})
    }
    const [saving, setSaving] = React.useState(false)

    const make_payment = () => {
        if (payment.user_id === 0) {
            ToastsStore.error("Select user who made the payment")
        } else if (!Utils.is_valid_number(payment.principal_paid as string) || parseFloat(payment.principal_paid as string) < 0) {
            ToastsStore.error("Enter a valid total principal paid")
        } else if (!Utils.is_valid_number(payment.interest_paid as string) || parseFloat(payment.interest_paid as string) < 0) {
            ToastsStore.error("Enter a valid total interest paid")
        } else if (payment.payment_method === "") {
            ToastsStore.error("Select a payment method")
        } else {
            setSaving(true)
            Req.pay_creditor_loan({
                loan_id: props.loan.loan.loan_id,
                payment_method: payment.payment_method,
                reference_no: payment.reference_no,
                time_paid: payment.date_paid,
                principal_paid: parseFloat(payment.principal_paid as string),
                interest_paid: parseFloat(payment.interest_paid as string),
                user_id: payment.user_id
            })
                .then((response) => {
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        setPayment(initial_payment)
                        props.update(response.data.loan)
                        ToastsStore.success("Successfully saved payment")
                    } else {
                        ToastsStore.error("Error while making payment, please retry")
                    }
                    setSaving(false)
                })
                .catch((error) => {
                    setSaving(false)
                    ToastsStore.error("Error while making payment, please retry")
                })
        }
    }

    React.useEffect(() => {
        if (props.show) {
            setPayment(initial_payment)
        }
    }, [props.show])

    return (
        <>
            <Modal open={props.show} basic size='small' closeOnDocumentClick={true} centered={false}
                   onClose={() => props.update()}>
                <div className="modal_div creditor_payments">
                    <div className="row m-0 h-100">
                        <div className="col-12 col-md-7 p-1 h-100">
                            <div className="table_container">
                                <Table celled striped compact size='small' inverted color='grey' selectable>
                                    <Table.Header fullWidth>
                                        <Table.Row>
                                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '120px'}}>Date Paid</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '120px'}}>Principal Paid</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '120px'}}>Interest Paid</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '120px'}}>Total Paid</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            props.loan.payments.map((payment, index) =>
                                                <Table.Row
                                                    key={index}
                                                    onClick={() => {
                                                        setPayment({
                                                            date_paid: payment.time_paid,
                                                            loan_id: payment.loan_id,
                                                            payment_id: payment.payment_id,
                                                            payment_method: payment.payment_method as "bank" | "cash",
                                                            reference_no: payment.reference_no,
                                                            principal_paid: payment.principal_paid,
                                                            interest_paid: payment.interest_paid,
                                                            user_id: payment.user_id,
                                                            prepared_by: payment.cashier_id,
                                                        })
                                                    }}>
                                                    <Table.Cell style={{width: '50px'}} textAlign="center">
                                                        {Utils.row_number(index)}
                                                    </Table.Cell>
                                                    <Table.Cell style={{width: '120px'}}>
                                                        {Utils.localise_date(payment.time_paid)}
                                                    </Table.Cell>
                                                    <Table.Cell style={{width: '120px'}}>
                                                        {Utils.comma_number(payment.principal_paid)}
                                                    </Table.Cell>
                                                    <Table.Cell style={{width: '120px'}}>
                                                        {Utils.comma_number(payment.interest_paid)}
                                                    </Table.Cell>
                                                    <Table.Cell style={{width: '120px'}}>
                                                        {Utils.comma_number(payment.interest_paid + payment.principal_paid)}
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>

                        <div className="col-12 col-md-5 p-1 h-100">
                            <div className="form">
                                <div className="form_content no_header">
                                    <MySelect
                                        change={(value: select_type) => handle_change('user_id', value as number)}
                                        title="Prepared By" name="prepared_by" disabled={true}
                                        value={payment.prepared_by} options={props.users}/>

                                    <MySelect
                                        change={(value: select_type) => handle_change('user_id', value as number)}
                                        title="Processed By" name="user_id" disabled={payment.payment_id > 0}
                                        value={payment.user_id} options={props.users}/>

                                    <MyInput placeholder="Enter principal paid" title="Principal Paid" name="principal_paid"
                                             change={handle_change} disabled={payment.payment_id > 0}
                                             value={payment.payment_id === 0 ? (payment.principal_paid as string) :
                                                 Utils.comma_number(payment.principal_paid as number)}/>

                                    <MyInput placeholder="Enter interest paid" title="Interest Paid" name="interest_paid"
                                             change={handle_change} disabled={payment.payment_id > 0}
                                             value={payment.payment_id === 0 ? (payment.interest_paid as string) :
                                                 Utils.comma_number(payment.interest_paid as number)}/>

                                    <MyInput placeholder="Enter Reference No." title="Reference No." name="reference_no"
                                             change={handle_change} style={{marginBottom: '15px'}}
                                             value={payment.reference_no} disabled={payment.payment_id > 0}/>

                                    <MyDateInput
                                        title="Payment Date" value={payment.date_paid} name="date_paid" placeholder="Select payment date"
                                        change={handle_change} maxDate={Utils.today()} minDate={props.loan.loan.date_acquired}/>

                                    <MySelect
                                        title="Payment Method" name="payment_method" value={payment.payment_method}
                                        change={(value: select_type) => handle_change('payment_method', (value as string))}
                                        options={
                                            [
                                                {text: "Select payment method", value: ""},
                                                {text: "Cash Payment", value: "cash"},
                                                {text: "Bank Payment", value: "bank"}
                                            ]
                                        }/>
                                </div>

                                <div className="form_footer">
                                    <div className="row mx-0">
                                        <div className="col-6 pl-0 pr-1">
                                            <Button negative disabled={saving} fluid size='tiny' icon="close" labelPosition="left"
                                                    content={payment.payment_id === 0 ? "Close Window" : "Clear Data"}
                                                    onClick={() => {
                                                        if (payment.payment_id === 0) {
                                                            props.update()
                                                        } else {
                                                            setPayment(initial_payment)
                                                        }
                                                    }}
                                            />
                                        </div>
                                        <div className="col-6 pl-1 pr-0">
                                            <Button positive onClick={make_payment} loading={saving} fluid size='tiny' content="Save Payment"
                                                    disabled={saving || payment.payment_id > 0} icon="save" labelPosition="left"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
