import React from "react";
import {Table} from "semantic-ui-react";

interface Report {

}

export default function BalanceSheet(params: { transactions: any }) {
    const [statements, setStatements] = React.useState<Array<Report>>([])

    React.useEffect(() => {
        if (params.transactions.hasOwnProperty("rp")) {
            setStatements(params.transactions.rp)
        }
    }, [params.transactions])

    return (
        <>
            <Table celled striped compact size='small' inverted color='grey' selectable className='has_total'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell style={{width: '450px'}}/>
                        <Table.HeaderCell style={{width: '150px'}}>Amount (shs)</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '150px'}}>Amount (shs)</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '150px'}}>Amount (shs)</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>

                </Table.Body>
            </Table>
        </>
    )
}
