import {Button, Modal, Table} from "semantic-ui-react";
import React from "react";
import {ModifiedLoan} from "./ModifyLoan";
import Utils from "../../../../utils/Utils";
import {MyDateInput, MyInput, MySelect, select_type} from "../../../../utils/Components";
import {LoanProps} from "../../container/ShowLoan";
import {ToastsStore} from "react-toasts";

export interface ModifyCharge {
    cashier_id: number
    cashier_name: string
    charge_amount: number | string
    charge_id: number
    charge_type: "Percentage" | "Flat"
    charge_value: number
    date_paid: string
    type_id: number
    fee_name: string
    time_paid: string
}

export default function ModifyCharges(params: {
    loan: ModifiedLoan, show: boolean, close: (charges?: ModifyCharge[]) => void, loanProps: LoanProps
}) {
    const initial_charge: ModifyCharge = {
        cashier_id: 0, cashier_name: "", charge_amount: 0, charge_id: 0, charge_type: "Flat", charge_value: 0,
        date_paid: "", fee_name: "", time_paid: "", type_id: 0
    }

    const [charge, setCharge] = React.useState(initial_charge)
    const [charges, setCharges] = React.useState(Array<ModifyCharge>())

    const handle_change = (name: string, value: string | number) => {
        setCharge({...charge, [name]: value})
    }

    const update_charge = () => {
        if (!Utils.is_valid_number(Utils.strip_commas(charge.charge_amount))) {
            ToastsStore.error("Enter a valid charge amount")
        } else if (charge.cashier_id > 0 && (charge.date_paid === "" || charge.time_paid === "")) {
            ToastsStore.error("Cashier provided, Payment date and payment time must also be provided")
        } else if (charge.date_paid !== "" && (charge.cashier_id === 0 || charge.time_paid === "")) {
            ToastsStore.error("Payment date provided, cashier name and payment time must also be provided")
        } else if (charge.time_paid !== "" && (charge.cashier_id === 0 || charge.date_paid === "")) {
            ToastsStore.error("Payment time provided, cashier name and payment date must also be provided")
        } else {
            setCharges(charges.map((_charge) => _charge.charge_id === charge.charge_id
                ? {...charge, charge_amount: Utils.strip_commas(charge.charge_amount)} : _charge))
            setCharge(initial_charge)
        }
    }

    const save_charges = () => {
        params.close(charges)
    }

    React.useEffect(() => {
        setCharges(params.loan.charges)
    }, [params.loan.charges])

    return (
        <Modal open={params.show} basic size='small' centered={false} onClose={() => params.close()}>
            <div className='modify_loan charges row m-0'>
                <div className="col-7 p-1 h-100">
                    <div className="table_container" style={{height: 'calc(100% - 45px)'}}>
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '140px'}}>Charge Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Charge Value</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Charge Amount</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    charges.map((charge) =>
                                        <Table.Row key={charge.charge_id} onClick={() => setCharge(charge)}>
                                            <Table.Cell style={{width: '140px'}}>{charge.fee_name}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>
                                                {Utils.comma_number(
                                                    charge.charge_value,
                                                    charge.charge_type === "Percentage" ? "" : "UGX ",
                                                    charge.charge_type === "Flat" ? "" : "%"
                                                )
                                                }{}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>
                                                {Utils.comma_number(charge.charge_amount as number)}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>

                    <div className="row mx-0 mt-1">
                        <div className="col-12 col-md-8 offset-md-2 p-1">
                            <Button fluid size='tiny' primary onClick={save_charges} content='Update Charges'
                                    icon='save' labelPosition={"left"}/>
                        </div>
                    </div>
                </div>

                <div className="col-5 p-1 h-100">
                    <div className="form">
                        <div className="form_content no_header">

                            <MyInput placeholder="Enter charge amount" name="charge_amount" title="Charge Amount"
                                     value={Utils.comma_input(charge.charge_amount).toString()} change={handle_change}/>

                            <MySelect
                                change={(value: select_type) => handle_change('cashier_id', (value as number))}
                                name={`cashier_id`} value={charge.cashier_id} className="mb-0" title="Cashier Name"
                                options={[
                                    {text: "Charge not paid", value: 0},
                                    ...params.loanProps.staffs.map((staff) =>
                                        ({text: staff.user_name, value: staff.user_id}))
                                ]}/>

                            <div className="my-3">
                                <MyDateInput
                                    title="Date paid" value={charge.date_paid} name="date_paid" placeholder="" change={handle_change}/>
                            </div>

                            <MyDateInput
                                title="Time Paid" value={charge.time_paid} name="time_paid" placeholder=""
                                type="time" change={handle_change}/>
                        </div>

                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-8 offset-2 pl-1 pr-0">
                                    <Button positive onClick={update_charge} content="Update Charge" size='mini'
                                            fluid icon="save" labelPosition={"left"} disabled={charge.charge_id === 0}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
