import React from "react";
import {Button, Confirm, Table} from "semantic-ui-react";
import Utils, {ConfirmAction, initial_confirm} from "../../../../utils/Utils";
import {Loading, MyInput, PDFReader} from "../../../../utils/Components";
import {LoanInfo, LoanProps} from "../ShowLoan";
import {Req} from "../../../../utils/Req";
import {ToastsStore} from "react-toasts";
import {useAppSelector} from "../../../../store/hooks";
import {getStaff} from "../../../../store/slices/userSlice";
import {Rights} from "../../../admin/users/rights/AccessRights";
import JSPrinter from "../../../../utils/JSPrinter";

export interface PaymentSummary {
    interest_paid: number
    interest_balance: number
    principal_paid: number
    principal_balance: number
    next_payment_date: string
    next_payment_amount: number
    penalty_balance: number
    loan_fee_balance: number
}

export interface LoanPayment {
    office_name: string
    accountant_name: string
    interest_balance: number
    interest_paid: number
    loan_id: number
    payer_contact: string
    payer_name: string
    payment_id: number
    penalty_paid: number
    over_payment: number
    principal_balance: number
    principal_paid: number
    time_paid: string
    time_verified: null | string
    total_paid: number
    verified_by: null | string
    loan_balance: number
    reversible: 1 | 0
    accountant_id: number
    payment_status: 'accepted' | 'rejected'
}

export default function LoanPayments(params: { loanProps: LoanProps, loanInfo: LoanInfo, setLoanInfo: (loanInfo: LoanInfo) => void }) {
    const user = useAppSelector(getStaff)

    const [loading, setLoading] = React.useState({message: "", show: false})
    const [showReader, setShowReader] = React.useState({display: false, source: ""})
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)

    const process_receipt = (payment_id: number) => {
        setLoading({message: "Generating receipt, please wait", show: true})
        Req.get_payment_receipt({payment_id: payment_id})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code")) {
                    if (response.data.code === 1) {
                        if (response.data.hasOwnProperty("file")) {
                            setShowReader({display: true, source: `${Req.BASE_URL}${response.data.file}`})
                        } else if (response.data.hasOwnProperty("receipt")) {
                            JSPrinter.print_receipt({response: response.data})
                        }
                    } else {
                        ToastsStore.error("Could not generate receipt, please retry")
                    }
                } else {
                    ToastsStore.error("Could not generate receipt, please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Could not generate receipt, please retry")
            })
    }

    const reverse_payment = (payment_id: number) => {
        setConfirm({
            ...confirm, open: true, content: 'Are you sure you want to reverse this transaction',
            onConfirm: () => {
                setConfirm({...confirm, 'open': false})

                setLoading({message: "Reversing transaction, please wait", show: true})
                Req.reverse_payment({payment_id: payment_id, loan_id: params.loanInfo.loan_id})
                    .then((response) => {
                        setLoading({message: "", show: false})
                        if (response.data.hasOwnProperty("code") && response.data['code'] === 1) {
                            params.setLoanInfo(Utils.format_loan_data(response))
                        } else {
                            ToastsStore.error("Could not reverse payment, please retry")
                        }
                    })
                    .catch(() => {
                        ToastsStore.error("Could not reverse payment, please retry")
                        setLoading({message: "", show: false})
                    })
            },
            onCancel: () => setConfirm({...confirm, open: false})
        })
    }

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <PDFReader source={showReader.source} open={showReader.display}
                       close={() => setShowReader({...showReader, display: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} centered={false} size='mini'
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} content={confirm.content}/>

            <div className="row mx-0 h-100">
                <div className="col-12 p-1">
                    <div className="client_payment_summary">
                        <div className="row m-0">
                            <div className='col-12 col-md-6 p-0'>
                                <div className='row m-0'>
                                    <div className="col-3 p-1">
                                        <MyInput
                                            placeholder="Total Principal Paid" title="Total Principal Paid" name="principal_paid"
                                            disabled={true} value={Utils.comma_number(params.loanInfo.payment_summary.principal_paid)}/>
                                    </div>
                                    <div className="col-3 p-1">
                                        <MyInput
                                            placeholder="Total Interest Paid" title="Total Interest Paid" name="interest_paid" disabled={true}
                                            value={Utils.comma_number(params.loanInfo.payment_summary.interest_paid)}/>
                                    </div>
                                    <div className="col-3 p-1">
                                        <MyInput
                                            placeholder="Principal Balance" title="Principal Balance" name="principal_balance" disabled={true}
                                            value={Utils.comma_number(params.loanInfo.payment_summary.principal_balance)}/>
                                    </div>
                                    <div className="col-3 p-1">
                                        <MyInput
                                            placeholder="Interest Balance" title="Interest Balance" name="interest_balance" disabled={true}
                                            value={Utils.comma_number(params.loanInfo.payment_summary.interest_balance)}/>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-md-6 p-0'>
                                <div className='row m-0'>
                                    <div className="col-3 p-1">
                                        <MyInput
                                            placeholder="Penalty Balance" title="Penalty Balance" name="penalty_balance" disabled={true}
                                            value={Utils.comma_number(params.loanInfo.payment_summary.penalty_balance)}/>
                                    </div>
                                    <div className="col-3 p-1">
                                        <MyInput
                                            placeholder="Other Fees" title="Other Fees" name="other_fees" disabled={true}
                                            value={Utils.comma_number(params.loanInfo.payment_summary.loan_fee_balance)}/>
                                    </div>

                                    <div className="col-3 p-1">
                                        <MyInput
                                            placeholder="Payment Date" title="Payment Date" name="next_payment_date" disabled={true}
                                            value={
                                                params.loanInfo.payment_summary.next_payment_amount === 0 ? 'LOAN COMPLETE'
                                                    : Utils.localise_date(params.loanInfo.payment_summary.next_payment_date)
                                            }/>
                                    </div>
                                    <div className="col-3 p-1">
                                        <MyInput
                                            placeholder="Next Payment" title="Next Payment" name="next_payment_amount" disabled={true}
                                            value={Utils.comma_number(params.loanInfo.payment_summary.next_payment_amount)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 p-1 loan_payments_parent">
                    <div className="table_container loan_payments_container full_height">
                        <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '40px'}}/>
                                    <Table.HeaderCell style={{width: '80px'}}/>
                                    <Table.HeaderCell style={{width: '140px'}}>Date Paid</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '180px'}}>Accountant Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Interest Paid</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Principal Paid</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Interest Balance</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Principal Balance</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Penalty Paid</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Extra Paid</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Total Paid</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    params.loanInfo
                                        .payments.map((transaction, index) => <>
                                            <Table.Row key={index} className={transaction.payment_status === 'rejected' ? 'reversed' : ''}>
                                                <Table.Cell style={{width: '40px'}} textAlign="center">
                                                    {Utils.row_number(index)}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '80px'}}>
                                                    <Button
                                                        primary size='mini' icon='file pdf' compact
                                                        disabled={transaction.payment_status === 'rejected'}
                                                        onClick={() => process_receipt(transaction.payment_id)}/>
                                                    <Button
                                                        primary size='mini' icon='redo' compact
                                                        disabled={!['pandewilliam100@gmail.com', 'mpkatibe@gmail.com'].includes(user.staff.user_email)}
                                                        onClick={() => reverse_payment(transaction.payment_id)}/>
                                                </Table.Cell>
                                                <Table.Cell style={{width: '140px'}}>{Utils.localise_date(transaction.time_paid)}</Table.Cell>
                                                <Table.Cell style={{width: '180px'}}>{transaction.accountant_name}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>{Utils.comma_number(transaction.interest_paid)}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>{Utils.comma_number(transaction.principal_paid)}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>{Utils.comma_number(transaction.interest_balance)}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>{Utils.comma_number(transaction.loan_balance)}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>{Utils.comma_number(transaction.penalty_paid)}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>{Utils.comma_number(transaction.over_payment)}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>{Utils.comma_number(transaction.total_paid)}</Table.Cell>
                                            </Table.Row>
                                        </>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}
