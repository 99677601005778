import Utils from "../../../../utils/Utils";
import {ToastsStore} from "react-toasts";
import CreateClient, {SystemClient} from "../../../clients/CreateClient";
import React from "react";
import {MyInput, MySelect, select_type} from "../../../../utils/Components";
import {ModifiedLoan} from "./ModifyLoan";
import {LoanProps} from "../../container/ShowLoan";
import {Button, Modal} from "semantic-ui-react";

export interface ModifyGuarantor {
    client_id: number
    relationship: string
    monthly_income: string
}

export default function ModifyGuarantors(params: {
    loan: ModifiedLoan, close: (guarantors?: ModifyGuarantor[]) => void, loanProps: LoanProps, show: boolean, searchingClients: boolean,
    query_clients: (query: string, guarantors: Array<number>) => void, setClients: (clients: SystemClient[]) => void
}) {
    const [guarantors, setGuarantors] = React.useState(params.loan.guarantors)
    const handle_change = (index: 1 | 0, name: string, value: string | number) => {
        setGuarantors(
            guarantors.map((guarantor, _index) => index === _index ? {...guarantor, [name]: value} : guarantor)
        )
    }
    const [addGuarantor, setAddGuarantor] = React.useState<{ index: 0 | 1, show: boolean }>({index: 0, show: false})

    const save_guarantors = () => {
        let has_error = false
        for (let index = 0; index < guarantors.length; index++) {
            const guarantor = guarantors[index]
            const word = index === 0 ? "first" : "second"
            if (Utils.is_valid_number(guarantor.monthly_income) || guarantor.relationship !== "" || guarantor.client_id > 0) {
                if (guarantor.client_id === 0) {
                    ToastsStore.error(`Select a ${word} loan guarantor name`)
                    has_error = true
                    break
                }
                if (guarantor.relationship === "") {
                    ToastsStore.error(`Select a ${word} loan guarantor relationship`)
                    has_error = true
                    break
                }
                if (!Utils.is_valid_number(guarantor.monthly_income)) {
                    ToastsStore.error(`Enter valid monthly income for ${word} guarantor`)
                    has_error = true
                    break
                }
            }
        }
        if (!has_error) {
            params.close(guarantors)
        }
    }

    const query_clients = (query: string) => {
        let clients: number[] = []
        if (guarantors[1].client_id > 0) {
            clients = [...clients, guarantors[1].client_id]
        }
        if (guarantors[0].client_id > 0) {
            clients = [...clients, guarantors[0].client_id]
        }
        params.query_clients(query, clients)
    }

    React.useEffect(() => {
        setGuarantors(params.loan.guarantors)
    }, [params.loan.guarantors])

    return (
        <>
            <CreateClient
                show={addGuarantor.show}
                close={(props) => {
                    if (props !== undefined) {
                        handle_change(addGuarantor.index, 'client_id', props.client.client_id)
                        params.setClients([{
                            client_id: props.client.client_id,
                            client_name: `${props.client.last_name} ${props.client.first_name}`,
                            main_contact: props.client.main_contact
                        }, ...params.loanProps.clients])
                    }
                }}/>

            <Modal open={params.show} basic size='small' centered={false} onClose={() => params.close()}>
                <div className='modify_loan row m-0'>
                    {
                        guarantors.map((guarantor, _index: number) => {
                            const index = _index as 1 | 0
                            return (
                                <div className="col-6 p-2">
                                    <MySelect
                                        change={(value: select_type) => {
                                            const client_id = value as number
                                            if (client_id === -1) {
                                                setAddGuarantor({index: index, show: true})
                                            } else {
                                                handle_change(index, 'client_id', value as number)
                                            }
                                        }}
                                        search={(query) => {
                                            if (query.trim().length >= 3) {
                                                query_clients(query)
                                            }
                                        }}
                                        title="Guarantor Name" name="" value={guarantor.client_id} loading={params.searchingClients}
                                        options={[
                                            {text: "Add a new guarantor", value: -1}, {text: "Loan has no guarantor", value: 0},
                                            ...params.loanProps.clients.map((client) =>
                                                ({text: `${client.client_name} (+${client.main_contact})`, value: client.client_id}))
                                        ]}/>

                                    <MySelect
                                        change={(value: select_type) => {
                                            handle_change(index, 'relationship', value as string)
                                        }}
                                        title="Relationship with Guarantor" name="relationship" value={guarantor.relationship}
                                        options={Utils.guarantor_relationships}/>

                                    <MyInput
                                        placeholder="Monthly income (UGX)" title="Monthly Income" name=""
                                        change={(name, value) => {
                                            handle_change(index, 'monthly_income', value)
                                        }}
                                        value={guarantor.monthly_income}/>
                                </div>
                            )
                        })
                    }
                    <div className="col-12 col-md-4 offset-md-4 p-1">
                        <Button fluid size='tiny' primary onClick={save_guarantors} content='Update Guarantors'
                                icon='save' labelPosition={"left"}/>
                    </div>
                </div>
            </Modal>
        </>
    )
}
