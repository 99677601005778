import {LoanInfo} from "../../ShowLoan";
import {ToastsStore} from "react-toasts";
import {Req} from "../../../../../utils/Req";
import Utils, {ConfirmAction, initial_confirm} from "../../../../../utils/Utils";
import React from "react";
import {Loading} from "../../../../../utils/Components";
import {Confirm, Icon, Table} from "semantic-ui-react";

export interface LoanReschedule {
    schedule_date: string
    reschedule_principal: number
    reschedule_interest: number
    interest_added: number
    principal_added: number
    time_created: string
}

export default function LoanReschedules(params: { loanInfo: LoanInfo, setLoanInfo: (loanInfo: LoanInfo) => void }) {
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)
    const [loading, setLoading] = React.useState({message: "", show: false})

    const reschedule_loan = () => {
        const system_error = () => {
            ToastsStore.error(`Error occurred while modifying loan, please retry`)
        }
        setConfirm({
            ...confirm, open: true, content: "Are you sure you want to reschedule this loan",
            onCancel: () => setConfirm({...confirm, open: false}),
            onConfirm: () => {
                setConfirm({...confirm, open: false})
                setLoading({message: "Interesting loan, please wait", show: true})

                Req.reschedule_loan({loan_id: params.loanInfo.loan_id})
                    .then((response) => {
                        setLoading({message: "", show: false})
                        if (response.data.hasOwnProperty("code")) {
                            switch (response.data.code as number) {
                                case 0:
                                    ToastsStore.error(`Loan not found`)
                                    break
                                case 1:
                                    ToastsStore.success(`Successfully rescheduled loan`)
                                    params.setLoanInfo(Utils.format_loan_data(response))
                                    break
                                case 2:
                                    ToastsStore.error(`Not fit for rescheduling`)
                                    break
                                default:
                                    system_error()
                                    break
                            }
                        } else {
                            system_error()
                        }
                    })
                    .catch((error: any) => {
                        console.error(error)
                        setLoading({message: "", show: false})
                        system_error()
                    })
            }
        })
    }

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} centered={false} size='mini'
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} content={confirm.content}/>

            {
                params.loanInfo.can_reschedule === 1 &&
                <div className="floating_button" onClick={reschedule_loan}>
                    <Icon name="retweet"/>
                </div>
            }


            <div className="p-1 h-100">
                <div className="table_container full_height">
                    <Table celled striped compact size='small' inverted color='grey' selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                <Table.HeaderCell className="time_row">Date Rescheduled</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Principal Before</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Interest Before</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Principal Added</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Interest Added</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Total Added</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                params.loanInfo.others.reschedules
                                    .map((info, index) =>
                                        <Table.Row key={info.schedule_date}>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                            <Table.Cell className="time_row">{Utils.localise_date(info.time_created)}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{Utils.comma_number(info.reschedule_principal)}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{Utils.comma_number(info.reschedule_interest)}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{Utils.comma_number(info.principal_added)}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{Utils.comma_number(info.interest_added)}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>
                                                {Utils.comma_number(info.principal_added + info.interest_added)}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                            }
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    )
}
