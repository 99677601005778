import React from "react"
import Utils, {ConfirmAction, initial_confirm} from "../../../utils/Utils";
import {MyDateInput, MyInput, MySelect, select_type, SelectData} from "../../../utils/Components";
import {Button, Confirm, Modal, Table} from "semantic-ui-react";
import {Req} from "../../../utils/Req"
import {useAppSelector} from "../../../store/hooks";
import {getStaff} from "../../../store/slices/userSlice";
import {ToastsStore} from "react-toasts";
import {Rights} from "../../admin/users/rights/AccessRights";
import moment from "moment";

interface Transaction {
    type: string
    amount: number
}

interface Account {
    account: string
    date: string
    list: Transaction[]
    office_name: string
    state: "+" | "-"
    total: number
    username: string
    balance: number
}

interface Row {
    span?: number
    state?: "+" | "-"
    date?: string
    username?: string
    account?: string
    type: string
    amount: number
    total?: number
    balance?: number
}

export default function CashAtHand() {
    const user = useAppSelector(getStaff)
    const [loading, setLoading] = React.useState(false)
    const [closing, setClosing] = React.useState(false)
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)

    const [search, setSearch] = React.useState<{ date: string, user_id: number, office_id: number }>({
        date: Utils.today(), user_id: user.staff.user_id, office_id: user.staff.office_id
    })
    const handle_change = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const [offices, setOffices] = React.useState<Array<SelectData>>([])
    const [users, setUsers] = React.useState<Array<SelectData>>([])

    const [accounts, setAccounts] = React.useState<Array<Account>>([])
    const [rows, setRows] = React.useState<Array<Row>>([])

    const [showSummary, setShowSummary] = React.useState(false)
    const [summary, setSummary] = React.useState({income: 0, expenses: 0, balance: 0, allocated: 0})

    const get_user_transactions = () => {
        setLoading(true)
        Req.get_user_transactions({transaction_date: search.date, transaction_user: search.user_id, office_id: search.office_id})
            .then((response) => {
                setLoading(false)
                if (response.data.hasOwnProperty("transactions")) {
                    setAccounts(response.data.transactions);
                    setSummary({
                        allocated: response.data['allocated'], balance: response.data['balance'],
                        expenses: response.data['total_expense'], income: response.data['total_income']
                    })
                } else {
                    ToastsStore.error("Could not get user transactions")
                }
            })
            .catch(() => {
                setLoading(false)
                ToastsStore.error("Could not get user transactions")
            })
    }

    const close_user_transaction = () => {
        const user_name = Utils.get_user(users, search.user_id).text

        setConfirm({
            ...confirm, open: true,
            content:
                `Are you sure you want to proceed with closing the transactions of "${user_name}"? 
                Note that the "${user_name}" shall not be able to make any further transactions for the rest of the day.`,
            onConfirm: () => {
                setConfirm({...confirm, open: false})
                setClosing(true)

                Req.close_user_transactions({transaction_date: search.date, user_id: search.user_id})
                    .then((response) => {
                        setClosing(false)
                        if (response.data.hasOwnProperty("code")) {
                            const code = response.data.code
                            if (code === 1) {
                                ToastsStore.success("User transactions have been successfully closed")
                            } else if (code === 2) {
                                ToastsStore.error("Could not find till allocated to user")
                            } else if (code === 3) {
                                ToastsStore.error("User transactions for this date were already closed")
                            }
                        } else {
                            ToastsStore.error("Could not close user transactions, please retry")
                        }
                    })
                    .catch(() => {
                        setClosing(false)
                        ToastsStore.error("Could not close user transactions, please retry")
                    })
            },
            onCancel: () => setConfirm({...confirm, open: false})
        })
    }

    React.useEffect(() => {
        let _rows: Row[] = [];
        accounts.forEach((account) => {
            account.list.forEach((transaction, index) => {
                _rows = [
                    ..._rows,
                    index === 0 ?
                        {
                            span: account.list.length,
                            state: account.state,
                            date: account.date,
                            username: account.username + (search.office_id === 0 ? `(${account.office_name})` : ''),
                            account: account.account,
                            type: transaction.type,
                            amount: transaction.amount,
                            total: account.total,
                            balance: account.balance,
                        }
                        : {
                            type: transaction.type,
                            amount: transaction.amount,
                        }
                ]
            })
        })
        console.log(_rows)
        setRows(_rows)
    }, [accounts])

    React.useEffect(() => {
        setUsers([])
        setSearch({...search, user_id: 0})
        if (search.office_id === 0) {
            setUsers(Utils.get_users([], {text: "View all users", value: 0}))
        } else {
            Req.get_office_users({office_id: search.office_id})
                .then((response) => {
                    if (response.data.hasOwnProperty("users")) {
                        setUsers(Utils.get_users(response.data['users'], {text: "View all accountants", value: 0}))
                        if (search.office_id === user.staff.office_id) {
                            setSearch({...search, user_id: user.staff.user_id})
                        }
                    }
                })
        }
    }, [search.office_id])

    React.useEffect(() => {
        Req.get_offices()
            .then((response) => {
                if (response.data.hasOwnProperty("offices")) {
                    const select_info = Utils.get_accessible_office(response.data.offices, user.roles.user_roles, Rights.transaction_verification)
                    setOffices(select_info.select)
                }
            })
        get_user_transactions()
    }, [])

    return (
        <>
            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm}
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} centered={false}
                     size='mini' content={confirm.content} dimmer="blurring"/>

            <>
                <div className="content_bar">
                    <div className="search_bar">
                        <div>
                            <MySelect
                                change={(value: select_type) => handle_change('office_id', (value as number))}
                                title="" name="office_id" value={search.office_id} options={offices}
                                disabled={!Rights.has_right(user.roles.user_roles, Rights.transaction_verification)}/>
                        </div>
                        <div>
                            <MySelect
                                change={(value: select_type) => handle_change('user_id', (value as number))}
                                title="" name="user_id" value={search.user_id} options={users}
                                disabled={!Rights.has_right(user.roles.user_roles, Rights.transaction_verification)}/>
                        </div>
                        <div>
                            <MyDateInput
                                title="" value={search.date} name="date" placeholder="Select calendar date"
                                change={handle_change}/>
                        </div>
                    </div>
                    <div className="button_bar">
                        <Button floated='right' size='mini' icon='search' disabled={loading} loading={loading}
                                onClick={get_user_transactions} content="Search" primary labelPosition="left"/>

                        <Button floated='right' size='mini' disabled={loading || closing}
                                onClick={() => setShowSummary(true)} content="More Info"
                                labelPosition="left" primary icon="info circle"/>
                    </div>
                </div>

                <div className="table_container container_no_footer">
                    <Table celled striped compact size='small' inverted structured color='grey' selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '100px'}}>Time</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '220px'}}>Cashier Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '250px'}}>Account Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '200px'}}>Transaction Reason</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}>Transaction</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}>Total Amount</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '130px'}}>Balance After</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                rows.map((row, index) => {
                                    return row.span === undefined ?
                                        <Table.Row key={index}>
                                            <Table.Cell style={{width: '200px'}} rowSpan={1}>{row.type}</Table.Cell>
                                            <Table.Cell style={{width: '130px'}} rowSpan={1}>{Utils.comma_number(row.amount)}</Table.Cell>
                                        </Table.Row>
                                        :
                                        <Table.Row key={index}>
                                            <Table.Cell style={{width: '100px'}} rowSpan={row.span}>
                                                {moment(row.date, 'YYYY-MM-DD hh:mm:ss').format("LTS")}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '220px'}} rowSpan={row.span}>{row.username}</Table.Cell>
                                            <Table.Cell style={{width: '250px'}} rowSpan={row.span}>{row.account}</Table.Cell>
                                            <Table.Cell style={{width: '200px'}} rowSpan={1}>{row.type}</Table.Cell>
                                            <Table.Cell style={{width: '130px'}} rowSpan={1}>{Utils.comma_number(row.amount)}</Table.Cell>
                                            <Table.Cell style={{width: '130px', color: row.state === "+" ? '#045A04' : '#890C0C', fontWeight: '600'}}
                                                        rowSpan={row.span}>
                                                {Utils.comma_number(row.total as number)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '130px', fontWeight: '700'}} rowSpan={row.span}>
                                                {Utils.comma_number(row.balance as number)}
                                            </Table.Cell>
                                        </Table.Row>
                                })
                            }
                        </Table.Body>
                    </Table>
                </div>
            </>

            <Modal open={showSummary} onClose={() => setShowSummary(false)} dimmer="blurring"
                   basic size='mini' centered={false}>
                <div className="modal_div p-2">
                    <div>
                        <MyInput placeholder="Total Allocated" title="Total Till Allocation"
                                 name="allocated" value={Utils.comma_number(summary.allocated)}/>

                        <MyInput placeholder="Total Expenses" title="Total Loan Disbursement"
                                 name="principal" value={Utils.comma_number(summary.expenses)}/>

                        <MyInput placeholder="Total Income" title="Total Cash Collected"
                                 name="interest_expected" value={Utils.comma_number(summary.income)}/>

                        <MyInput placeholder="Cash At Hand" title="Cash At Hand" style={{}}
                                 name="total" value={Utils.comma_number(summary.balance)}/>
                    </div>

                    {
                        Rights.has_right(user.roles.user_roles, Rights.transaction_verification) &&
                        <div className="row mx-0 mt-3">
                            <div className="col-8 offset-2 p-0">
                                <Button size='mini' disabled={search.user_id === 0 || closing} loading={closing} content="Close Day" fluid
                                        onClick={close_user_transaction} primary labelPosition='left' icon="moon outline"/>
                            </div>
                        </div>
                    }
                </div>
            </Modal>
        </>
    )
}
