import axios from 'axios'
import {InputFile} from "./Utils";
import {intervals, rate_types} from "../modules/admin/loans/LoanTypes"

export class Req {
    //static BASE_URL = "http://127.0.0.1:8006/"
    static BASE_URL = "https://loans.wilsofts.com/"
    //static BASE_URL = "http://192.168.100.117:8006"

    static CLIENT: "local" | "sokoto" | "tonish" | "armour" | 'panny' = "tonish"

    static post_params(data: any) {
        const form = new FormData();
        Object.entries(data).forEach(([prop, val]) => {
            if (val !== null) {
                if (val instanceof InputFile) {
                    if (val.file !== undefined) {
                        form.append(prop, val.file as File)
                    }
                } else {
                    form.append(prop, val as string)
                }
            }
        })
        return form
    }

    /*dashboards*/
    static get_loan_summary(params: { min_date: string, max_date: string, branch_id: number }) {
        return axios({method: 'get', url: 'loans/dashboard/summary/loans', params: params});
    }

    static get_branch_summary(params: { min_date: string, max_date: string, branch_id: number }) {
        return axios({method: 'get', url: 'loans/dashboard/summary/branch', params: params});
    }

    static get_branches_summary(params: { min_date: string, max_date: string, branches: string }) {
        return axios({method: 'get', url: 'loans/dashboard/summary/branches', params: params});
    }

    /*system calendar*/
    static get_calendar(params: { office_id: number, min_date: string, max_date: string }) {
        return axios({method: 'get', url: 'calendar/get', params: params});
    }

    static pdf_calendar(params: { office_id: number, min_date: string, max_date: string }) {
        return axios({method: 'get', url: 'calendar/pdf', params: params});
    }

    static daily_summary(params: {}) {
        return axios({method: 'get', url: 'calendar/daily', params: params});
    }

    static pdf_daily_summary(params: {}) {
        return axios({method: 'get', url: 'calendar/pdf/daily', params: params});
    }

    /*branch management*/
    static save_office(office: { office_id: number, office_name: string, office_location: string, office_manager: number, office_contact: string }) {
        return axios({method: 'post', url: 'system/offices/save', data: this.post_params(office)});
    }

    static get_offices() {
        return axios({method: 'get', url: 'system/offices/get'});
    }

    static get_branches_users() {
        return axios({method: 'get', url: 'system/offices/combo'});
    }

    /*user management*/
    static update_fcm_token(params: { fcm_token: string }) {
        return axios({method: 'post', url: 'users/auth/fcm', data: this.post_params(params)});
    }

    static login_user(data: { credential: string, login_password: string, fetch_data: 1 | 0, fcm_token: string }) {
        return axios({method: 'post', url: 'users/auth/login', data: this.post_params(data)});
    }

    static logout_user() {
        return axios({method: 'post', url: 'users/auth/logout'});
    }

    static change_password(data: { credential: string, new_password: string, current_password: string }, operation?: string) {
        return axios({
            method: 'post', url: `${operation === undefined ? 'users/password/change' : 'users/password/reset'}`,
            data: this.post_params(data)
        });
    }

    static get_office_users(params: { office_id: number }) {
        return axios({method: 'get', url: 'users/get/branch', params: params});
    }

    static save_user(user: {
        user_id: number, office_id: number, first_name: string, last_name: string, user_name: string, user_email: string,
        user_gender: 'Male' | 'Female' | 'Other', main_contact: string, other_contact: string, user_avatar: InputFile | null,
        user_status: 'active' | 'inactive'
    }) {
        return axios({method: 'post', url: 'users/save', data: this.post_params(user)});
    }

    static get_users(params: { name: string, branch: number, status: string }) {
        return axios({method: 'get', url: 'users/get/search', params: params});
    }

    static update_user_rights(params: { access_rights: string, user_id: number }) {
        return axios({method: 'post', url: 'users/rights/update', data: this.post_params(params)});
    }

    static update_report_rights(params: { report_rights: string, user_id: number }) {
        return axios({method: 'post', url: 'users/rights/report', data: this.post_params(params)});
    }

    /*loan listings*/
    static get_loans_listing_complete(params: { pages: number, page: number, name: string, loan_id: string, branches: string }) {
        return axios({method: 'get', url: 'loans/listing/complete', params: params});
    }

    static get_loans_listing_portfolio(params: {
        branches: string, name: string, pages: number, page: number,
        status: 'active' | 'complete' | 'all' | 'extended' | 'deleted' | 'modified' | 'monthly',
        loan_id?: string, loan_type?: number, user_id?: number, month?: number, year?: number
    }) {
        return axios({method: 'get', url: 'loans/listing/portfolio', params: params});
    }

    static get_loans_listing_due(params: { name: string, branches: string, min_date?: string, max_date?: string, period: number, loan_id: string }) {
        return axios({method: 'get', url: 'loans/listing/due', params: params});
    }

    /*loan management*/
    static get_loan_portfolios() {
        return axios({method: 'get', url: 'loans/portfolio'});
    }

    static get_loan_actions(params: { branches: string, loan_status: string }) {
        return axios({method: 'get', url: 'loans/actionable', params: params});
    }

    static get_guarantor_loan_info(params: { client_id: number }) {
        return axios({method: 'get', url: 'loans/info/guarantor', params: params});
    }

    static get_client_loan_info(params: { client_id: number }) {
        return axios({method: 'get', url: 'loans/info/client', params: params});
    }

    static save_loan(data: FormData) {
        return axios({method: 'post', url: 'loans/store/loan', data: data});
    }

    static save_security(data: FormData) {
        return axios({method: 'post', url: 'loans/store/security', data: data});
    }

    static get_loan_info(params: { loan_id: number }) {
        return axios({method: 'get', url: 'loans/details', params: params});
    }

    static approve_reject_verify(data: { loan_status: 'rejected' | 'accepted' | 'verified', loan_id: number, branch_id: number }) {
        return axios({method: 'post', url: 'loans/approve_reject_verify', data: this.post_params(data)});
    }

    static disburse_loan(data: { schedules: string, start_date: string, due_date: string, loan_id: number, transactions: string }) {
        return axios({method: 'post', url: 'loans/disburse', data: this.post_params(data)});
    }

    static disburse_loan_info(data: { loan_id: number }) {
        return axios({method: 'post', url: 'loans/disburse/init', data: this.post_params(data)});
    }

    static calculate_loan_schedule(data: {
        loan_principal: number, loan_period: number, loan_rate: number, loan_interval: number,
        rate_type: 'simple' | 'compound' | 'simple_interest' | '', start_date: string
    }) {
        return axios({method: 'post', url: 'loans/schedule/calculate', data: this.post_params(data)});
    }

    static get_payment_schedule(params: { loan_id: number }) {
        return axios({method: 'get', url: 'loans/schedule/print', params: params});
    }

    static get_payment_info(params: { loan_id: number }) {
        return axios({method: 'get', url: 'loans/info/loan', params: params});
    }

    static extend_loan(
        params: {
            loan_id: number, extension_period: number, extension_reason: string, interest_mode: string,
            payment_start_date: string, extension_principal: number, extension_interest: number, extension_rate: number
        }) {
        return axios({method: 'post', url: 'loans/extend', data: this.post_params(params)});
    }

    static add_loan_fee(params: { fee_description: string, fee_amount: string, date_created: string, loan_id: number }) {
        return axios({method: 'post', url: 'loans/add_fee', data: this.post_params(params)});
    }

    static deduct_interest(params: { amount_removed: string, reason_removed: string, loan_id: number }) {
        return axios({method: 'post', url: 'loans/deduct_interest', data: this.post_params(params)});
    }

    static add_penalty(params: { penalty_reason: string, penalty_amount: string, date_penalised: string, loan_id: number }) {
        return axios({method: 'post', url: 'loans/add_penalty', data: this.post_params(params)});
    }

    static reschedule_loan(params: { loan_id: number }) {
        return axios({method: 'post', url: 'loans/reschedule', data: this.post_params(params)});
    }

    /*loan modification*/
    static get_modifiable_loans(params: { name: string, loan_id: string }) {
        return axios({method: 'get', url: 'loans/modifiable/search', params: params});
    }

    static get_modifiable_loan(params: { loan_id: number }) {
        return axios({method: 'get', url: 'loans/modifiable/info', params: params});
    }

    static modify_loan(data: {
        loan_id: number, client_id: number, inspector_id: number, loan_period: number, loan_principal: number, loan_rate: number,
        loan_type: number, office_id: number, payment_interval: intervals, start_date: string, rate_type: rate_types, charges: string,
        date_disbursed: string, payments: string, reschedules: string, securities: string, extensions: string, guarantors: string
    }) {
        return axios({method: 'post', url: 'loans/modifiable/save', data: this.post_params(data)});
    }

    static delete_loan(data: { loan_id: number, delete_reason: string }) {
        return axios({method: 'post', url: 'loans/modifiable/delete', data: this.post_params(data)});
    }

    static clear_loan(data: { loan_id: number }) {
        return axios({method: 'post', url: 'loans/modifiable/clear', data: this.post_params(data)});
    }

    /*inspection reports*/
    static get_inspection_reports(
        params: { min_date: string, max_date: string, client_name: string }
    ) {
        return axios({method: 'get', url: 'loans/inspections/get', params: params});
    }

    static save_inspection_report(data: {
        inspection_id: number, inspected_by: number, user_name: string, date_inspected: string, client_name: string, client_contact: string,
        work_address: string, residential_address: string, marital_status: string, market_value: string, job_period: string,
        weekly_earning: string | number, weekly_savings: string | number, payment_capacity: number, guarantor_commitment: number,
        self_employed: number, rider_remittance: string | number, surety: string, properties: string
    }) {
        return axios({method: 'post', url: 'loans/inspections/save', data: this.post_params(data)});
    }

    /*loan payments*/
    static get_payment_receipt(params: { payment_id: number }) {
        return axios({method: 'get', url: 'payments/receipt', params: params});
    }

    static reverse_payment(data: { payment_id: number, loan_id: number }) {
        return axios({method: 'post', url: 'payments/reverse', data: this.post_params(data)});
    }

    static get_user_transactions(params: { transaction_date: string, transaction_user: number, office_id: number }) {
        return axios({method: 'post', url: 'payments/user', data: this.post_params(params)});
    }

    static close_user_transactions(params: { transaction_date: string, user_id: number }) {
        return axios({method: 'post', url: 'payments/close', data: this.post_params(params)});
    }

    static get_expected_payments(params: { name: string, branches: string, min_date?: string, max_date?: string, period: number, loan_id: string }) {
        return axios({method: 'get', url: 'payments/arrears', params: params});
    }

    static get_loan_payment_data(params: { loan_id: number, office_id: number }) {
        return axios({method: 'get', url: 'payments/initialise', params: params});
    }

    static make_payment(data: { loan_id: number, payer_name: string, payer_contact: string, total_paid: number, date_paid: string }) {
        return axios({method: 'post', url: 'payments/save', data: this.post_params(data)});
    }

    /*till allocations*/
    static save_allocation(
        data: { allocation_id: number, office_id: number, amount_allocated: number, allocation_date: string, user_id: number }) {
        return axios({method: 'post', url: 'allocations/save', data: this.post_params(data)});
    }

    static get_allocations(params: { min_date: string, max_date: string, office_id: number, user_id: number }) {
        return axios({method: 'get', url: 'allocations/get', params: params});
    }

    /*client requests*/
    static save_client(data: FormData) {
        const length = 5 * 1000 * 1024
        return axios({method: 'post', url: 'clients/save/account', data: data, maxBodyLength: Infinity, maxContentLength: Infinity});
    }

    static save_account_fee(client: { client_id: number, payments: string }) {
        return axios({method: 'post', url: 'clients/save/fees', data: this.post_params(client)});
    }

    static get_client(params: { client_id: number }) {
        return axios({method: 'get', url: 'clients/get/client', params: params});
    }

    static get_clients(params: { page: number, pages: number, client_name: string, branches: string, status: 'pending' | 'paid' | 'all' }) {
        return axios({method: 'get', url: 'clients/get/search', params: params});
    }

    static get_client_summary(params: { client_id: number }) {
        return axios({method: 'get', url: 'clients/summary', params: params});
    }

    static search_clients(params: { query: string, clients: string }) {
        return axios({method: 'get', url: 'clients/get/query', params: params});
    }

    /*system management*/
    static save_loan_type(loanType: {
        type_name: string, type_desc: string, loan_rate: string, start_days: 1 | 7 | 14 | 30, payment_interval: 1 | 7 | 14 | 30,
        security_type: 'none' | 'guarantor' | 'collateral' | 'either' | 'both', loan_status: 'active' | 'de-active', type_id: number
    }) {
        return axios({method: 'post', url: 'system/loan_types/save/info', data: this.post_params(loanType)});
    }

    static save_loan_charges(loanType: { charges: string, type_id: number, fee_name: string, fee_description: string, charge_id: number }) {
        return axios({method: 'post', url: 'system/loan_types/save/charges', data: this.post_params(loanType)});
    }

    static get_loan_types() {
        return axios({method: 'get', url: 'system/loan_types/get', params: {operation: 'get_loan_types'}});
    }

    /*accounts management*/
    static save_expenses(
        data: {
            office_id: number, expense_name: string, expense_description: string,
            expense_amount: number, expense_receipt: InputFile | null, expense_date: string, expense_id: number
        }) {
        return axios({method: 'post', url: 'accounts/expenses/save', data: this.post_params(data)});
    }

    static initialise_expenses() {
        return axios({method: 'get', url: 'accounts/expenses/init',});
    }

    static get_expenses(params: { pages: number, page: number, min_date: string, max_date: string, branches: string }) {
        return axios({method: 'get', url: 'accounts/expenses/search', params: params});
    }

    /*bank accounts*/
    static get_bank_accounts() {
        return axios({method: 'get', url: 'accounts/bank_accounts/search'});
    }

    static save_bank_account(data: { account_id: number, account_name: string, account_no: string, bank_name: string }) {
        return axios({method: 'post', url: 'accounts/bank_accounts/save', data: this.post_params(data)});
    }

    /*bank deposits*/
    static save_bank_deposits(
        data: {
            office_id: number, account_id: number, user_id: number, amount_deposited: number,
            deposit_receipt: InputFile | null, date_deposited: string, deposit_id: number
        }) {
        return axios({method: 'post', url: 'accounts/bank_deposits/save', data: this.post_params(data)});
    }

    static get_bank_deposits(params: { pages: number, page: number, min_date: string, max_date: string, branches: string }) {
        return axios({method: 'get', url: 'accounts/bank_deposits/search', params: params});
    }

    /*bank withdrawals*/
    static save_bank_withdrawal(
        data: {
            account_id: number, office_id: number, withdrawal_id: number, amount_withdrawn: number,
            withdrawal_reason: string, withdrawal_receipt: InputFile | null, date_withdrawn: string
        }) {
        return axios({method: 'post', url: 'accounts/bank_withdrawals/save', data: this.post_params(data)});
    }

    static get_bank_withdrawals(params: { pages: number, page: number, min_date: string, max_date: string, branches: string }) {
        return axios({method: 'get', url: 'accounts/bank_withdrawals/search', params: params});
    }

    /*assets*/
    static save_asset(params: {
        office_id: number, asset_name: string, asset_description: string, buying_price: number | string, asset_no: string, asset_id: number,
        appreciation_rate: number | string, depreciation_rate: number | string, date_acquired: string, payment_method: "cash" | "bank" | ""
    }) {
        return axios({method: 'post', url: 'accounts/assets/save', data: this.post_params(params)});
    }

    static get_assets() {
        return axios({method: 'get', url: 'accounts/assets/search'});
    }

    /*salary scales*/
    static save_salary_scale(params: {
        user_id: number, salary: number, allowances: number, bank_name: string, account_number: string, account_name: string, scale_id: number
    }) {
        return axios({method: 'post', url: 'accounts/salary/scales', data: this.post_params(params)});
    }

    static get_salary_scales() {
        return axios({method: 'get', url: 'accounts/salary/scales'});
    }

    static initialise_salary_data() {
        return axios({method: 'get', url: 'accounts/salary/init'});
    }

    static get_salary_schedules(params: { payment_year: number, payment_month: string, user_id: number, office_id: number }) {
        return axios({method: 'get', url: 'accounts/salary/schedules', params: params});
    }

    static get_salary_payments(params: { min_date: string, max_date: string, user_id: number }) {
        return axios({method: 'get', url: 'accounts/salary/payments', params: params});
    }

    static save_salary_payment(params: {
        user_id: number, reference_no: string, salary_arrears: number,
        allowance_arrears: number, allowance_paid: number, salary_paid: number, date_paid: string
    }) {
        return axios({method: 'post', url: 'accounts/salary/payment', data: this.post_params(params)});
    }

    /*system creditors*/
    static save_system_creditor(params: { creditor_id: number, creditor_name: string, creditor_email: string, creditor_contact: string }) {
        return axios({method: 'post', url: 'creditors/entity/save', data: this.post_params(params)});
    }

    static get_system_creditor(params: { loan_status: 'active' | 'deleted' | 'complete' | 'overdue', creditor_name: string }) {
        return axios({method: 'get', url: 'creditors/entity/search', params: params});
    }

    static get_creditor_loan(params: { loan_id: number }) {
        return axios({method: 'get', url: 'creditors/loan/info', params: params});
    }

    static save_creditor_loan(params: {
        creditor_id: number, loan_principal: number, loan_rate: number, loan_id: number,
        loan_period: number, start_date: string, due_date: string, date_acquired: string
    }) {
        return axios({method: 'post', url: 'creditors/loan/save', data: this.post_params(params)});
    }

    static pay_creditor_loan(params: {
        loan_id: number, time_paid: string, principal_paid: number, interest_paid: number, user_id: number,
        payment_method: string, reference_no: string
    }) {
        return axios({method: 'post', url: 'creditors/loan/pay', data: this.post_params(params)});
    }

    static extend_creditor_loan(params: { loan_id: number, extension_period: number, payment_start_date: string, extension_rate: number }) {
        return axios({method: 'post', url: 'creditors/loan/extend', data: this.post_params(params)});
    }

    static delete_creditor_loan(params: { loan_id: number }) {
        return axios({method: 'post', url: 'creditors/loan/delete', data: this.post_params(params)});
    }

    /*reports*/
    static process_report(params: { criteria: string }, type: { action: string, type: 'pdf' | 'table' | 'excel' }) {
        return axios({method: 'get', url: `report/generate/${type.action}/${type.type}`, params: params});
    }

    static init_reports(params: { type: string }) {
        return axios({method: 'get', url: 'report/initialise', params: params});
    }
}

export interface Page {
    page: number
    pages: number
    limit: number
}

export interface Fetch {
    start: number
    limit: number
    total: number
    page: number
    pages: number
    load?: boolean
}
