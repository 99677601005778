import React from "react";
import Utils, {ConfirmAction, initial_confirm,} from "../../../utils/Utils";
import {Loading, loading_params, LoadingParams, MyDateInput, MyInput, MySelect, select_type, SelectData} from "../../../utils/Components";
import {Button, Confirm, Table} from "semantic-ui-react";
import {Req} from "../../../utils/Req"
import {useAppSelector} from "../../../store/hooks";
import {getStaff} from "../../../store/slices/userSlice";
import {ToastsStore} from "react-toasts";
import {BranchUser} from "../../../nav/SideBar";

interface Allocation {
    allocation_id: number
    accountant_id: number
    accountant_name: string
    office_name: string
    office_id: number
    user_id: number
    user_name: string
    allocation_date: string
    time_allocated: string
    amount_allocated: number | string
}

export default function TillAllocation(params: { branches: { select: SelectData[], selected: number[] }, branchUser: BranchUser }) {
    const user = useAppSelector(getStaff)

    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)
    const initial_allocation: Allocation = {
        allocation_date: Utils.today(), allocation_id: 0, amount_allocated: "", office_name: "", time_allocated: "", office_id: 0,
        user_id: user.staff.user_id, user_name: `${user.staff.last_name} ${user.staff.first_name}`,
        accountant_id: user.staff.user_id, accountant_name: `${user.staff.last_name} ${user.staff.first_name}`
    }

    const [loading, setLoading] = React.useState<LoadingParams>(loading_params)
    const [allocations, setAllocations] = React.useState <Array<Allocation>>([])
    const [allocation, setAllocation] = React.useState <Allocation>(initial_allocation)
    const handle_change = (name: string, value: string | number) => {
        setAllocation({...allocation, [name]: value})
    }

    const [search, setSearch] = React.useState({
        min_date: Utils.weekly_date().sunday, max_date: Utils.today(), office_id: 0, user_id: 0
    })
    const handle_search = (name: string, value: string | number) => {
        setSearch({...search, [name]: value})
    }

    const load_allocations = () => {
        if (search.office_id === 0) {
            ToastsStore.info("Select an allocation branch")
            return
        }

        setLoading({show: true, text: 'Loading allocations, please wait'})
        Req.get_allocations({
            max_date: search.max_date, min_date: search.min_date, office_id: search.office_id, user_id: search.user_id
        })
            .then((response) => {
                setLoading(loading_params)
                if (response.data.hasOwnProperty("allocations")) {
                    setAllocations(response.data.allocations)
                } else {
                    ToastsStore.error("Could not load allocations, please retry")
                }
            })
            .catch(() => {
                ToastsStore.error("Could not load allocations, please retry")
            })
    }

    const clear_allocations = () => {
        setAllocation(initial_allocation)
    }

    const save_allocations = () => {
        const continue_allocation = () => {
            setLoading({show: true, text: "Saving till allocation, please wait"})

            Req.save_allocation({
                allocation_date: allocation.allocation_date, allocation_id: allocation.allocation_id, user_id: allocation.user_id,
                amount_allocated: parseInt(allocation.amount_allocated as string), office_id: allocation.office_id
            })
                .then((response) => {
                    setLoading(loading_params)
                    if (response.data.hasOwnProperty("code")) {
                        switch (response.data['code']) {
                            case 1:
                                const new_allocation = {
                                    ...allocation, allocation_id: response.data.allocation_id, time_allocated: response.data.time_allocated
                                }
                                if (allocation.allocation_id === 0) {
                                    setAllocations(allocations => [...allocations, new_allocation])
                                } else {
                                    setAllocations(allocations.map((_allocate) =>
                                        _allocate.allocation_id === allocation.allocation_id ? new_allocation : _allocate)
                                    )
                                }
                                setAllocation(new_allocation)
                                ToastsStore.success("Successfully saved allocation")
                                break
                            case 2:
                                ToastsStore.error("Allocation to be modified not found")
                                break
                            case 3:
                                ToastsStore.error(response.data.msg)
                                break
                            case 4:
                                ToastsStore.error(response.data.msg)
                                break
                            case 5:
                                ToastsStore.error('User till was already closed, you cannot modify this allocation')
                                break
                        }
                    } else {
                        ToastsStore.error("Could not allocate, please retry")
                    }
                })
                .catch(() => {
                    setLoading(loading_params)
                    ToastsStore.error("Could not allocate, please retry")
                })
        }

        if (allocation.office_id === 0) {
            ToastsStore.error("Select an allocation office")
        } else if (allocation.user_id === 0) {
            ToastsStore.error("Select a user to allocate money to")
        } else if (!Utils.is_valid_number(allocation.amount_allocated as string)) {
            ToastsStore.error("Enter a valid amount allocated")
        } else {
            setConfirm({
                ...confirm, open: true,
                content: `Are you sure you want to continue allocation ${Utils.comma_number(allocation.amount_allocated as number)} to ${allocation.user_name}`,
                onCancel: () => setConfirm({...confirm, open: false}),
                onConfirm: () => {
                    setConfirm({...confirm, open: false})
                    continue_allocation()
                }
            })
        }
    }

    return (
        <>
            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} centered={false} size='mini'
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} content={confirm.content}/>

            <Loading show={loading.show} text={loading.text} reload={loading.reload} error={loading.error}/>

            <div className="row m-0 h-100">
                <div className="col-12 col-md-8 p-1 h-100">
                    <div className="content_bar">
                        <div className="search_bar">
                            <div>
                                <MySelect
                                    change={(value: select_type) => handle_search('office_id', (value as number))}
                                    value={search.office_id} options={[{text: 'Select a branch', value: 0}, ...params.branches.select]}/>
                            </div>
                            <div>
                                <MySelect
                                    change={(value: select_type) => handle_search('user_id', (value as number))} value={search.user_id}
                                    options={[
                                        {text: 'All users', value: 0},
                                        ...params.branchUser.users
                                            .filter((user) => user.office_id === search.office_id)
                                            .map((user) => ({text: user.user_name, value: user.user_id}))
                                    ]}/>
                            </div>
                            <div>
                                <MyDateInput
                                    value={search.min_date} name="min_date" maxDate={search.max_date}
                                    placeholder="Minimum payment date" change={handle_search}/>
                            </div>
                            <div>
                                <MyDateInput
                                    value={search.max_date} name="max_date" maxDate={Utils.today()}
                                    minDate={search.min_date} placeholder="Maximum payment date" change={handle_search}/>
                            </div>
                        </div>

                        <div className="button_bar">
                            <Button primary size='mini' onClick={load_allocations} icon='search' labelPosition='left' content='Search'/>
                        </div>
                    </div>

                    <div className="table_container container_no_footer">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}}>No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Allocation Date</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '200px'}}>Allocated To</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '200px'}}>Allocated By</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '130px'}}>Allocation</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    allocations.map((allocation, index) =>
                                        <Table.Row key={allocation.allocation_id} onClick={() => setAllocation(allocation)}>
                                            <Table.Cell style={{width: '50px'}}>{Utils.row_number(index)}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>
                                                {Utils.localise_date(allocation.allocation_date)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '180px'}}>{allocation.user_name}</Table.Cell>
                                            <Table.Cell style={{width: '180px'}}>{allocation.accountant_name}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>
                                                {Utils.comma_number(allocation.amount_allocated as number)}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                <div className="col-12 col-md-4 p-1 h-100">
                    <div className="form">
                        <div className="form_header">
                            {
                                allocation.allocation_id === 0 ? "Create new allocation"
                                    : `Change for ${allocation.office_name} at ${Utils.localise_date(allocation.allocation_date)}`
                            }
                        </div>

                        <div className="form_content">
                            <MySelect
                                change={(value, text) => {
                                    setAllocation({
                                        ...allocation, office_id: value as number, office_name: text, user_id: 0, user_name: ''
                                    })
                                }}
                                title="Branch of Allocation" value={allocation.office_id}
                                options={[{text: 'Select a branch', value: 0}, ...params.branches.select]}/>

                            <MySelect
                                change={(value, text) => {
                                    setAllocation({...allocation, user_id: value as number, user_name: text,})
                                }}
                                title="Select user you are allocating funds to" value={allocation.user_id}
                                options={[
                                    {text: 'Select a user', value: 0},
                                    ...params.branchUser.users
                                        .filter((user) => user.office_id === allocation.office_id && user.user_status === 'active')
                                        .map((user) => ({text: user.user_name, value: user.user_id}))
                                ]}/>

                            <div style={{marginBottom: '15px'}}>
                                <MyDateInput
                                    title="Select Allocation Date" value={allocation.allocation_date} name="allocation_date"
                                    placeholder="Set date of allocation" maxDate={Utils.today()} change={handle_change}/>
                            </div>

                            <MyInput
                                placeholder="Enter amount allocated" title="Amount Allocated" name="amount_allocated"
                                change={handle_change} value={allocation.amount_allocated as string}/>
                        </div>

                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <Button negative onClick={clear_allocations} labelPosition={"right"}
                                            content="Clear Data" icon="trash" size='mini' fluid/>
                                </div>
                                <div className="col-6 pl-1 pr-0">
                                    <Button positive onClick={save_allocations} disabled={allocation.allocation_id > 0}
                                            content="Save Allocation" icon="save" size='mini' fluid labelPosition='left'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
