import Utils from "../../../utils/Utils";
import {MyDateInput, MyLabel, SelectData} from "../../../utils/Components";
import {MultiSelect} from "../../../utils/MultiSelect";
import React from "react";
import {Button, Modal} from "semantic-ui-react";
import {MultiUser} from "../../admin/users/Users";

export interface CriteriaPayment {
    min_date: string
    max_date: string
    office_id: []
    users: []
}

export default function PaymentCriteria(
    params: {
        branches: SelectData[], users: MultiUser[], show: boolean, close: () => void, criteria: CriteriaPayment,
        handle_change: (criteria: CriteriaPayment) => void, generate: () => void
    }
) {
    const [users, setUsers] = React.useState<Array<SelectData>>([])
    const handle_change = (name: string, value: string | number | Array<number | string>) => {
        params.handle_change({...params.criteria, [name]: value})
    }

    React.useEffect(() => {
        const {users, selected} = Utils.get_multi_users({
            offices: params.criteria.office_id, selected: params.criteria.users, users: params.users
        })
        setUsers(users)
        handle_change('users', selected)
    }, [params.criteria.office_id, params.users, params.branches])

    return (
        <Modal open={params.show} basic size='mini' centered={false} onClose={params.close}>
            <div className='modal_div modal_form'>
                <div className="form_content">
                    <div className="row m-0">
                        <div className="col-12 p-1">
                            <MyDateInput
                                title="Minimum Payment Date" value={params.criteria.min_date} name="min_date"
                                maxDate={params.criteria.max_date} placeholder="Select minimum date" change={handle_change}/>
                        </div>
                        <div className="col-12 p-1">
                            <MyDateInput
                                title="Maximum payment date" value={params.criteria.max_date} name="max_date"
                                maxDate={Utils.today()} minDate={params.criteria.min_date}
                                placeholder="Select maximum date" change={handle_change}/>
                        </div>

                        <div className="col-12 p-1">
                            <MyLabel title="Select report branches"/>
                            <MultiSelect
                                multiple={true} placeholder="Select offices" items={params.branches} selected={params.criteria.office_id}
                                change={(value) => handle_change('office_id', value)}/>

                        </div>

                        <div className="col-12 p-1">
                            <MyLabel title="Select report users"/>
                            <MultiSelect
                                multiple={true} placeholder="Select users" items={users} selected={params.criteria.users}
                                change={(value) => handle_change('users', value)}/>
                        </div>
                    </div>
                </div>

                <div className="form_footer">
                    <div className="row m-0">
                        <div className="col-6 pl-0 pr-1">
                            <Button negative onClick={params.close} content="Close Window" size='mini' fluid icon='close'
                                    labelPosition="left"/>
                        </div>
                        <div className="col-6 pl-1 pr-0">
                            <Button positive onClick={params.generate} content="Load Report" size='mini' fluid icon="table"
                                    labelPosition="left"/>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
