import React from "react";
import {LoanInfo} from "../ShowLoan";
import {Tab} from "semantic-ui-react";
import {LoanExtension} from "./transactions/LoanExtension";
import LoanReschedules from "./transactions/LoanReschedules";
import LoanPenalties from "./transactions/LoanPenalties";
import OtherFees from "./transactions/OtherFees";
import RemoveInterest from "./transactions/RemoveInterest";
import LoanHistory from "./transactions/LoanHistory";

export default function OtherTransactions(params: { loanInfo: LoanInfo, setLoanInfo: (loanInfo: LoanInfo) => void }) {
    return (
        <>
            <Tab className='loan_other_data'
                 panes={[
                     {
                         menuItem: 'Loan History',
                         render() {
                             return (
                                 <Tab.Pane attached={false} className="other_loan_pane" key='loan_history'>
                                     <LoanHistory loanInfo={params.loanInfo}/>
                                 </Tab.Pane>
                             )
                         }
                     },
                     {
                         menuItem: 'Penalties',
                         render() {
                             return (
                                 <Tab.Pane attached={false} className="other_loan_pane" key='loan_penalties'>
                                     <LoanPenalties loanInfo={params.loanInfo} setLoanInfo={params.setLoanInfo}/>
                                 </Tab.Pane>
                             )
                         }
                     },
                     {
                         menuItem: 'Other Fees',
                         render() {
                             return (
                                 <Tab.Pane attached={false} className="other_loan_pane" key='loan_other_fees'>
                                     <OtherFees loanInfo={params.loanInfo} setLoanInfo={params.setLoanInfo}/>
                                 </Tab.Pane>
                             )
                         }
                     },
                     {
                         menuItem: 'Waive Interest',
                         render() {
                             return (
                                 <Tab.Pane attached={false} className="other_loan_pane" key='loan_scheduling'>
                                     <RemoveInterest loanInfo={params.loanInfo} setLoanInfo={params.setLoanInfo}/>
                                 </Tab.Pane>
                             )
                         }
                     },
                     {
                         menuItem: 'Restructuring',
                         render() {
                             return (
                                 <Tab.Pane attached={false} className="other_loan_pane" key='loan_scheduling'>
                                     <LoanReschedules loanInfo={params.loanInfo} setLoanInfo={params.setLoanInfo}/>
                                 </Tab.Pane>
                             )
                         }
                     },
                     {
                         menuItem: 'Loan Extensions',
                         render() {
                             return (
                                 <Tab.Pane attached={false} className="other_loan_pane" key='loan_re_structuring'>
                                     <LoanExtension loanInfo={params.loanInfo} setLoanInfo={params.setLoanInfo}/>
                                 </Tab.Pane>
                             )
                         }
                     },
                 ]}/>

        </>
    )
}
