import React from "react";
import Utils from "../../../utils/Utils";
import {Button, Modal, Table} from "semantic-ui-react";
import {Req} from "../../../utils/Req";
import {MyInput} from "../../../utils/Components";
import {ToastsStore} from "react-toasts";

export interface BankAccount {
    account_id: number
    account_name: string
    account_no: string
    bank_name: string
}

export default function BankAccounts(props: { show: boolean, accounts: Array<BankAccount>, close: (accounts: Array<BankAccount>) => void }) {
    const [saving, setSaving] = React.useState(false)
    const [accounts, setAccounts] = React.useState(props.accounts)

    const initial_account: BankAccount = {account_id: 0, account_name: "", account_no: "", bank_name: ""}
    const [account, setAccount] = React.useState<BankAccount>(initial_account)
    const handle_change = (name: string, value: string) => {
        setAccount({...account, [name]: value})
    }

    const save_account = () => {
        if (Utils.invalid_address(account.bank_name)) {
            ToastsStore.error("Enter a valid bank name")
        } else if (Utils.invalid_address(account.account_name)) {
            ToastsStore.error("Enter a valid account name")
        } else if (Utils.invalid_address(account.account_no)) {
            ToastsStore.error("Enter a valid account number")
        } else {
            setSaving(true)
            Req.save_bank_account(account)
                .then((response) => {
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            const new_account: BankAccount = {...account, account_id: response.data.account_id}
                            if (account.account_id === 0) {
                                setAccounts([...accounts, new_account])
                            } else {
                                setAccounts(
                                    accounts.map((_account) =>
                                        _account.account_id === account.account_id ? new_account : _account)
                                )
                            }
                            setAccount(new_account)
                            ToastsStore.error("Successfully saved bank account")
                        } else if (response.data.code === 2) {
                            ToastsStore.error("Account data already exists")
                        }
                        setSaving(false)
                    } else {
                        ToastsStore.error("Enter while saving bank account")
                        setSaving(false)
                    }
                })
                .catch(() => {
                    ToastsStore.error("Enter while saving bank account")
                })
        }
    }

    React.useEffect(() => {
        setAccounts(props.accounts)
    }, [props.accounts])

    React.useEffect(() => {

    }, [])

    return (
        <>
            <Modal open={props.show} basic size='small' onClose={() => props.close(accounts)}
                   closeOnDimmerClick={!saving} closeOnDocumentClick={!saving} closeOnEscape={!saving}>
                <div className="modal_div">
                    <div className='row m-0'>
                        <div className='col-6 p-1'>
                            <div className="accounts_form">
                                <MyInput
                                    placeholder="Enter bank name" title="Enter bank name"
                                    name="bank_name" change={handle_change} value={account.bank_name}/>

                                <MyInput
                                    placeholder="Enter account name" title="Enter account name"
                                    name="account_name" change={handle_change} value={account.account_name}/>

                                <MyInput
                                    placeholder="Enter account number" title="Enter account number"
                                    name="account_no" change={handle_change} value={account.account_no}/>

                                <div className="payment_button p-0">
                                    <Button.Group floated='right' size='tiny' fluid disabled={saving}>
                                        <Button negative
                                                onClick={() => {
                                                    setAccount(initial_account)
                                                    props.close(accounts)
                                                }}>Close Window</Button>
                                        <Button.Or/>
                                        <Button positive onClick={save_account} loading={saving}>Save Account</Button>
                                    </Button.Group>
                                </div>
                            </div>
                        </div>

                        <div className='col-6 p-1'>
                            <div className="accounts_form">
                                <div className="table_container">
                                    <Table celled striped compact size='small' inverted color='grey' selectable>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={2}>No.</Table.HeaderCell>
                                                <Table.HeaderCell>Account Number</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {
                                                accounts.map((account, index) =>
                                                    <Table.Row key={account.account_id} onClick={() => setAccount(account)}>
                                                        <Table.Cell width={2}>{Utils.row_number(index)}</Table.Cell>
                                                        <Table.Cell>{account.account_no}</Table.Cell>
                                                    </Table.Row>
                                                )
                                            }
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
