import React from "react";
import {Table} from "semantic-ui-react";
import Utils from "../../../utils/Utils";
import {ShowLoan} from "../../loans/container/ShowLoan";

interface Report {
    extension_id: number,
    loan_id: number,
    extension_principal: number,
    extension_interest: number,
    extension_period: number,
    extension_rate: number,
    extension_reason: string,
    extension_start_date: string,
    payment_due_date: string,
    date_extended: string,
    interest_added: number,
    interest_mode: "principal_only" | "interest_principal" | ""
    username: string,
    date_disbursed: string,
    principal_balance: number,
    interest_balance: number,
    main_contact: string,
    client_name: string,
    loan_principal: number
    extensions: number
    total_balance: number
    balance_before: number
}

export default function RestructuredReport(params: { transactions: any }) {
    const [showLoan, setShowLoan] = React.useState({show: false, loan_id: 0})
    const [statements, setStatements] = React.useState<Array<Report>>([])

    React.useEffect(() => {
        if (params.transactions.hasOwnProperty("rp")) {
            setStatements(params.transactions.rp)
        }
    }, [params.transactions])

    /*
    extension_reason: string,
    date_extended: string,
    * */
    return (
        <>
            {
                showLoan.show ?
                    <ShowLoan close={() => setShowLoan({loan_id: 0, show: false})} loan_id={showLoan.loan_id}/> :
                    <Table celled striped compact size='small' inverted color='grey' selectable className="has_total">
                        <Table.Header>
                            <Table.Row key={0}>
                                <Table.HeaderCell style={{width: '50px'}} textAlign="center" rowSpan={2}>No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '70px'}} textAlign="center" rowSpan={2}>Loan No</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '50px'}} textAlign="center" rowSpan={2}>Count</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '200px'}} rowSpan={2}>Client Name</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '110px'}} rowSpan={2}>Contact</Table.HeaderCell>
                                <Table.HeaderCell className='time_row' rowSpan={2}>Disbursed Date</Table.HeaderCell>
                                <Table.HeaderCell className='time_row' rowSpan={2}>Last Extended</Table.HeaderCell>

                                <Table.HeaderCell colSpan={3} style={{width: '300px'}} textAlign="center">Balance before extension</Table.HeaderCell>
                                <Table.HeaderCell colSpan={3} style={{width: '300px'}} textAlign="center">Current Balance</Table.HeaderCell>

                                <Table.HeaderCell style={{width: '200px'}} rowSpan={2}>Extended By</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}} rowSpan={2}>Start Date</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '160px'}} rowSpan={2}>Period / Due Date</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '60px'}} textAlign="center" rowSpan={2}>Rate</Table.HeaderCell>

                                <Table.HeaderCell className='amount_row' rowSpan={2}>Interest Added</Table.HeaderCell>

                                <Table.HeaderCell style={{width: '130px'}} rowSpan={2}>Interest Mode</Table.HeaderCell>
                            </Table.Row>

                            <Table.Row key={1}>
                                <Table.HeaderCell style={{width: '100px'}}>Principal</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '100px'}}>Interest</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '100px'}}>Total</Table.HeaderCell>

                                <Table.HeaderCell style={{width: '100px'}}>Principal</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '100px'}}>Interest</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '100px'}}>Total</Table.HeaderCell>
                            </Table.Row>

                        </Table.Header>

                        <Table.Body>
                            {
                                statements
                                    .map((transaction, index) =>
                                        <Table.Row
                                            key={transaction.extension_id}
                                            onClick={() => {
                                                if (transaction.loan_id > 0) {
                                                    setShowLoan({loan_id: transaction.loan_id, show: true});
                                                }
                                            }}>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">
                                                {(index < statements.length - 1) && (index + 1)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '70px'}} textAlign="center">{transaction.loan_id}</Table.Cell>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">{transaction.extensions}</Table.Cell>
                                            <Table.Cell style={{width: '200px'}}>{transaction.client_name}</Table.Cell>
                                            <Table.Cell style={{width: '110px'}}>{transaction.main_contact}</Table.Cell>
                                            <Table.Cell className='time_row'>{Utils.localise_date(transaction.date_disbursed)}</Table.Cell>
                                            <Table.Cell className='time_row'>{Utils.localise_date(transaction.date_extended)}</Table.Cell>

                                            <Table.Cell style={{width: '100px'}}>{Utils.comma_number(transaction.extension_principal)}</Table.Cell>
                                            <Table.Cell style={{width: '100px'}}>{Utils.comma_number(transaction.extension_interest)}</Table.Cell>
                                            <Table.Cell style={{width: '100px'}}>{Utils.comma_number(transaction.balance_before)}</Table.Cell>

                                            <Table.Cell style={{width: '100px'}}>{Utils.comma_number(transaction.principal_balance)}</Table.Cell>
                                            <Table.Cell style={{width: '100px'}}>{Utils.comma_number(transaction.interest_balance)}</Table.Cell>
                                            <Table.Cell style={{width: '100px'}}>{Utils.comma_number(transaction.total_balance)}</Table.Cell>

                                            <Table.Cell style={{width: '200px'}}>{transaction.username}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.localise_date(transaction.extension_start_date)}</Table.Cell>
                                            <Table.Cell style={{width: '160px'}}>
                                                {
                                                    (index < statements.length - 1) &&
                                                    `${transaction.extension_period} Mon / ${Utils.localise_date(transaction.payment_due_date)}`
                                                }
                                            </Table.Cell>
                                            <Table.Cell style={{width: '60px'}} textAlign="center">
                                                {(index < statements.length - 1) && `${transaction.extension_rate}%`}
                                            </Table.Cell>

                                            <Table.Cell className='amount_row'>{Utils.comma_number(transaction.interest_added, '')}</Table.Cell>

                                            <Table.Cell style={{width: '130px'}}>
                                                {
                                                    transaction.interest_mode === "" ? "" :
                                                        transaction.interest_mode === "principal_only" ? "Principal" : "Principal + Interest"
                                                }
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                            }
                        </Table.Body>
                    </Table>
            }
        </>
    )
}
