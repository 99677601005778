import React from "react";
import Utils from "../../../../../utils/Utils";
import {Table} from "semantic-ui-react";
import {Req} from "../../../../../utils/Req"
import {LoanInfo} from "../../ShowLoan";
import {ToastsStore} from "react-toasts";
import {ActualSchedule} from "./PaymentScheduleActual";
import {Loading} from "../../../../../utils/Components";

export interface Schedule {
    date: string
    principal: number
    interest: number
    total: number
    balance: number
}

export function PaymentScheduleIdeal(params: { loanInfo: LoanInfo, setLoanInfo: (loanInfo: LoanInfo) => void }) {
    const [loading, setLoading] = React.useState({message: "", show: false})

    const calculate_schedule = () => {
        if (['pending', 'rejected', 'accepted', 'verified'].includes(params.loanInfo.loan_status)) {
            const principal = Utils.strip_commas(params.loanInfo.loan_principal)

            if (!Utils.is_valid_number(principal) || parseFloat(principal) <= 0) {
                ToastsStore.error("Enter a valid loan principal")
            } else if (!Utils.is_valid_number(params.loanInfo.loan_rate) || parseFloat(params.loanInfo.loan_rate) < 0) {
                ToastsStore.error("Select a loan rate")
            } else if (!Utils.is_valid_number(params.loanInfo.loan_period) || parseInt(params.loanInfo.loan_period) <= 0) {
                ToastsStore.error("Select a valid loan period")
            } else if (params.loanInfo.rate_type === "") {
                ToastsStore.error("Select a valid loan rate type")
            } else {
                setLoading({message: "Loading loan schedule data, please wait", show: true})
                Req.calculate_loan_schedule({
                    loan_interval: params.loanInfo.payment_interval,
                    loan_period: parseInt(params.loanInfo.loan_period),
                    loan_principal: parseFloat(principal),
                    loan_rate: parseFloat(params.loanInfo.loan_rate),
                    rate_type: params.loanInfo.rate_type,
                    start_date: params.loanInfo.start_date
                })
                    .then((response) => {
                        setLoading({message: "", show: false})
                        if (response.data.hasOwnProperty("due_date")) {
                            let actual_schedules: Array<ActualSchedule> = [];
                            (response.data.schedules as Array<Schedule>).forEach((schedule) => {
                                actual_schedules = [...actual_schedules, {
                                    payment_date: schedule.date,
                                    actual_principal: schedule.principal,
                                    actual_interest: schedule.interest,
                                    total_payment: schedule.interest + schedule.principal,
                                    schedule_id: 0,
                                }]
                            })
                            params.setLoanInfo({
                                ...params.loanInfo, expected_schedule: response.data.schedules, actual_schedule: actual_schedules,
                                projected_interest: response.data.projected_interest, due_date: response.data.due_date
                            })
                        } else {
                            ToastsStore.error("Could not load loan schedule, please retry")
                        }
                    })
                    .catch(() => {
                        setLoading({message: "", show: false})
                        ToastsStore.error("Could not load loan schedule, please retry")
                    })
            }
        }
    }

    React.useEffect(() => {
        calculate_schedule()
    }, [])
    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <div className="row mx-0 h-100">
                <div className="col-12 p-1 h-100">
                    <div className="table_container loan_schedule_container full_height">
                        <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                            <Table.Header fullWidth>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Payment Date</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Principal Payment</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Interest Payment</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Total Payment</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Balance After</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    params.loanInfo.expected_schedule
                                        .map((schedule, index) =>
                                            <Table.Row key={index}>
                                                <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>{Utils.localise_date(schedule.date)}</Table.Cell>
                                                <Table.Cell style={{width: '150px'}}>{Utils.comma_number(schedule.principal)}</Table.Cell>
                                                <Table.Cell style={{width: '150px'}}>{Utils.comma_number(schedule.interest)}</Table.Cell>
                                                <Table.Cell style={{width: '150px'}}>{Utils.comma_number(schedule.total)}</Table.Cell>
                                                <Table.Cell style={{width: '150px'}}>{Utils.comma_number(schedule.balance)}</Table.Cell>
                                            </Table.Row>
                                        )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}
