import React, {ChangeEvent} from "react";
import Utils from "../../../../../utils/Utils";
import {initial_security, Security} from "./LoanCollateral";
import {ToastsStore} from "react-toasts";
import {MyInput, MyLabel} from "../../../../../utils/Components";
import {Button, Form, Image, TextArea} from "semantic-ui-react";
import imageCompression from "browser-image-compression";

export default function ManageCollateral(params: {
    security: Security, securities: Security[], save: (security: Security) => void, editDisabled: boolean
}) {
    const [security, setSecurity] = React.useState<Security>(initial_security)

    const handle_change = (name: string, value: string | number) => {
        setSecurity({...security, [name]: value})
    }

    const save_collateral = () => {
        const collateral: Security = {...security, collateral_value: Utils.strip_commas(security.collateral_value)}
        if (collateral.collateral_name.length < 1) {
            ToastsStore.error("Enter a security name")
        } else if (!Utils.is_valid_number(collateral.collateral_value)) {
            ToastsStore.error("Enter valid security value")
        } else if (collateral.images.length === 0) {
            ToastsStore.error("Add at least 1(one) image for the security")
        } else {
            params.save({...collateral, index: params.securities.length})
            setSecurity(initial_security)
        }
    }

    const handle_image = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files !== null && files.length > 0) {
            const file: File | null = files.item(0);
            if (file !== null) {
                const options = {maxSizeMB: 0.05, maxWidthOrHeight: 800, useWebWorker: true}
                try {
                    imageCompression(file, options)
                        .then((compressed)=>{
                            Utils.format_file(compressed)
                                .then((result) => {
                                    setSecurity({...security, images: [{path: result.file_src, file: compressed}, ...security.images]})
                                })
                        })
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }

    React.useEffect(() => {
        setSecurity(params.security)
    }, [params.security])

    return (
        <div className="form">
            <div className="form_content no_header">
                <div className="px-1 mb-1">
                    <MyInput placeholder="Enter collateral name" title="Collateral Name" name="collateral_name"
                             change={handle_change} value={security.collateral_name}/>
                </div>
                <div className="px-1 mb-1">
                    <MyInput placeholder="Enter collateral value" title="Collateral Value" name="collateral_value"
                             change={handle_change} value={Utils.comma_input(security.collateral_value)}/>
                </div>
                <div className="px-1 mb-1">
                    <MyLabel title="Enter collateral description"/>
                    <Form>
                        <TextArea
                            placeholder='Enter collateral description' rows={6} value={security.collateral_description}
                            onChange={((event, data) => {
                                handle_change('collateral_description', data.value as string)
                            })}/>
                    </Form>
                </div>

                {
                    security.images
                        .map((image, index) =>
                            <div className="collateral_images" key={index}>
                                <Image src={image.path}/>
                                {
                                    image.file !== undefined &&
                                    <div className="delete_image"
                                         onClick={() => {
                                             setSecurity({
                                                 ...security, images: security.images.filter((_, __) => __ !== index)
                                             })
                                         }}>
                                        <i className="ui trash icon"/>
                                    </div>
                                }
                            </div>
                        )
                }

                <div className="add_collateral">
                    <input type="file" className="input_file" id="new_collateral" accept="image/*"
                           onChange={(event) => handle_image(event)}/>
                    <label htmlFor="new_collateral">
                        <i className="ui upload icon"/>
                    </label>
                </div>
            </div>

            <div className="form_footer">
                <div className="row m-0">
                    <div className="col-6 pl-0 pr-1">
                        <Button negative fluid content="Clear Data" size='mini' icon="trash" labelPosition="left"
                                onClick={() => setSecurity(initial_security)}/>
                    </div>
                    <div className="col-6 pl-1 pr-0">
                        <Button positive fluid content="Save Collateral" size='mini' icon="save" labelPosition="left"
                                onClick={save_collateral}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
