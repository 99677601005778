import React from 'react'
import {Loading, MyInput} from '../../../utils/Components'
import {ToastsStore} from "react-toasts";
import {LoanProps, ShowLoan} from '../container/ShowLoan'
import {Req} from "../../../utils/Req";
import Utils from "../../../utils/Utils";
import {Button, Table} from "semantic-ui-react";
import {useAppSelector} from "../../../store/hooks";

import {getStaff} from "../../../store/slices/userSlice";
import ModifyLoan, {initial_loan} from "./modify/ModifyLoan";

interface Loan {
    loan_id: number
    client_name: string
    principal: number
    balance: number
}

export default function LoanModification() {
    const user = useAppSelector(getStaff)

    const [loading, setLoading] = React.useState({show: false, title: ""})
    const [search, setSearch] = React.useState({name: "", loan_id: ""})
    const handle_search = (name: string, value: string) => {
        setSearch({...search, [name]: value})
    }
    const [loans, setLoans] = React.useState<Array<{ loan_id: number, client_name: string, principal: number }>>([])

    /*Single loan data*/
    const [loan, setLoan] = React.useState(initial_loan)
    const [loanModal, setLoanModal] = React.useState({show: false, loan_id: 0})
    const [loanProps, setLoanProps] = React.useState<LoanProps>({
        loanTypes: [], offices: [], charges: [], userInfo: user.staff, clients: [], staffs: []
    })

    const clear_data = () => {
        setLoans([])
        setLoan(initial_loan)
    }

    const search_loans = () => {
        setLoading({show: true, title: "Loading available loans, please wait"})
        clear_data()
        Req.get_modifiable_loans({name: search.name, loan_id: search.loan_id})
            .then((response) => {
                setLoading({show: false, title: ""})
                if (response.data.hasOwnProperty("loans")) {
                    const _loans: Array<Loan> = response.data["loans"]
                    setLoans(_loans)
                    if (_loans.length === 1) {
                        get_loan_info(_loans[0].loan_id)
                    }
                } else {
                    ToastsStore.error("Could not load loans")
                }
            })
            .catch(() => {
                ToastsStore.error("Could not load loans")
                setLoading({show: false, title: ""})
            })
    }

    const get_loan_info = (loan_id: number) => {
        setLoading({show: true, title: "Loading loan data, please wait"})
        Req.get_modifiable_loan({loan_id: loan_id})
            .then((response) => {
                setLoading({show: false, title: ""})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    if (response.data.hasOwnProperty("loan")) {
                        setLoan(response.data.loan)
                    }

                    setLoanProps({
                        charges: response.data.initial.charges,
                        clients: response.data.initial.clients,
                        loanTypes: response.data.initial.types,
                        offices: response.data.initial.branches,
                        userInfo: user.staff,
                        staffs: response.data.initial.staffs
                    })
                } else {
                    ToastsStore.error("Could not load loan information")
                }
            })
            .catch(() => {
                ToastsStore.error("Could not load loan information")
                setLoading({show: false, title: ""})
            })
    }

    React.useEffect(() => {
        setLoanModal({...loanModal, loan_id: loan.loan_id})
    }, [loan.loan_id])

    React.useEffect(() => {
        get_loan_info(0)
    }, [])

    return (
        <>
            <Loading show={loading.show} text={loading.title}/>

            {
                loanModal.show ?
                    <ShowLoan close={() => setLoanModal({...loanModal, show: false})} loan_id={loanModal.loan_id}/>
                    :
                    <>
                        <div className="row m-0 h-100">
                            <div className="col-12 col-md-6 p-1 h-100">
                                <div className="content_bar">
                                    <div className="search_bar">
                                        <div>
                                            <MyInput
                                                placeholder="Search by client name" title="" name="name"
                                                value={search.name} change={handle_search} enter={search_loans}/>
                                        </div>
                                        <div>
                                            <MyInput
                                                placeholder="Search by loan no" title="" name="loan_id" value={search.loan_id}
                                                change={handle_search} enter={search_loans}/>
                                        </div>
                                    </div>
                                    <div className="button_bar">
                                        <Button primary content="Search Loans" size='mini' onClick={search_loans} icon='search'/>
                                    </div>
                                </div>

                                <div className="table_container container_no_footer">
                                    <Table celled striped compact size='small' inverted color='grey' selectable>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                                <Table.HeaderCell style={{width: '80px'}}>Loan No.</Table.HeaderCell>
                                                <Table.HeaderCell style={{width: '200px'}}>Customer Name</Table.HeaderCell>
                                                <Table.HeaderCell style={{width: '120px'}}>Principal</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {
                                                loans.map((loan, index) =>
                                                    <Table.Row key={loan.loan_id} onClick={() => get_loan_info(loan.loan_id)}>
                                                        <Table.Cell style={{width: '50px'}} textAlign="center">
                                                            {Utils.row_number(index)}
                                                        </Table.Cell>
                                                        <Table.Cell style={{width: '80px'}}>{loan.loan_id}</Table.Cell>
                                                        <Table.Cell style={{width: '200px'}}>{loan.client_name}</Table.Cell>
                                                        <Table.Cell style={{width: '120px'}}>
                                                            {Utils.comma_number(loan.principal)}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                            }
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 p-1 h-100">
                                <ModifyLoan
                                    loan={loan} setLoan={setLoan} loanProps={loanProps}
                                    setClients={(clients) => setLoanProps({...loanProps, clients: clients})}
                                    showLoan={() => setLoanModal({...loanModal, show: true})}/>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}
