import React from "react";
import {Button, Modal} from "semantic-ui-react"
import Utils from "../../../utils/Utils";
import {MyDateInput, MyLabel, SelectData} from "../../../utils/Components";
import {MultiSelect} from "../../../utils/MultiSelect";

export interface CriteriaAging {
    min_date: string
    max_date: string
    branches: []
}

export default function AgingCriteria(params: {
    branches: SelectData[], show: boolean, close: () => void, criteria: CriteriaAging,
    handle_change: (criteria: CriteriaAging) => void, generate: () => void
}) {
    const handle_change = (name: string, value: string | number | Array<number | string>) => {
        params.handle_change({...params.criteria, [name]: value})
    }

    return (
        <Modal open={params.show} basic size='mini' centered={false} onClose={params.close} dimmer='blurring'>
            <div className='modal_div modal_form'>
                <div className="form_content">
                    <div className="row m-0">
                        <div className="col-12 p-1">
                            <MyLabel title="Select branches"/>
                            <MultiSelect
                                multiple={true} placeholder="Select offices" items={params.branches} selected={params.criteria.branches}
                                change={(value) => handle_change('branches', value)}/>
                        </div>
                        <div className="col-12 p-1">
                            <MyDateInput
                                title="Minimum disbursement date" value={params.criteria.min_date} name="min_date"
                                maxDate={params.criteria.max_date} placeholder="Select minimum disbursement date" change={handle_change}/>
                        </div>
                        <div className="col-12 p-1">
                            <MyDateInput
                                title="Maximum disbursement date" value={params.criteria.max_date} name="max_date"
                                maxDate={Utils.today()} minDate={params.criteria.min_date}
                                placeholder="Select maximum disbursement date" change={handle_change}/>
                        </div>
                    </div>
                </div>

                <div className="form_footer">
                    <div className="row m-0">
                        <div className="col-6 pl-0 pr-1">
                            <Button negative onClick={params.close} content="Close Window" size='mini' fluid icon='close' labelPosition="left"/>
                        </div>
                        <div className="col-6 pl-1 pr-0">
                            <Button positive onClick={params.generate} content="Load Report" size='mini' fluid icon="table" labelPosition="left"/>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
