import React from "react";
import Utils, {InputFile} from "../../../utils/Utils";
import {MyDateInput, MyInput, MyLabel, MySelect, select_type, SelectData, TablePagination} from "../../../utils/Components";
import {Page, Req} from "../../../utils/Req";
import {Button, Form, Image, Table, TextArea} from "semantic-ui-react";
import placeholder from "../../../../assets/images/image.png"
import BankAccounts, {BankAccount} from "./BankAccounts";
import {useAppSelector} from "../../../store/hooks";
import {getStaff} from "../../../store/slices/userSlice";
import {ToastsStore} from "react-toasts";
import {BranchUser} from "../../../nav/SideBar";
import {MultiSelect, multiselect_type} from "../../../utils/MultiSelect";

interface Withdrawal {
    withdrawal_id: number
    account_id: number
    account_name: string
    account_no: string
    bank_name: string
    office_id: number
    office_name: string
    accountant_id: number
    accountant_name: string
    amount_withdrawn: number | string
    withdrawal_reason: string
    date_withdrawn: string
    date_modified: string
    withdrawal_receipt: InputFile | null
}

export default function BankWithdrawal(params: { branchUser: BranchUser, branches: { select: SelectData[], selected: number[] } }) {
    const user = useAppSelector(getStaff)

    const initial_withdrawal: Withdrawal = {
        account_id: 0, account_name: "", account_no: "", accountant_id: user.staff.user_id, accountant_name: user.staff.last_name,
        amount_withdrawn: "", bank_name: "", date_modified: "", date_withdrawn: Utils.today(), office_id: 0,
        office_name: "", withdrawal_id: 0, withdrawal_receipt: null, withdrawal_reason: ""
    }

    const [showAccounts, setShowAccounts] = React.useState(false)
    const [bankAccounts, setBankAccounts] = React.useState<Array<BankAccount>>([])

    const [withdrawal, setWithdrawal] = React.useState(initial_withdrawal)
    const [withdrawals, setWithdrawals] = React.useState<Array<Withdrawal>>([])
    const handle_change = (name: string, value: string | number) => {
        setWithdrawal({...withdrawal, [name]: value})
    }

    const [pages, setPages] = React.useState<Page>({page: 1, pages: 0, limit: 100})
    const [search, setSearch] = React.useState({
        min_date: Utils.weekly_date().sunday, max_date: Utils.today(), branches: params.branches.selected
    })
    const handle_search = (name: string, value: string | number | multiselect_type) => {
        setSearch({...search, [name]: value})
    }

    const [loading, setLoading] = React.useState(false)
    const [searching, setSearching] = React.useState(false)

    const load_withdrawals = (params: { page: number, pages: number }) => {
        setSearching(true)
        Req.get_bank_withdrawals({
            max_date: search.max_date, min_date: search.min_date, branches: JSON.stringify(search.branches),
            page: params.page, pages: params.pages,
        })
            .then((response) => {
                setSearching(false)
                if (response.data.hasOwnProperty("withdrawals")) {
                    let new_withdrawals: Array<any> = response.data.withdrawals;
                    new_withdrawals.forEach((new_withdrawal, index) => {
                        new_withdrawal.withdrawal_receipt = new_withdrawal.withdrawal_receipt === "" ? null :
                            new InputFile(Req.BASE_URL + new_withdrawal.withdrawal_receipt)
                        new_withdrawal[index] = new_withdrawal
                    })
                    setWithdrawals(new_withdrawals)
                    setPages({...pages, pages: response.data.pages, page: params.page})
                } else {
                    ToastsStore.error("Could not load bank withdrawals")
                }
            })
            .catch(() => {
                setSearching(false)
                ToastsStore.error("Could not load bank withdrawals")
            })
    }

    const clear_deposits = () => {
        setWithdrawal(initial_withdrawal)
    }

    const save_deposits = () => {
        /* if (withdrawal.withdrawal_receipt === null) {
             ToastsStore.error("Select a bank withdrawal receipt")
         }*/
        if (withdrawal.office_id === 0) {
            ToastsStore.error("Select branch making the bank withdrawal")
        } else if (withdrawal.account_id === 0) {
            ToastsStore.error("Select account money was withdrawn from")
        } else if (!Utils.is_valid_number(withdrawal.amount_withdrawn as string)) {
            ToastsStore.error("Enter valid amount withdrew")
        } else {
            setLoading(true)
            Req.save_bank_withdrawal({
                account_id: withdrawal.account_id, amount_withdrawn: withdrawal.amount_withdrawn as number,
                date_withdrawn: withdrawal.date_withdrawn, office_id: withdrawal.office_id, withdrawal_id: withdrawal.withdrawal_id,
                withdrawal_reason: withdrawal.withdrawal_reason, withdrawal_receipt: withdrawal.withdrawal_receipt
            })
                .then((response) => {
                    setLoading(false)
                    if (response.data.hasOwnProperty("code") && response.data['code'] === 1) {
                        let new_withdrawal = {
                            ...withdrawal, withdrawal_id: response.data.withdrawal_id, date_modified: response.data.date_modified
                        }
                        if (response.data.withdrawal_receipt !== "") {
                            new_withdrawal = {
                                ...new_withdrawal,
                                withdrawal_receipt: new InputFile(`${Req.BASE_URL}${response.data.withdrawal_receipt}`)
                            }
                        }
                        if (withdrawal.withdrawal_id === 0) {
                            setWithdrawals(withdrawals => [...withdrawals, new_withdrawal])
                        } else {
                            setWithdrawals(withdrawals.map((_withdrawal) =>
                                _withdrawal.withdrawal_id === withdrawal.withdrawal_id ? new_withdrawal : withdrawal
                            ))
                        }
                        setWithdrawal(new_withdrawal)
                        ToastsStore.success("Successfully saved bank withdrawal")
                    } else {
                        ToastsStore.error("Error while saving bank withdrawal, please retry")
                    }
                })
                .catch(() => {
                    setLoading(false)
                    ToastsStore.error("Error while saving bank withdrawal, please retry")
                })
        }
    }

    React.useEffect(() => {
        Req.get_bank_accounts()
            .then((response) => {
                if (response.data.hasOwnProperty("accounts")) {
                    setBankAccounts(response.data.accounts)
                }
            })
    }, [])

    return (
        <>
            <BankAccounts
                show={showAccounts} accounts={bankAccounts}
                close={(accounts: Array<BankAccount>) => {
                    setBankAccounts(accounts)
                    setShowAccounts(false)
                }}/>

            <div className="row m-0 h-100">
                <div className="col-12 col-md-8 p-1 h-100">
                    <div className="content_bar">
                        <div className="search_bar">
                            <div>
                                <MultiSelect
                                    multiple={true} placeholder="Select branches"
                                    items={params.branches.select} selected={search.branches}
                                    change={(value) => handle_search('branches', value)}/>
                            </div>
                            <div>
                                <MyDateInput
                                    value={search.min_date} name="min_date" maxDate={search.max_date}
                                    placeholder="Select min date" change={handle_search}/>
                            </div>
                            <div>
                                <MyDateInput
                                    value={search.max_date} name="max_date" minDate={search.min_date}
                                    placeholder="Select max date" change={handle_search}/>
                            </div>
                        </div>

                        <div className="button_bar">
                            <Button size='mini' content="Search" primary labelPosition="left" loading={searching} disabled={searching}
                                    icon="search" onClick={() => load_withdrawals({page: 1, pages: 0})}/>

                            <Button size='mini' content="Accounts" primary labelPosition="left" disabled={searching}
                                    onClick={() => setShowAccounts(true)} icon="th list"/>
                        </div>
                    </div>

                    <div className="table_container">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '40px'}}>No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '180px'}}>Office Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '180px'}}>Accountant Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '130px'}}>Amount</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Account No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Date Withdrawn</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    withdrawals.map((bank_withdrawal, index) =>
                                        <Table.Row key={bank_withdrawal.withdrawal_id}
                                                   onClick={() => setWithdrawal(bank_withdrawal)}>
                                            <Table.Cell style={{width: '40px'}}>{Utils.row_number(index, pages)}</Table.Cell>
                                            <Table.Cell style={{width: '180px'}}>{bank_withdrawal.office_name}</Table.Cell>
                                            <Table.Cell style={{width: '180px'}}>{bank_withdrawal.accountant_name}</Table.Cell>
                                            <Table.Cell style={{width: '130px'}}>
                                                {Utils.comma_number(bank_withdrawal.amount_withdrawn as number)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{bank_withdrawal.account_no}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>
                                                {Utils.localise_date(bank_withdrawal.date_withdrawn)}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>

                    <div className="table_footer">
                        <TablePagination
                            total={pages.pages} change={(page: number) => load_withdrawals({page: page, pages: pages.pages})}/>
                    </div>
                </div>

                <div className="col-12 col-md-4 p-1 h-100">
                    <div className="form">
                        <div className="form_header">
                            {
                                withdrawal.withdrawal_id === 0 ? "Create new Bank Withdrawal"
                                    : `Change for ${Utils.comma_number(withdrawal.amount_withdrawn as number)},
                                                     at ${Utils.localise_date(withdrawal.date_withdrawn)}`
                            }
                        </div>

                        <div className="form_content">
                            <div className="mb-2">
                                <div style={{height: '120px'}}>
                                    <Image
                                        centered
                                        src={withdrawal.withdrawal_receipt === null ? placeholder : withdrawal.withdrawal_receipt.file_src}/>
                                </div>
                                <div className="mt-2">
                                    <input type="file" className="input_file" id="withdrawal_receipt" accept="image/*"
                                           onChange={(event) => {
                                               const files = event.target.files;
                                               if (files !== null && files.length > 0) {
                                                   const file: File | null = files.item(0);
                                                   if (file !== null) {
                                                       Utils.format_file(file as File)
                                                           .then(result => {
                                                               setWithdrawal({...withdrawal, 'withdrawal_receipt': result})
                                                           })
                                                   }
                                               }
                                           }}/>
                                    <label htmlFor="withdrawal_receipt" className="ui tiny primary button fluid">
                                        <i className="ui upload icon"/>
                                        Select Bank Withdrawal Receipt
                                    </label>
                                </div>
                            </div>

                            <MySelect
                                change={(value: select_type) => handle_change('office_id', (value as number))}
                                title="Branch making Withdrawal" value={withdrawal.office_id} options={params.branches.select}/>

                            <MySelect
                                change={(value: select_type) => {
                                    const account = Utils.get_bank_account(bankAccounts, value as number)
                                    setWithdrawal({
                                        ...withdrawal, account_id: (value as number), account_no: account.account_no,
                                        account_name: account.account_name, bank_name: account.bank_name
                                    })
                                }}
                                title="Select Bank Account" value={withdrawal.account_id}
                                options={Utils.get_bank_accounts(bankAccounts, {text: "Select bank account", value: 0})}/>

                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <MyInput
                                        placeholder="Enter account name" title="" disabled={true}
                                        name="account_name" value={withdrawal.account_name}/>
                                </div>
                                <div className="col-6 pl-0 pr-0">
                                    <MyInput
                                        placeholder="Enter bank name" title="" disabled={true}
                                        name="bank_name" value={withdrawal.bank_name}/>
                                </div>
                            </div>

                            <MyInput
                                placeholder="Enter amount withdrawn" title="Amount Withdrawn" name="amount_withdrawn"
                                change={handle_change} value={withdrawal.amount_withdrawn as string}/>


                            <MyDateInput
                                title="Select Date of Withdrawal" value={withdrawal.date_withdrawn}
                                name="date_withdrawn" change={handle_change}
                                placeholder="Set date of withdrawal" maxDate={Utils.today()}/>

                            <Form className="mt-3">
                                <MyLabel title="Reason for withdrawal"/>
                                <TextArea placeholder='Enter reason why you made the withdrawal' rows={5}
                                          value={withdrawal.withdrawal_reason}
                                          onChange={((event, data) => {
                                              handle_change('withdrawal_reason', data.value as string)
                                          })}/>
                            </Form>
                        </div>

                        <div className="form_footer">
                            <div className='row m-0'>
                                <div className='col-6 pl-0 pr-1'>
                                    <Button negative onClick={clear_deposits}
                                            content='Clear Data' fluid size='tiny' icon='trash' labelPosition='right'/>
                                </div>
                                <div className='col-6 pl-1 pr-0'>
                                    <Button positive onClick={save_deposits} content='Save Deposit' size='tiny' icon='save'
                                            loading={loading} fluid labelPosition='left'
                                            disabled={loading || (withdrawal.withdrawal_id > 0
                                                && withdrawal.accountant_id !== user.staff.user_id)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
