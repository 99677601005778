import {intervals, rate_types} from "../../../admin/loans/LoanTypes";
import React from "react";
import Utils from "../../../../utils/Utils";
import {ToastsStore} from "react-toasts";
import {Req} from "../../../../utils/Req";
import ModifyCharges, {ModifyCharge} from "./ModifyCharges";
import ModifyPayments, {ModifyPayment} from "./ModifyPayments";
import ModifyReschedules, {ModifyReschedule} from "./ModifyReschedules";
import ModifySecurities, {ModifySecurity} from "./ModifySecurities";
import ModifyExtensions, {ModifyExtension} from "./ModifyExtensions";
import ModifyGuarantors, {ModifyGuarantor} from "./ModifyGuarantors";
import {Loading, MyDateInput, MyInput, MySelect, PDFReader, select_type} from "../../../../utils/Components";
import {Button} from "semantic-ui-react";
import {LoanProps} from "../../container/ShowLoan";
import {SystemClient} from "../../../clients/CreateClient";
import {Security} from "../../container/info/collateral/LoanCollateral";
import DeleteLoan from "../../container/DeleteLoan";

export interface ModifiedLoan {
    loan_id: number

    office_id: number
    inspector_id: number
    client_id: number
    loan_period: number | string
    loan_principal: string | number
    loan_rate: number | string
    loan_type: number
    payment_interval: intervals
    rate_type: rate_types
    start_date: string
    date_disbursed: string
    time_disbursed: string

    charges: ModifyCharge[]
    payments: ModifyPayment[]
    reschedules: ModifyReschedule[]
    securities: ModifySecurity[]
    extensions: ModifyExtension[]
    guarantors: ModifyGuarantor[]
}

export const initial_loan: ModifiedLoan = {
    charges: [], client_id: 0, extensions: [], inspector_id: 0, loan_id: 0, loan_period: 1, loan_principal: 0,
    loan_rate: 20, loan_type: 0, office_id: 0, payment_interval: 30, payments: [], rate_type: 'compound', reschedules: [],
    securities: [], start_date: "", date_disbursed: "", time_disbursed: "",
    guarantors: [{client_id: 0, monthly_income: "", relationship: ""}, {client_id: 0, monthly_income: "", relationship: ""}],
}

export default function ModifyLoan(params: {
    loanProps: LoanProps, loan: ModifiedLoan, setLoan: (modifiedLoan: ModifiedLoan) => void,
    showLoan: () => void, setClients: (clients: SystemClient[]) => void
}) {
    const [loading, setLoading] = React.useState({show: false, title: ""})
    const [showReader, setShowReader] = React.useState({display: false, source: ""})
    const [searchingClients, setSearchingClients] = React.useState(false)

    const [showCharges, setShowCharges] = React.useState(false)
    const [showExtensions, setShowExtensions] = React.useState(false)
    const [showGuarantor, setShowGuarantor] = React.useState(false)
    const [showPayments, setShowPayments] = React.useState(false)
    const [showReschedules, setShowReschedules] = React.useState(false)
    const [showSecurities, setShowSecurities] = React.useState(false)

    const [showDelete, setShowDelete] = React.useState(false)

    const [securities, setSecurities] = React.useState<Security[]>([])

    const handle_change = (name: string, value: string | number) => {
        params.setLoan({...params.loan, [name]: value})
    }

    React.useEffect(() => {
        const days = (params.loan.payment_interval) < 30 ? params.loan.payment_interval : undefined
        const months = (params.loan.payment_interval) < 30 ? undefined : params.loan.payment_interval / 30
        const start_date = Utils.format_payment_date(params.loan.date_disbursed, months, days)
        handle_change('start_date', start_date)
    }, [params.loan.payment_interval, params.loan.start_date])

    const query_clients = (query: string, guarantors: Array<number>) => {
        setSearchingClients(true)
        let clients = guarantors
        if (guarantors.length === 0) {
            if (params.loan.guarantors[1].client_id > 0) {
                clients = [...clients, params.loan.guarantors[1].client_id]
            }
            if (params.loan.guarantors[0].client_id > 0) {
                clients = [...clients, params.loan.guarantors[0].client_id]
            }
        }

        if (params.loan.client_id > 0) {
            clients = [...clients, params.loan.client_id]
        }

        Req.search_clients({clients: JSON.stringify(clients), query: query})
            .then((response) => {
                setSearchingClients(false)
                if (response.data.hasOwnProperty("code")) {
                    params.setClients(response.data.clients)
                }
            })
            .catch(() => {
                setSearchingClients(false)
            })
    }

    const update_loan = () => {
        if (params.loan.client_id === 0) {
            ToastsStore.error("Select a client")
        } else if (!Utils.is_valid_number(params.loan.loan_principal as string)) {
            ToastsStore.error("Insert a valid principal")
        } else if (params.loan.loan_type === 0) {
            ToastsStore.error("Select a loan type")
        } else if (!Utils.is_valid_number(params.loan.loan_rate as string)) {
            ToastsStore.error("Insert a valid loan rate")
        } else if (params.loan.rate_type === "") {
            ToastsStore.error("Select a loan rate type")
        } else if (!Utils.is_valid_number(params.loan.loan_period as string) || parseInt(params.loan.loan_period as string) < 1) {
            ToastsStore.error("Insert a valid loan period")
        } else if (params.loan.start_date === "") {
            ToastsStore.error("Select a valid payment start date")
        } else {
            setLoading({show: true, title: "Modifying loan, please wait"})
            Req.modify_loan({
                charges: JSON.stringify(params.loan.charges),
                client_id: params.loan.client_id,
                extensions: JSON.stringify(params.loan.extensions),
                guarantors: JSON.stringify(params.loan.guarantors),
                inspector_id: params.loan.inspector_id,
                start_date: params.loan.start_date,
                loan_period: parseInt(params.loan.loan_period.toString()),
                loan_principal: parseFloat(params.loan.loan_principal.toString()),
                loan_rate: parseFloat(params.loan.loan_rate.toString()),
                loan_type: params.loan.loan_type,
                office_id: params.loan.office_id,
                payment_interval: params.loan.payment_interval,
                payments: JSON.stringify(params.loan.payments),
                loan_id: params.loan.loan_id,
                rate_type: params.loan.rate_type,
                reschedules: JSON.stringify(params.loan.reschedules),
                securities: JSON.stringify(params.loan.securities),
                date_disbursed: `${params.loan.date_disbursed} ${params.loan.time_disbursed}`
            })
                .then((response) => {
                    setLoading({show: false, title: ""})
                    if (response.data.hasOwnProperty('code') && response.data.code === 1) {
                        params.showLoan()
                    } else {
                        ToastsStore.error("Error while modifying loan, please retry")
                    }
                })
                .catch(() => {
                    setLoading({show: false, title: ""})
                    ToastsStore.error("Error while modifying loan, please retry")
                })
        }
    }

    const clear_loan_balance = () => {
        setLoading({show: true, title: "Clearing all loan balance, please wait"})
        Req.clear_loan({loan_id: params.loan.loan_id})
            .then((response) => {
                setLoading({show: false, title: ""})
                if (response.data.hasOwnProperty("code")) {
                    if (response.data.code === 1) {
                        params.showLoan()
                    } else if (response.data.code === 2) {
                        ToastsStore.error(`Loan not found`)
                    } else if (response.data.code === 3) {
                        ToastsStore.error(`Only ${Utils.comma_number(response.data.paid)} has been paid by client`)
                    }
                } else {
                    ToastsStore.error("Loan could not be cleared, please retry")
                }
            })
            .catch(() => {
                setLoading({show: false, title: ""})
                ToastsStore.error("Loan could not be cleared, please retry")
            })
    }

    const print_loan_info = () => {
        setLoading({title: "Generating loan info, please wait", show: true})
        Req.get_payment_info({loan_id: params.loan.loan_id})
            .then((response) => {
                if (response.data.hasOwnProperty("file")) {
                    setShowReader({display: true, source: `${Req.BASE_URL}${response.data.file}`})
                } else {
                    ToastsStore.error("Error while generating loan info")
                }
                setLoading({...loading, show: false})
            })
            .catch(() => {
                setLoading({...loading, show: false})
                ToastsStore.error("Error while generating loan info")
            })
    }

    React.useEffect(() => {
        setSecurities(
            params.loan.securities.map((security, index) =>
                ({
                    collateral_description: security.security_desc,
                    collateral_id: security.security_id,
                    collateral_name: security.security_name,
                    collateral_value: security.security_value,
                    images: security.security_images.map((image) => ({path: Req.BASE_URL + image})),
                    index: index
                }))
        )
    }, [params.loan.securities])

    return (
        <>
            <Loading show={loading.show} text={loading.title}/>
            <PDFReader source={showReader.source} open={showReader.display}
                       close={() => setShowReader({...showReader, display: false})}/>

            <ModifyCharges loan={params.loan} show={showCharges} loanProps={params.loanProps}
                           close={(charges) => {
                               if (charges !== undefined) {
                                   params.setLoan({...params.loan, charges: charges})
                               }
                               setShowCharges(false);
                           }}/>

            <ModifyExtensions loan={params.loan} show={showExtensions} loanProps={params.loanProps}
                              close={(extensions) => {
                                  if (extensions !== undefined) {
                                      params.setLoan({...params.loan, extensions: extensions})
                                  }
                                  setShowExtensions(false);
                              }}/>

            <ModifyGuarantors loan={params.loan} show={showGuarantor} loanProps={params.loanProps}
                              query_clients={query_clients} setClients={params.setClients} searchingClients={searchingClients}
                              close={(guarantors) => {
                                  if (guarantors !== undefined) {
                                      params.setLoan({...params.loan, guarantors: guarantors})
                                  }
                                  setShowGuarantor(false);
                              }}/>

            <ModifyPayments loan={params.loan} show={showPayments} loanProps={params.loanProps}
                            close={(payments) => {
                                if (payments !== undefined) {
                                    params.setLoan({...params.loan, payments: payments})
                                }
                                setShowPayments(false);
                            }}/>

            <ModifyReschedules loan={params.loan} show={showReschedules}
                               close={(reschedules) => {
                                   if (reschedules !== undefined) {
                                       params.setLoan({...params.loan, reschedules: reschedules})
                                   }
                                   setShowReschedules(false);
                               }}/>

            <ModifySecurities securities={securities} show={showSecurities}
                              close={(securities) => {
                                  if (securities !== undefined) {
                                      setSecurities(securities)
                                  }
                                  setShowSecurities(false);
                              }}/>

            <DeleteLoan show={showDelete} loan_id={params.loan.loan_id}
                        close={(deleted) => {
                            if (deleted) {
                                params.setLoan(initial_loan)
                            }
                            setShowDelete(false)
                        }}/>

            <div className="form">
                <div className="form_content no_header">
                    <div className="row m-0">
                        {/*branch name*/}
                        <div className="col-12 col-md-6 p-1">
                            <MySelect
                                change={(value: select_type) => handle_change('office_id', (value as number))}
                                title="Branch Name" placeholder="Select a loan branch" name="office_id" value={params.loan.office_id}
                                options={params.loanProps.offices.map((branch) => ({text: branch.office_name, value: branch.office_id}))}/>
                        </div>

                        {/*loan inspector*/}
                        <div className="col-12 col-md-6 p-1">
                            <MySelect
                                change={(value: select_type) => handle_change('inspector_id', (value as number))}
                                title="Loan Inspector" name="inspector_id" value={params.loan.inspector_id}
                                options={[
                                    {text: "Loan has no inspector", value: 0},
                                    ...params.loanProps.staffs.map((staff) => ({text: staff.user_name, value: staff.user_id}))
                                ]}/>
                        </div>

                        {/*client name*/}
                        <div className="col-12 p-1">
                            <MySelect
                                change={(value: select_type) => handle_change('client_id', (value as number))}
                                title="Client Name" name="client_id" value={params.loan.client_id}
                                search={(query) => {
                                    if (query.trim().length >= 3) {
                                        query_clients(query, [])
                                    }
                                }}
                                options={[
                                    {text: "Select a client", value: 0},
                                    ...params.loanProps.clients.map((client) =>
                                        ({text: client.client_name + " (" + client.main_contact + ")", value: client.client_id}))
                                ]}/>
                        </div>

                        {/*loan principal*/}
                        <div className="col-12 col-md-6 p-1">
                            <MyInput
                                placeholder="Loan Principal" title="Loan Principal" name="loan_principal" change={handle_change}
                                value={params.loan.loan_principal as string}/>
                        </div>

                        {/*loan type*/}
                        <div className="col-12 col-md-6 p-1">
                            <MySelect
                                change={(value: select_type) => handle_change('loan_type', parseInt(value as string))}
                                title="Loan Type" name="loan_type" value={params.loan.loan_type}
                                options={
                                    params.loanProps.loanTypes.map((loanType) =>
                                        ({text: loanType.type_name, value: loanType.type_id}))
                                }/>
                        </div>

                        {/*Loan rate*/}
                        <div className="col-6 col-md-3 p-1">
                            <MyInput
                                placeholder="Enter loan type rate" title="Interest Rate (%age)"
                                name="loan_rate" change={handle_change} value={params.loan.loan_rate as string}/>
                        </div>

                        {/*loan period*/}
                        <div className="col-6 col-md-3 p-1">
                            <MyInput
                                placeholder="Period (Months)" title="Period (Months)" name="loan_period"
                                change={handle_change} value={params.loan.loan_period as string}/>
                        </div>

                        {/*interest rate type*/}
                        <div className="col-12 col-md-6 p-1">
                            <MySelect
                                change={(value: select_type) => handle_change('rate_type', (value as string))}
                                title="Interest Rate Type" name="rate_type" value={params.loan.rate_type}
                                options={
                                    [
                                        {text: "Flat Interest", value: "simple"},
                                        {text: "Flat Interest(Only Interest Paid)", value: "simple_interest"},
                                        {text: "Reducing Balance", value: "compound"}
                                    ]
                                }/>
                        </div>

                        {/*payment start date*/}
                        <div className="col-12 col-md-6 p-1" style={{marginBottom: '15px'}}>
                            <MyDateInput
                                title="Date of initial payment" value={params.loan.start_date} name="start_date"
                                placeholder="Payment Start Date" change={handle_change}/>
                        </div>

                        {/*Payments Interval*/}
                        <div className="col-12 col-md-6 p-1">
                            <MySelect
                                change={(value: select_type) => handle_change('payment_interval', parseInt(value as string))}
                                title="Loan payment Interval" name="payment_interval" value={params.loan.payment_interval}
                                options={
                                    [
                                        {text: "Daily Payment", value: 1}, {text: "One week (7 days)", value: 7},
                                        {text: "Two weeks (14 days)", value: 14}, {text: "One Month (30 days)", value: 30}
                                    ]
                                }/>
                        </div>

                        {/*date disbursed*/}
                        <div className="col-12 col-md-6 p-1" style={{marginBottom: '15px'}}>
                            <MyDateInput
                                title="Date Disbursed" value={params.loan.date_disbursed} name="date_disbursed"
                                placeholder="Date Disbursed" change={handle_change}/>
                        </div>

                        {/*time disbursed*/}
                        <div className="col-12 col-md-6 p-1" style={{marginBottom: '15px'}}>
                            <MyDateInput
                                title="Time Disbursed" value={params.loan.time_disbursed} name="time_disbursed" type="time"
                                placeholder="Time Disbursed" change={handle_change}/>
                        </div>
                    </div>
                </div>

                <div className="form_footer">
                    <div className="row m-0">
                        <div className="col-12 px-1 py-0">
                            {/*modify charges*/}
                            <Button primary size='mini' onClick={() => setShowCharges(true)}
                                    icon='money bill alternate' disabled={params.loan.loan_id === 0}/>
                            {/*modify extensions*/}
                            <Button primary size='mini' onClick={() => setShowExtensions(true)}
                                    icon='list alternate' disabled={params.loan.loan_id === 0}/>
                            {/*modify guarantors*/}
                            <Button primary size='mini' onClick={() => setShowGuarantor(true)}
                                    icon='users' disabled={params.loan.loan_id === 0}/>
                            {/*modify payments*/}
                            <Button primary size='mini' onClick={() => setShowPayments(true)}
                                    icon='money bill alternate outline' disabled={params.loan.loan_id === 0}/>
                            {/*modify reschedules*/}
                            <Button primary size='mini' onClick={() => setShowReschedules(true)}
                                    icon='retweet' disabled={params.loan.loan_id === 0}/>
                            {/*modify securities*/}
                            <Button primary size='mini' onClick={() => setShowSecurities(true)}
                                    icon='warehouse' disabled={params.loan.loan_id === 0}/>


                            <Button positive floated="right" size='tiny' onClick={update_loan} content='Save Loan'
                                    icon='save' labelPosition={"left"}/>

                            <Button primary floated='right' size='mini' icon='info' disabled={params.loan.loan_id === 0}
                                    onClick={print_loan_info}/>

                            <Button primary floated='right' size='mini' icon='info circle' disabled={params.loan.loan_id === 0}
                                    onClick={params.showLoan}/>

                            <Button negative floated="right" size='mini' icon='trash' disabled={params.loan.loan_id === 0}
                                    onClick={() => setShowDelete(true)}/>

                            <Button positive floated="right" size='mini' icon='check square' disabled={params.loan.loan_id === 0}
                                    onClick={() => clear_loan_balance()}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
