import {Req} from "../../../utils/Req";
import {ToastsStore} from "react-toasts";
import Utils from "../../../utils/Utils";
import React from "react";
import {LoanInfo} from "./ShowLoan";
import {Loading} from "../../../utils/Components";
import {Button, Checkbox, Modal, Table} from "semantic-ui-react";

interface Transaction {
    type: '+' | '-'
    group: 'principal' | 'charges' | 'account'
    amount: number
    fee_name: string
    optional: 1 | 0
    checked: 1 | 0
}

export default function DisburseLoan(params: {
    success: (loanInfo: LoanInfo, path: string) => void, loanInfo: LoanInfo, close: () => void, show: boolean
}) {
    const [loading, setLoading] = React.useState({message: "", show: false})
    const [transactions, setTransactions] = React.useState(Array<Transaction>())

    const disburse_loan = () => {
        setLoading({message: "Disbursing loan, please wait", show: true})
        Req.disburse_loan({
            due_date: params.loanInfo.due_date,
            schedules: JSON.stringify(params.loanInfo.expected_schedule),
            start_date: params.loanInfo.start_date,
            loan_id: params.loanInfo.loan_id,
            transactions: JSON.stringify(transactions)
        })
            .then(response => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code")) {
                    if (response.data['code'] === -1) {
                        ToastsStore.info("Cashier till were already closed, no other transactions can be made")
                    } else if (response.data['code'] === 1) {
                        params.success({...params.loanInfo, loan_status: 'active'}, `${Req.BASE_URL} ${response.data.file}`)
                    } else if (response.data['code'] === 2) {
                        ToastsStore.error("Cashier has not yet allocated money for any transactions")
                    } else if (response.data['code'] === 3) {
                        ToastsStore.error(`You have only ${Utils.comma_number(response.data['available'])} left for loan disbursements`)
                    } else if (response.data['code'] === 4) {
                        ToastsStore.info("Loan was already disbursed")
                    }
                } else {
                    ToastsStore.error("Could not disburse please retry")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                ToastsStore.error("Could not update disburse loan please retry")
            })
    }

    const initialise_disburse = () => {
        setLoading({message: "Initialising disbursement, please wait", show: true})
        Req.disburse_loan_info({loan_id: params.loanInfo.loan_id})
            .then((response) => {
                setLoading({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setTransactions([
                        {
                            type: '+', amount: parseFloat(params.loanInfo.loan_principal),
                            fee_name: "Loan Principal", optional: 0, checked: 1, group: 'principal'
                        },
                        ...response.data.transactions
                    ])
                } else {
                    params.close()
                    ToastsStore.error("Could not initialise loan information, please wait")
                }
            })
            .catch(() => {
                setLoading({message: "", show: false})
                params.close()
                ToastsStore.error("Could not initialise loan information, please wait")
            })
    }

    const calculate_disburse = (): number => {
        let total = 0
        transactions.forEach((transaction) => {
            if (transaction.checked === 1) {
                total = transaction.type === "+" ? total + transaction.amount : total - transaction.amount
            }
        })
        return total
    }

    React.useEffect(() => {
        if (params.show) {
            initialise_disburse()
        }
    }, [params.show])

    return (
        <>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <Modal open={params.show} basic size='mini' centered={false}>
                <div className="modal_div">
                    <div className="form_header">Disburse Loan</div>

                    <div className="form_content">
                        <div className="table_container loan_schedule_container full_height">
                            <Table celled striped compact size='small' inverted color='grey' selectable>
                                <Table.Header fullWidth>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '40px'}}/>
                                        <Table.HeaderCell style={{width: '170px'}}>Transaction Type</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '120px'}}>Actual Amount</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        transactions.map((transaction, index) =>
                                            <Table.Row key={index}>
                                                <Table.Cell style={{width: '40px'}} textAlign="center">
                                                    {
                                                        transaction.optional === 1 &&
                                                        <Checkbox
                                                            checked={transaction.checked === 1}
                                                            onChange={(event, data) =>
                                                                setTransactions(
                                                                    transactions.map((_t, _i) =>
                                                                        _i === index ? {..._t, checked: data.checked ? 1 : 0} : _t
                                                                    ))
                                                            }/>
                                                    }

                                                </Table.Cell>
                                                <Table.Cell style={{width: '170px'}}>{transaction.fee_name}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>{Utils.comma_number(transaction.amount)}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>

                        <div className="disburse_summary">
                            <div className="title">Disbursement Amount</div>
                            <div className="amount">{Utils.comma_number(calculate_disburse())}</div>
                        </div>
                    </div>

                    <div className="form_footer">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button negative onClick={() => params.close()} content="Close Window" size='mini' fluid
                                        icon='close' labelPosition={"left"}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button positive onClick={disburse_loan} content="Disburse Loan" size='mini' fluid
                                        icon="money" labelPosition={"left"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
