import React from "react";
import {Button, Modal, Table} from "semantic-ui-react";
import Utils from "../../../../utils/Utils";
import {ModifiedLoan} from "./ModifyLoan";
import {MyDateInput, MyInput} from "../../../../utils/Components";
import {ToastsStore} from "react-toasts";

export interface ModifyReschedule {
    interest_added: number
    principal_added: number
    reschedule_interest: number
    reschedule_principal: number
    time_created: string
    date_created: string
}

export default function ModifyReschedules(params: { show: boolean, close: (reschedules?: ModifyReschedule[]) => void, loan: ModifiedLoan }) {
    const [reschedules, setReschedules] = React.useState(params.loan.reschedules)
    const [addReschedule, setAddReschedule] = React.useState({last_date: "", last_time: "", new_date: "", new_time: "", show: false})
    const handle_change = (name: string, value: string) => {
        setAddReschedule({...addReschedule, [name]: value})
    }
    const update_reschedule = () => {
        if (addReschedule.new_date === "") {
            ToastsStore.error("Enter a valid reschedule date")
        } else if (addReschedule.new_time === "") {
            ToastsStore.error("Enter a valid reschedule time")
        } else {
            if (addReschedule.last_date === "") {
                if (reschedules.filter((reschedule) => reschedule.date_created === addReschedule.new_date).length > 0) {
                    ToastsStore.info("Reschedule date was already saved")
                } else {
                    setReschedules([...reschedules, {
                        interest_added: 0, principal_added: 0, reschedule_interest: 0, reschedule_principal: 0,
                        time_created: addReschedule.new_time, date_created: addReschedule.new_date
                    }])
                }
            } else {
                setReschedules(
                    reschedules.map((reschedule) =>
                        reschedule.date_created !== addReschedule.last_date ? reschedule : {
                            ...reschedule, time_created: addReschedule.new_time, date_created: addReschedule.new_date
                        })
                )
            }
            setAddReschedule({...addReschedule, show: false})
        }
    }

    const update_reschedules = () => {
        params.close(reschedules)
    }

    React.useEffect(() => {
        setReschedules(params.loan.reschedules)
    }, [params.loan.reschedules])

    return (
        <>
            <Modal open={addReschedule.show} basic size='mini' centered={false}>
                <div className="modal_div modal_form">
                    <div className="form_content">
                        {
                            addReschedule.last_date !== "" &&
                            <MyInput placeholder="" name="" title="Current Reschedule Time"
                                     value={Utils.localise_date_time(`${addReschedule.last_date} ${addReschedule.last_time}`)}/>
                        }
                        <div className="mb-2">
                            <MyDateInput
                                title="New Reschedule Date" value={addReschedule.new_date} name="new_date"
                                placeholder="Payment Date" change={handle_change}/>
                        </div>

                        <MyDateInput
                            title="New Reschedule Time" value={addReschedule.new_time} name="new_time" type="time"
                            placeholder="Payment Time" change={handle_change}/>
                    </div>
                    <div className="form_footer">
                        <div className="row m-0">
                            <div className="col-6 pl-0 pr-1">
                                <Button negative size='mini' fluid icon='close' labelPosition={"left"} content="Close Window"
                                        onClick={() => setAddReschedule({...addReschedule, show: false})}/>
                            </div>
                            <div className="col-6 pl-1 pr-0">
                                <Button positive onClick={update_reschedule} content="Save Reschedule" size='mini'
                                        fluid icon="save" labelPosition={"left"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal open={params.show} basic size='small' centered={false} onClose={() => params.close()}>
                <div className='modify_loan reschedules'>

                    <div className="table_container" style={{height: 'calc(100% - 45px)'}}>
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '70px'}}/>
                                    <Table.HeaderCell style={{width: '180px'}}>Reschedule Date</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '115px'}}>Principal Before</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '115px'}}>Principal Added</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '110px'}}>Interest Before</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '110px'}}>Interest Added</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    reschedules.map((reschedule, index) =>
                                        <Table.Row
                                            key={reschedule.date_created}
                                            onClick={() => {
                                                setAddReschedule({
                                                    last_time: reschedule.time_created,
                                                    new_time: reschedule.time_created,
                                                    last_date: reschedule.date_created,
                                                    new_date: reschedule.date_created,
                                                    show: true
                                                })
                                            }}>
                                            <Table.Cell style={{width: '70px'}}>
                                                <Button
                                                    primary size='mini' icon='trash' compact
                                                    onClick={() => {
                                                        setReschedules(
                                                            reschedules.filter((_, _index) => _index !== index)
                                                        )
                                                    }}/>
                                                <Button
                                                    primary size='mini' icon='edit' compact
                                                    onClick={() => {
                                                        setAddReschedule({
                                                            last_time: reschedule.time_created,
                                                            new_time: reschedule.time_created,
                                                            last_date: reschedule.date_created,
                                                            new_date: reschedule.date_created,
                                                            show: true
                                                        })
                                                    }}/>
                                            </Table.Cell>
                                            <Table.Cell style={{width: '180px'}}>
                                                {Utils.localise_date_time(`${reschedule.date_created} ${reschedule.time_created}`)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '115px'}}>
                                                {Utils.comma_number(reschedule.reschedule_principal)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '115px'}}>
                                                {Utils.comma_number(reschedule.principal_added)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '110px'}}>
                                                {Utils.comma_number(reschedule.reschedule_interest)}
                                            </Table.Cell>
                                            <Table.Cell style={{width: '110px'}}>
                                                {Utils.comma_number(reschedule.interest_added)}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>

                    <div className="row mx-0 mt-1">
                        <div className="col-6 col-md-3 offset-md-3 p-1">
                            <Button fluid size='tiny' positive content='Add Reschedule' icon='add' labelPosition={"left"}
                                    onClick={() => {
                                        setAddReschedule({last_date: "", last_time: "", new_date: "", new_time: "", show: true})
                                    }}/>
                        </div>
                        <div className="col-6 col-md-3 p-1">
                            <Button fluid size='tiny' primary onClick={update_reschedules} content='Update Reschedules'
                                    icon='save' labelPosition={"left"}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
