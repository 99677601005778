import {Table} from "semantic-ui-react";
import Utils from "../../../../../utils/Utils";
import React from "react";
import {LoanInfo} from "../../ShowLoan";

export interface ActualSchedule {
    schedule_id: number
    payment_date: string
    actual_principal: number
    actual_interest: number
    total_payment: number
}

export function PaymentScheduleActual(params: { loanInfo: LoanInfo, setLoanInfo: (loanInfo: LoanInfo) => void }) {
    return (
        <div className="row mx-0 h-100">
            <div className="col-12 p-1 h-100">
                <div className="table_container loan_schedule_container full_height">
                    <Table celled striped compact size='small' inverted color='grey' selectable>
                        <Table.Header fullWidth>
                            <Table.Row>
                                <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '120px'}}>Payment Date</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Principal Expected</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Interest Expected</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Total Expected</Table.HeaderCell>
                                <Table.HeaderCell style={{width: '150px'}}>Balance After</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {
                                params.loanInfo.actual_schedule
                                    .map((schedule, index) =>
                                        <Table.Row key={index}>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.localise_date(schedule.payment_date)}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{Utils.comma_number(schedule.actual_principal)}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{Utils.comma_number(schedule.actual_interest)}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{Utils.comma_number(schedule.total_payment)}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>
                                                {Utils.comma_number(params.loanInfo.expected_schedule[index].balance)}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                            }
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </div>
    )
}
