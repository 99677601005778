import {MyDateInput, MyInput, SelectData} from "../../../utils/Components";
import Utils from "../../../utils/Utils";
import React from "react";
import {ToastsStore} from "react-toasts";
import {Req} from "../../../utils/Req";
import {ShowLoan} from "../container/ShowLoan";
import {MultiSelect} from "../../../utils/MultiSelect";
import {Button, Table} from "semantic-ui-react";

export type due_periods = -1 | -7 | 0 | 7 | 14 | 28 | 90 | 30

interface DueLoan {
    branch: string
    client: string
    date_disbursed: string
    due_date: string
    last_amount: number
    last_date: number
    total_balance: number
    loan_id: number
    loan_principal: number
    loan_rate: number
    overdue: number
}

export default function DueLoans(params: { branches: { select: SelectData[], selected: number[] }, period: due_periods }) {
    const [loading, setLoading] = React.useState(false)
    const [search, setSearch] = React.useState({name: '', branches: params.branches.selected, min: Utils.today(), max: Utils.today(), loan_id: ''})
    const handle_search_change = (name: string, value: string | number | Array<number | string>) => {
        setSearch({...search, [name]: value})
    }

    const [dueLoans, setDueLoans] = React.useState<Array<DueLoan>>([])
    const [showLoan, setShowLoanInfo] = React.useState({show: false, loan_id: 0})

    const search_loans = () => {
        if (search.branches.length === 0) {
            ToastsStore.error("No branches selected, reload page if branches select is empty")
            return
        }
        setLoading(true)
        Req.get_loans_listing_due({
            branches: JSON.stringify(search.branches), name: search.name.trim(), max_date: search.max,
            min_date: search.min, period: params.period, loan_id: search.loan_id.trim()
        })
            .then((response) => {
                setLoading(false)
                if (response.data.hasOwnProperty("loans")) {
                    setDueLoans(response.data.loans)
                } else {
                    ToastsStore.error("Failed to get due loans")
                }
            })
            .catch(() => {
                setLoading(false)
                ToastsStore.error("Failed to get due loans")
            })
    }

    React.useEffect(() => {
        setDueLoans([])
    }, [params.period])

    return (
        <>
            {showLoan.show && <ShowLoan close={() => setShowLoanInfo({loan_id: 0, show: false})} loan_id={showLoan.loan_id}/>}

            {
                !showLoan.show &&
                <>
                    <div className="content_bar">
                        <div className="search_bar">
                            <div>
                                <MultiSelect
                                    multiple={true} placeholder="Select offices" items={params.branches.select} selected={search.branches}
                                    change={(value) => handle_search_change('branches', value)}/>
                            </div>
                            {
                                params.period === -1 &&
                                <>
                                    <div>
                                        <MyDateInput
                                            title="" value={search.min} name="min" placeholder="Minimum Date"
                                            change={handle_search_change} maxDate={search.max}/>
                                    </div>
                                    <div>
                                        <MyDateInput
                                            title="" value={search.max} name="max" placeholder="Maximum Date"
                                            change={handle_search_change} minDate={search.min}/>
                                    </div>
                                </>
                            }
                            <div>
                                <MyInput
                                    placeholder="Search by client name" title="" name="name" value={search.name}
                                    change={handle_search_change} enter={search_loans}/>
                            </div>
                            <div>
                                <MyInput
                                    placeholder="Search by loan no" title="" name="loan_id" value={search.loan_id}
                                    change={handle_search_change} enter={() => search_loans()}/>
                            </div>
                        </div>
                        <div className="button_bar">
                            <Button floated='right' size='mini' icon='search' disabled={loading} loading={loading}
                                    onClick={search_loans} content="Search" primary labelPosition="left"/>
                        </div>
                    </div>

                    <div className="table_container container_no_footer">
                        <Table celled striped compact size='small' selectable inverted color='grey'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '40px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">Loan</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '140px'}}>Branch Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '150px'}}>Client Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Date Disbursed</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Due Date</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '70px'}}>Overdue</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '70px'}} textAlign="center">Rate</Table.HeaderCell>
                                    <Table.HeaderCell className="amount_row">Loan Principal</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '130px'}}>Total Balance</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '90px'}}>Received</Table.HeaderCell>
                                    <Table.HeaderCell className="amount_row">Amount Paid</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    dueLoans.map((loan, index) =>
                                        <Table.Row key={loan.loan_id}>
                                            <Table.Cell style={{width: '40px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                            <Table.Cell style={{width: '50px'}} textAlign="center">{loan.loan_id}</Table.Cell>
                                            <Table.Cell style={{width: '140px'}}>{loan.branch}</Table.Cell>
                                            <Table.Cell style={{width: '150px'}}>{loan.client}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.localise_date(loan.date_disbursed)}</Table.Cell>
                                            <Table.Cell style={{width: '120px'}}>{Utils.localise_date(loan.due_date)}</Table.Cell>
                                            <Table.Cell style={{width: '70px'}}>{loan.overdue} days</Table.Cell>
                                            <Table.Cell style={{width: '70px'}} textAlign="center">
                                                {loan.loan_rate.toFixed(2)} %
                                            </Table.Cell>
                                            <Table.Cell className="amount_row">{Utils.comma_number(loan.loan_principal)}</Table.Cell>
                                            <Table.Cell style={{width: '130px'}}>{Utils.comma_number(loan.total_balance)}</Table.Cell>
                                            <Table.Cell style={{width: '90px'}}>
                                                {loan.last_amount > 0 ? `${loan.last_date} days ago` : ''}
                                            </Table.Cell>
                                            <Table.Cell className="amount_row">
                                                {loan.last_amount > 0 ? Utils.comma_number(loan.last_amount) : ''}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </>
            }
        </>
    )
}
