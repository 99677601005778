import React from "react";
import Utils, {ConfirmAction, initial_confirm} from "../../../../../utils/Utils";
import {ToastsStore} from "react-toasts";
import {Req} from "../../../../../utils/Req";
import {LoanInfo} from "../../ShowLoan";
import {Loading, MyDateInput, MyInput, MyLabel} from "../../../../../utils/Components";
import {Button, Confirm, Form, Table, TextArea} from "semantic-ui-react";

export interface Penalty {
    penalty_id: number
    penalty_reason: string
    penalty_amount: string | number
    date_penalised: string
    penalty_balance: string | number
    penalised_by: string
}

export default function LoanPenalties(params: { loanInfo: LoanInfo, setLoanInfo: (loanInfo: LoanInfo) => void }) {
    const [confirm, setConfirm] = React.useState<ConfirmAction>(initial_confirm)
    const [loader, setLoader] = React.useState({message: "", show: false})

    const initial_penalty: Penalty = {
        penalty_id: 0, penalised_by: "", penalty_balance: "", penalty_amount: "", penalty_reason: "", date_penalised: Utils.today()
    }

    const [penalty, setPenalty] = React.useState(initial_penalty)
    const handle_penalty = (name: string, value: string) => {
        setPenalty({...penalty, [name]: value})
    }
    const add_penalty = () => {
        if (!Utils.is_valid_number(penalty.penalty_amount as string) || parseFloat(penalty.penalty_amount as string) < 0) {
            ToastsStore.error("Enter valid penalty added")
        } else {
            setConfirm({
                ...confirm,
                'open': true,
                'content': 'Are you sure you want to proceed with adding a penalty of  '
                    + Utils.comma_number(parseFloat(penalty.penalty_amount as string)) + ' to this loan?',
                'onConfirm': () => {
                    setConfirm({...confirm, 'open': false})
                    setLoader({message: "Adding loan penalty, please wait", show: true})

                    Req.add_penalty({
                        date_penalised: penalty.date_penalised, penalty_amount: penalty.penalty_amount as string,
                        penalty_reason: penalty.penalty_reason, loan_id: params.loanInfo.loan_id
                    })
                        .then((response) => {
                            setLoader({message: "", show: false})
                            if (response.data.hasOwnProperty("code")) {
                                if (response.data.code === 1) {
                                    ToastsStore.success("Successfully added penalty to loan")
                                    setPenalty(initial_penalty)
                                    params.setLoanInfo(Utils.format_loan_data(response))
                                } else if (response.data.code === 2) {
                                    ToastsStore.error("Loan not found")
                                } else if (response.data.code === 3) {
                                    ToastsStore.error("Loan was already cleared, you cannot add a penalty")
                                }
                            } else {
                                ToastsStore.error("Could not add penalty to loan, please retry")
                            }
                        })
                        .catch(() => {
                            setLoader({message: "", show: false})
                            ToastsStore.error("Could not add penalty to loan, please retry")
                        })
                },
                'onCancel': () => setConfirm({...confirm, 'open': false})
            })
        }
    }

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} centered={false} size='mini'
                     cancelButton={confirm.cancelButton} confirmButton={confirm.confirmButton} content={confirm.content}/>

            <div className="row mx-0 h-100">
                <div className="col-12 p-1 col-md-8 h-100">
                    <div className="table_container full_height">
                        <Table celled striped compact size='small' inverted color='grey' selectable style={{width: '800px'}}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                    <Table.HeaderCell className="time_row">Date Penalised</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '170px'}}>Accountant Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Penalty Amount</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Penalty Balance</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '250px'}}>Penalty Reason</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    params.loanInfo.others.penalties
                                        .map((transaction, index) =>
                                            <Table.Row key={transaction.penalty_id}>
                                                <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell className="time_row">
                                                    {Utils.localise_date(transaction.date_penalised)}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '170px'}}>{transaction.penalised_by}</Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>
                                                    {Utils.comma_number(transaction.penalty_amount as number)}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>
                                                    {Utils.comma_number(transaction.penalty_balance as number)}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '250px'}}>{transaction.penalty_reason}</Table.Cell>
                                            </Table.Row>
                                        )
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>

                <div className="col-12 p-1 col-md-4 h-100">
                    <div className="form">
                        <div className='form_content no_header'>
                            <MyInput placeholder="Enter penalty amount added" title="Enter penalty amount added" name="penalty_amount"
                                     change={handle_penalty} value={penalty.penalty_amount as string}/>

                            <MyDateInput
                                title="Date penalty added" value={penalty.date_penalised} placeholder="Date penalty added"
                                name="date_penalised" change={handle_penalty} maxDate={Utils.today()} minDate={params.loanInfo.start_date}/>

                            <div style={{marginTop: '15px'}}>
                                <MyLabel title="Reason for adding penalty"/>
                                <Form>
                                    <TextArea
                                        placeholder='Enter reason as to why you have added a penalty to loan'
                                        rows={5} value={penalty.penalty_reason}
                                        onChange={((event, data) => {
                                            handle_penalty('penalty_reason', data.value as string)
                                        })}/>
                                </Form>
                            </div>
                        </div>

                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <Button negative onClick={() => setPenalty(initial_penalty)} labelPosition={"left"} icon='close'
                                            content="Clear Data" size='mini' fluid
                                            disabled={params.loanInfo.payment_summary.principal_balance === 0}/>
                                </div>
                                <div className="col-6 pl-1 pr-0">
                                    <Button positive onClick={add_penalty} content="Save Penalty" size='mini' fluid
                                            icon="save" labelPosition={"left"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
