import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import {ConnectedRouter} from "connected-react-router";
import {PersistGate} from "redux-persist/integration/react";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from 'react-toasts'

import {createBrowserHistory} from "history";
import reportWebVitals from "./reportWebVitals";

import {Route, Switch} from "react-router";
import Firebase from "./system/nav/Firebase";
import System from "./system/System";
import NoMatch from './system/NoMatch';

import {persistedStore, store} from "./system/store/store";

import 'semantic-ui-css/semantic.min.css'
import 'react-phone-input-2/lib/style.css';

import './assets/css/index.scss';
import firebase from "firebase/compat";

const render = () => {
    if (!firebase.apps.length) {
        firebase.initializeApp({
            apiKey: "AIzaSyA63Nl28w2HphP7Z0IEHzMWClp6zIb06lo",
            authDomain: "misc-applications.firebaseapp.com",
            databaseURL: "https://misc-applications.firebaseio.com",
            projectId: "misc-applications",
            storageBucket: "misc-applications.appspot.com",
            messagingSenderId: "797043390702",
            appId: "1:797043390702:web:b06c06f1addcef10bcdbff",
            measurementId: "G-HHVSH870S6"
        });
    }

    ReactDOM.render(
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistedStore}>
                <ConnectedRouter history={createBrowserHistory()}>
                    <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER}/>
                    {firebase.messaging.isSupported() && <Firebase/>}

                    <Switch>
                        <Route exact path="/" component={System}/>
                        <Route component={NoMatch}/>
                    </Switch>
                </ConnectedRouter>
            </PersistGate>
        </Provider>,
        document.getElementById('root')
    )
};
render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
