import {Button, Modal, Table} from "semantic-ui-react";
import React from "react";
import {ModifiedLoan} from "./ModifyLoan";
import {LoanProps} from "../../container/ShowLoan";
import Utils from "../../../../utils/Utils";
import {useAppSelector} from "../../../../store/hooks";
import {getStaff} from "../../../../store/slices/userSlice";
import {MyDateInput, MyInput, MyLabel, MySelect, select_type} from "../../../../utils/Components";
import PhoneInput from "react-phone-input-2";
import {ToastsStore} from "react-toasts";
import moment from "moment";

export interface ModifyPayment {
    date_paid: string
    payment_id: number
    payment_status: "rejected" | 'accepted'
    time_paid: string
    total_paid: string | number
    cashier_id: number
    payer_name: string
    payer_contact: string
}

export default function ModifyPayments(params: {
    show: boolean, close: (payments?: ModifyPayment[]) => void, loan: ModifiedLoan, loanProps: LoanProps
}) {
    const user = useAppSelector(getStaff)
    const [payments, setPayments] = React.useState(params.loan.payments)
    const [paymentIndex, setPaymentIndex] = React.useState(-1)

    const clients = params.loanProps.clients.filter((client) => client.client_id === params.loan.client_id)
    const initial_payment: ModifyPayment = {
        cashier_id: user.staff.user_id, date_paid: "", payment_id: 0, payment_status: "accepted", time_paid: "", total_paid: "",
        payer_contact: clients.length === 1 ? clients[0].main_contact.toString() : '',
        payer_name: clients.length === 1 ? clients[0].client_name.toString() : '',
    }
    const [payment, setPayment] = React.useState(initial_payment)
    const handle_payment = (name: string, value: string | number) => {
        setPayment({...payment, cashier_id: user.staff.user_id, [name]: value})
    }

    const save_payment = () => {
        if (!Utils.is_valid_number(Utils.strip_commas(payment.total_paid))) {
            ToastsStore.error("Enter valid payment amount")
        } else if (parseFloat(Utils.strip_commas(payment.total_paid)) < 0) {
            ToastsStore.error("Minimum payment cannot be less than 0")
        } else if (Utils.invalid_full_name(payment.payer_name)) {
            ToastsStore.error("Enter a valid payer name")
        } else if (Utils.invalid_contact(payment.payer_contact)) {
            ToastsStore.error("Enter a valid payer contact")
        } else if (payment.date_paid === "") {
            ToastsStore.error("Select a date of payment")
        } else if (payment.time_paid === "") {
            ToastsStore.error("Select a time of payment")
        } else {
            if (paymentIndex === -1) {
                setPayments([{...payment, total_paid: Utils.strip_commas(payment.total_paid)}, ...payments])
            } else {
                setPayments(payments.map((_payment, index) =>
                    index === paymentIndex ? {...payment, total_paid: Utils.strip_commas(payment.total_paid)} : _payment))
            }
            setPayment(initial_payment)
            setPaymentIndex(-1)
        }
    }

    const save_payments = () => {
        params.close(payments)
    }

    React.useEffect(() => {
        setPayments(params.loan.payments)
    }, [params.loan.payments])

    return (
        <Modal open={params.show} basic size='small' centered={false} onClose={() => params.close()}>
            <div className='modal_form modify_loan payments row m-0'>
                <div className='col-7 p-1 h-100'>
                    <div className="table_container" style={{height: 'calc(100% - 45px)'}}>
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{width: '50px'}} textAlign="center">No</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '200px'}}>Date Paid</Table.HeaderCell>
                                    <Table.HeaderCell style={{width: '120px'}}>Amount Paid</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    payments
                                        .sort((a, b) => {
                                            return moment(`${b.date_paid} ${b.time_paid}`).valueOf()
                                                - moment(`${a.date_paid} ${a.time_paid}`).valueOf()
                                        })
                                        .map((_payment, index) =>
                                            <Table.Row key={index}
                                                       onClick={() => {
                                                           setPaymentIndex(index)
                                                           setPayment({..._payment, payer_contact: `+${_payment.payer_contact}`})
                                                       }}>
                                                <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell style={{width: '200px'}}>
                                                    {Utils.localise_date_time(`${_payment.date_paid} ${_payment.time_paid}`)}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '120px'}}>
                                                    {Utils.comma_number(_payment.total_paid as number)}
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                }
                            </Table.Body>
                        </Table>
                    </div>

                    <div className="row mx-0 mt-1">
                        <div className="col-12 col-md-8 offset-md-2 p-1">
                            <Button fluid size='tiny' primary onClick={save_payments} content='Update Payments'
                                    icon='save' labelPosition={"left"}/>
                        </div>
                    </div>
                </div>

                <div className='col-5 p-1 h-100'>
                    <div className="form">
                        <div className="form_content no_header">
                            <MySelect
                                change={(value: select_type) => handle_payment('cashier_id', (value as number))}
                                title="Payment received by" name="cashier_id" value={payment.cashier_id} disabled={true}
                                options={params.loanProps.staffs.map((user) => ({text: user.user_name, value: user.user_id}))}/>

                            <MyInput placeholder="Enter total amount paid" name="total_paid" title="Total Amount Paid"
                                     value={Utils.comma_input(payment.total_paid).toString()} change={handle_payment}/>

                            <MyInput placeholder="Enter name of person who paid" name="payer_name" title="Paid By"
                                     value={payment.payer_name} change={handle_payment}/>

                            <div>
                                <MyLabel title="Payer Contact"/>
                                <PhoneInput
                                    countryCodeEditable={false} containerStyle={{marginBottom: '15px'}}
                                    country={'ug'} value={payment.payer_contact} onlyCountries={['ug']}
                                    onChange={(value) => handle_payment('payer_contact', value)}/>
                            </div>

                            <MySelect
                                change={(value) => handle_payment('payment_status', value as string)}
                                title="Payment Status" name='payment_status' value={payment.payment_status}
                                options={[{text: 'Payment Accepted', value: 'accepted'}, {text: 'Payment Rejected', value: 'rejected'}]}/>

                            <div className="mt-2">
                                <MyDateInput
                                    title="Payment Date" value={payment.date_paid} name="date_paid"
                                    placeholder="Payment Date" change={handle_payment}/>
                            </div>

                            <div className="mt-2">
                                <MyDateInput
                                    title="Payment Time" value={payment.time_paid} name="time_paid" type="time"
                                    placeholder="Payment Time" change={handle_payment}/>
                            </div>
                        </div>

                        <div className="form_footer">
                            <div className="row m-0">
                                <div className="col-6 pl-0 pr-1">
                                    <Button negative size='mini' fluid icon='close' labelPosition={"left"} content="Clear Data"
                                            onClick={() => {
                                                setPayment(initial_payment)
                                                setPaymentIndex(-1)
                                            }}/>
                                </div>
                                <div className="col-6 pl-1 pr-0">
                                    <Button positive onClick={save_payment} content="Save Payment" size='mini'
                                            fluid icon="save" labelPosition={"left"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
