import Utils from "../../../utils/Utils";
import {MyDateInput} from "../../../utils/Components";
import {Button, Modal} from "semantic-ui-react";
import React from "react";

export interface CriteriaTrialBalance {
    min_date: string
    max_date: string
}

export default function TrialBalanceCriteria(params: {
    show: boolean, close: () => void, criteria: CriteriaTrialBalance, handle_change: (criteria: CriteriaTrialBalance) => void, generate: () => void
}){
    const handle_change = (name: string, value: string | number | Array<number | string>) => {
        params.handle_change({...params.criteria, [name]: value})
    }

    return(
        <Modal open={params.show} basic size='mini' centered={false} onClose={params.close} dimmer='blurring'>
            <div className='reports_modal'>
                <div className="row m-0 reports_criteria">
                    <div className="book_content">
                        <div className="row m-0">
                            <div className="col-12 p-1">
                                <MyDateInput
                                    title="Start trial balance Date" value={params.criteria.min_date} name="min_date"
                                    maxDate={params.criteria.max_date} placeholder="Select start date" change={handle_change}/>
                            </div>
                            <div className="col-12 p-1">
                                <MyDateInput
                                    title="End trial balance date" value={params.criteria.max_date} name="max_date" maxDate={Utils.today()}
                                    minDate={params.criteria.min_date} placeholder="Select end date" change={handle_change}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="outer_button">
                <div className="row m-0">
                    <div className="col-6 pl-0 pr-1">
                        <Button negative onClick={params.close} content="Close Window" size='mini' fluid icon='close' labelPosition="left"/>
                    </div>
                    <div className="col-6 pl-1 pr-0">
                        <Button positive onClick={params.generate} content="Load Report" size='mini' fluid icon="table" labelPosition="left"/>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
