import React from "react";
import Utils from "../../../utils/Utils";
import {Table} from "semantic-ui-react";

interface Statement {
    amount: number
    date: string
    name: string
    user: string
    account_name: number | string
    branch_name: string
}

export default function IncomesReport(params: { transactions: any }) {
    const [statements, setStatements] = React.useState<Statement[]>([])

    React.useEffect(() => {
        if (params.transactions.hasOwnProperty("rp")) {
            setStatements(params.transactions.rp)
        }
    }, [params.transactions])

    return (
        <>
            <Table celled striped compact size='small' inverted color='grey' selectable className="has_total">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '130px'}}>Branch Name</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '220px'}}>Account No.</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '150px'}}>Income Name</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '160px'}}>Collection Date</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '200px'}}>Received By</Table.HeaderCell>
                        <Table.HeaderCell style={{width: '150px'}}>Income Amount</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        statements.map((transaction, index) =>
                            (index < statements.length - 1) ?
                                <>
                                    <Table.Row key={index}>
                                        <Table.Cell style={{width: '50px'}} textAlign="center">{index + 1}</Table.Cell>
                                        <Table.Cell style={{width: '130px'}}>{transaction.branch_name}</Table.Cell>
                                        <Table.Cell style={{width: '220px'}}>{transaction.account_name}</Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>{transaction.name}</Table.Cell>
                                        <Table.Cell style={{width: '160px'}}>{Utils.localise_date_time(transaction.date)}</Table.Cell>
                                        <Table.Cell style={{width: '200px'}}>{transaction.user}</Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>{Utils.comma_number(transaction.amount)}</Table.Cell>
                                    </Table.Row>
                                </>
                                :
                                <>
                                    <Table.Row key={index}>
                                        <Table.Cell style={{width: '900px'}} colSpan={4}>{transaction.name}</Table.Cell>
                                        <Table.Cell style={{width: '150px'}}>{Utils.comma_number(transaction.amount)}</Table.Cell>
                                    </Table.Row>
                                </>
                        )
                    }
                </Table.Body>
            </Table>
        </>
    )
}
