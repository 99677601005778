import {Button, Modal, Table} from "semantic-ui-react";
import React from "react";
import Utils from "../../../utils/Utils";
import {MyInput, MyLabel} from "../../../utils/Components";
import PhoneInput from "react-phone-input-2";
import {Req} from "../../../utils/Req"
import {ToastsStore} from "react-toasts";

export interface Creditor {
    creditor_id: number
    creditor_name: string
    creditor_contact: string
    creditor_email: string
}

export const initial_creditor: Creditor = {creditor_contact: "", creditor_email: "", creditor_id: 0, creditor_name: ""}

export function Creditors(props: { close: (creditors?: Array<Creditor>) => void, show: boolean, creditors: Array<Creditor> }) {
    const [saving, setSaving] = React.useState(false)
    const [creditors, setCreditors] = React.useState<Array<Creditor>>([])
    const [creditor, setCreditor] = React.useState(initial_creditor)
    const handle_change = (name: string, value: string) => {
        setCreditor({...creditor, [name]: value})
    }

    const save_creditor = () => {
        if (Utils.invalid_full_name(creditor.creditor_name.trim())) {
            ToastsStore.error("Enter a valid creditor name")
        } else if (creditor.creditor_email.trim().length > 0 && Utils.invalid_email(creditor.creditor_email.trim())) {
            ToastsStore.error("Enter a valid creditor email")
        } else if (creditor.creditor_contact.trim().length > 0 && Utils.invalid_contact(creditor.creditor_contact.trim())) {
            ToastsStore.error("Enter a valid creditor contact")
        } else {
            setSaving(true)
            Req.save_system_creditor({
                creditor_contact: creditor.creditor_contact.trim(), creditor_email: creditor.creditor_email.trim(),
                creditor_id: creditor.creditor_id, creditor_name: creditor.creditor_name.trim()
            })
                .then((response) => {
                    setSaving(false)
                    if (response.data.hasOwnProperty("code")) {
                        if (response.data.code === 1) {
                            let _creditor: Creditor = {...creditor, creditor_id: response.data.creditor_id}
                            if (creditor.creditor_id === 0) {
                                setCreditors([_creditor, ...creditors])
                            } else {
                                setCreditors(creditors.map((__creditor) =>
                                    __creditor.creditor_id === creditor.creditor_id ? _creditor : __creditor
                                ))
                            }
                            setCreditor(_creditor)
                            ToastsStore.success("Successfully saved creditor")
                        } else if (response.data['code'] === 2) {
                            ToastsStore.error("Email address is already in use")
                        } else if (response.data['code'] === 3) {
                            ToastsStore.error("Mobile Contact is already in use")
                        }
                    } else {
                        ToastsStore.error("Could not save creditor, please retry")
                    }
                })
                .catch(() => {
                    setSaving(false)
                    ToastsStore.error("Could not save creditor, please retry")
                })
        }
    }

    React.useEffect(() => {
        setCreditors(props.creditors);
    }, [props.creditors])

    return (
        <>
            <Modal open={props.show} basic size='small' closeOnDocumentClick={true} centered={false}
                   onClose={() => props.close(creditors)}>
                <div className="modal_div creditor_modal">
                    <div className="row m-0 h-100">
                        <div className="col-12 col-md-7 p-1 h-100">
                            <div className="table_container full_height">
                                <Table celled striped compact size='small' inverted color='grey' selectable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '200px'}}>Creditor Name</Table.HeaderCell>
                                            <Table.HeaderCell style={{width: '100px'}}>Contact</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            creditors
                                                .map((creditor, index) =>
                                                    <Table.Row key={creditor.creditor_id} onClick={() => setCreditor(creditor)}>
                                                        <Table.Cell style={{width: '50px'}} textAlign="center">
                                                            {Utils.row_number(index)}
                                                        </Table.Cell>
                                                        <Table.Cell style={{width: '200px'}}>{creditor.creditor_name}</Table.Cell>
                                                        <Table.Cell style={{width: '100px'}}>{creditor.creditor_contact}</Table.Cell>
                                                    </Table.Row>
                                                )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>

                        <div className="col-12 col-md-5 p-1 h-100">
                            <div className="form">
                                <div className="form_content no_header">
                                    <MyInput
                                        placeholder="Creditor name" title="Creditor name" name="creditor_name"
                                        value={creditor.creditor_name} change={handle_change}/>

                                    <MyInput
                                        placeholder="Creditor Email Address" title="Creditor Email Address" name="creditor_email"
                                        value={creditor.creditor_email} change={handle_change}/>

                                    <MyLabel title="Creditor Contact"/>
                                    <PhoneInput
                                        countryCodeEditable={false} containerStyle={{marginBottom: '15px'}}
                                        country={'ug'} value={creditor.creditor_contact} onlyCountries={['ug']}
                                        onChange={((value) => {
                                            handle_change('creditor_contact', value)
                                        })}/>
                                </div>
                                <div className="form_footer">
                                    <div className="row mx-0">
                                        <div className="col-6 pl-0 pr-1">
                                            <Button negative onClick={() => setCreditor(initial_creditor)} disabled={saving}
                                                    fluid size='tiny' content="Clear Data" icon="close" labelPosition="left"/>
                                        </div>
                                        <div className="col-6 pl-1 pr-0">
                                            <Button positive onClick={save_creditor} loading={saving} disabled={saving}
                                                    fluid size='tiny' content="Save Creditor" icon="save" labelPosition="left"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>)
}
