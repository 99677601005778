import React from "react";
import {Button, Modal, Tab, Table} from "semantic-ui-react";
import Utils from "../../../utils/Utils";
import {Loading, MyDateInput, MySelect, PDFReader, select_type, SelectData} from "../../../utils/Components";
import {Rights} from "../../admin/users/rights/AccessRights";
import {Req} from "../../../utils/Req";
import {useAppSelector} from "../../../store/hooks";
import {getStaff} from "../../../store/slices/userSlice";

export interface LoanSummary {
    client_name: string
    due_date: string
    interest_paid: number
    loan_id: number
    main_contact: number
    payment_date: string
    pending_interest: number
    pending_principal: number
    principal_paid: number
    loan_principal: number
}

export default function Calendar(props: { offices: Array<SelectData> }) {
    const user = useAppSelector(getStaff)

    const [calender, setCalender] = React.useState<Array<Array<{ date: string }>>>([])
    const [loading, setLoading] = React.useState({message: "Loading events, please wait", show: false})
    const [searching, setSearching] = React.useState(true)
    const [showReader, setShowReader] = React.useState({display: false, source: ""})

    const [search, setSearch] = React.useState({
        max_date: Utils.today(new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0)),
        min_date: Utils.today(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)),
        office_id: Utils.get_initial_office(user.roles.user_roles, Rights.view_loans)
    })
    const handle_change = (name: string, value: string | number) => {
        let _search = {...search, [name]: value}
        if (name === 'min_date') {
            const date = new Date(_search.min_date.replace(/-/g, "/"))
            date.setMonth(date.getMonth() + 1)
            date.setDate(date.getDate() - 1)
            _search = {..._search, max_date: Utils.today(date)}

        } else if (name === 'max_date') {
            const date = new Date(_search.max_date.replace(/-/g, "/"))
            date.setMonth(date.getMonth() - 1)
            date.setDate(date.getDate() + 1)
            _search = {..._search, min_date: Utils.today(date)}
        }
        setSearch({..._search})
    }

    const pdf_summary = () => {
        setLoading({...loading, show: true})
        Req.pdf_calendar({max_date: search.max_date, min_date: search.min_date, office_id: search.office_id})
            .then((response) => {
                setLoading({...loading, show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setShowReader({display: true, source: Req.BASE_URL + response.data.pdf})
                }
            })
            .catch(() => {
                setLoading({...loading, show: false})
            })
    }

    const get_weekly_days = () => {
        const tables: Array<Array<{ date: string }>> = []

        const expiry_date = new Date(search.max_date.replace(/-/g, "/"))
        const current_date = new Date(search.min_date.replace(/-/g, "/"))

        let start_day = current_date.getDay()
        do {
            let index = 0
            const table: Array<{ date: string }> = [];
            while (index < 7) {
                if (index >= start_day) {
                    if (current_date <= expiry_date) {
                        table.push({date: Utils.today(current_date)})
                    } else {
                        table.push({date: ""})
                    }
                    current_date.setDate(current_date.getDate() + 1)
                } else {
                    table.push({date: ""})
                }
                index++
            }
            start_day = 0
            tables.push(table)
        } while (current_date <= expiry_date)
        setCalender(tables)
    }

    const get_date = (date: string) => {
        return (new Date(date.replace(/-/g, "/"))).getDate()
    }

    const get_month = (date: string) => {
        return (new Date(date.replace(/-/g, "/")))
            .toLocaleString('default', {month: 'short'});
    }

    const [dueLoans, setDueLoans] = React.useState<Array<LoanSummary>>([])
    const [payments, setPayments] = React.useState<Array<LoanSummary>>([])
    const [pending, setPending] = React.useState<Array<LoanSummary>>([])

    const search_calendar_events = () => {
        setSearching(true)
        Req.get_calendar({max_date: search.max_date, min_date: search.min_date, office_id: search.office_id})
            .then((response) => {
                if (response.data.hasOwnProperty("data")) {
                    get_weekly_days()
                    setDueLoans(response.data.data.due_loans)
                    setPayments(response.data.data.payments)
                    setPending(response.data.data.pending)
                }
                setSearching(false)
            })
            .catch(() => {
                setSearching(false)
            })
    }

    const get_due_loans = (due_date: string) => {
        let due_loans = 0;
        dueLoans.forEach((dueLoan) => {
            if (dueLoan.due_date === due_date) {
                due_loans++
            }
        })
        return due_loans > 0 && <div className='due_loan'>{due_loans}</div>
    }

    const get_payments = (payment_date: string) => {
        let expected = 0;
        payments.forEach((payment) => {
            if (payment.payment_date === payment_date) {
                expected++
            }
        })
        return expected > 0 && <div className='payments'>{expected}</div>
    }

    const get_pending = (payment_date: string) => {
        let expected = 0;
        pending.forEach((payment) => {
            if (payment.payment_date === payment_date) {
                expected++
            }
        })
        return expected > 0 && <div className='pending'>{expected}</div>
    }

    /*THE DATA MODAL CONTAINER*/
    const [modal, setModal] = React.useState<{ show: boolean, date: string, }>({show: false, date: ''})

    const load_table = (type: 'pending' | 'paid' | 'due') => {
        let data = Array<LoanSummary>()
        if (type === 'pending') {
            data = [...pending.filter((loan) => loan.payment_date === modal.date)]
        } else if (type === 'paid') {
            data = [...payments.filter((loan) => loan.payment_date === modal.date)]
        } else if (type === 'due') {
            data = [...dueLoans.filter((loan) => loan.payment_date === modal.date)]
        }
        return (
            <div className="table_container">
                <Table celled striped compact size='small' inverted color='grey' selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{width: '40px'}}>Loan</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '140px'}}>Client Name</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '80px'}}>Client Contact</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '80px'}}>Loan Principal</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '80px'}}>Interest Paid</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '80px'}}>Pending Interest</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '80px'}}>Principal Paid</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '90px'}}>Pending Principal</Table.HeaderCell>
                            <Table.HeaderCell style={{width: '90px'}}>Due Date</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            data.map((datum) =>
                                <Table.Row>
                                    <Table.Cell style={{width: '40px'}}>{datum.loan_id}</Table.Cell>
                                    <Table.Cell style={{width: '140px'}}>{datum.client_name}</Table.Cell>
                                    <Table.Cell style={{width: '80px'}}>{datum.main_contact}</Table.Cell>
                                    <Table.Cell style={{width: '80px'}}>{Utils.comma_number(datum.loan_principal)}</Table.Cell>
                                    <Table.Cell style={{width: '80px'}}>{Utils.comma_number(datum.interest_paid)}</Table.Cell>
                                    <Table.Cell style={{width: '80px'}}>{Utils.comma_number(datum.pending_interest)}</Table.Cell>
                                    <Table.Cell style={{width: '80px'}}>{Utils.comma_number(datum.principal_paid)}</Table.Cell>
                                    <Table.Cell style={{width: '90px'}}>{Utils.comma_number(datum.pending_principal)}</Table.Cell>
                                    <Table.Cell style={{width: '90px'}}>{Utils.localise_date(datum.due_date)}</Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </div>
        )
    }

    let panes = [
        {
            menuItem: 'Payments Expected', key: 'payments_expected',
            render() {
                return <Tab.Pane attached={false} className="calendar_info_pane">{load_table('pending')}</Tab.Pane>;
            }
        },
        {
            menuItem: 'Due Loans', key: 'due_loans',
            render() {
                return <Tab.Pane attached={false} className="calendar_info_pane">{load_table('due')}</Tab.Pane>;
            }
        },
        {
            menuItem: 'Payments Received', key: 'payments_received',
            render() {
                return <Tab.Pane attached={false} className="calendar_info_pane">{load_table('paid')}</Tab.Pane>;
            }
        }
    ]

    React.useEffect(() => {
        search_calendar_events()
    }, [])

    React.useEffect(() => {
        if (user.staff.user_id > 0) {
            search_calendar_events()
        }
    }, [user.staff.user_id])

    return (
        <>
            <PDFReader source={showReader.source} open={showReader.display}
                       close={() => setShowReader({...showReader, display: false})}/>
            <Loading show={loading.show} text={loading.message} hide={() => setLoading({...loading, show: false})}/>

            <Modal open={modal.show} basic size='large' centered={false} onClose={() => setModal({...modal, show: false})}
                   closeOnDimmerClick={true} closeOnDocumentClick={true} closeOnEscape={true}>
                <Tab menu={{pointing: true}} panes={panes}/>
            </Modal>

            <div className="content_bar">
                <div className="search_bar">
                    <div>
                        <MySelect title="" name='offices_id' value={search.office_id} options={props.offices}
                                  change={(value: select_type) => handle_change('office_id', value as number)}/>
                    </div>
                    <div style={{width: '140px'}}>
                        <MyDateInput
                            title="" value={search.min_date} name="min_date" placeholder="Minimum Date"
                            change={handle_change} maxDate={search.max_date}/>
                    </div>
                    <div style={{width: '140px'}}>
                        <MyDateInput
                            title="" value={search.max_date} name="max_date" placeholder="Maximum Date"
                            change={handle_change} minDate={search.min_date}/>
                    </div>
                </div>
                <div className="search_button">
                    <Button primary floated='right' size='mini' icon='file pdf' onClick={pdf_summary}/>
                    <Button primary floated='right' size='mini' icon='search' loading={searching} disabled={searching}
                            onClick={search_calendar_events}/>
                </div>
            </div>

            <Table className="calendar_table">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Sun</Table.HeaderCell>
                        <Table.HeaderCell>Mon</Table.HeaderCell>
                        <Table.HeaderCell>Tue</Table.HeaderCell>
                        <Table.HeaderCell>Wed</Table.HeaderCell>
                        <Table.HeaderCell>Thu</Table.HeaderCell>
                        <Table.HeaderCell>Fri</Table.HeaderCell>
                        <Table.HeaderCell>Sat</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        calender.map(((dates, index) =>
                                <Table.Row key={index}>
                                    {
                                        dates.map((date, date_index) =>
                                            <Table.Cell key={date_index}>
                                                {
                                                    date.date !== "" &&
                                                    <div className={((index + date_index) % 2 === 0) ? 'even' : 'odd'}>
                                                        <div className="date"
                                                             onClick={() => setModal({date: date.date, show: true})}>
                                                            <div className="month">{get_month(date.date)}</div>
                                                            <div className="day">{get_date(date.date)}</div>
                                                            {get_due_loans(date.date)}
                                                            {get_payments(date.date)}
                                                            {get_pending(date.date)}
                                                        </div>
                                                    </div>
                                                }
                                            </Table.Cell>)
                                    }
                                </Table.Row>
                        ))
                    }
                </Table.Body>
            </Table>
        </>
    )
}
